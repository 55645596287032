import {
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
} from '@ionic/react'
import {
    styledHideIfDesktop,
    styledHideIfMobile,
} from 'helper/applicationHelper';
import styled from "styled-components";

export const ToolbarWrapper = styled(IonToolbar)`
--min-height: var(--ion-tool-bar-min-height);
--background: white;
color: var(--ion-color-text-primary);
--padding-top: 0;
--padding-bottom: 0;
--padding-start: 0;
--padding-end: 0;
`

export const ToolbarContainer = styled(IonToolbar)`
--background: none;
--padding-bottom: 0px;
--padding-end: 1.5rem;
--padding-start: 1.5rem;
--padding-top: 0px;
`

export const BackButtonContainer = styled(IonBackButton)`
--color: var(--ion-color-text-primary);
`

export const CustomBackButtonContainer = styled(IonButton)`
--color: var(--ion-color-text-primary);
`

const headerPaddingLeft = '30px;'

export const ButtonsContainer = styled(IonButtons)`
padding-right: 30px;
padding-left: ${headerPaddingLeft}
`

export const EndButtonsContainer = styled(IonButtons)`
padding-right: 30px;
padding-left: ${headerPaddingLeft}
${styledHideIfDesktop}
`

export const HomeButtonContainer = styled(IonButtons)`
padding-right: 24px;
padding-left: ${headerPaddingLeft}
${styledHideIfDesktop}
`

export const ButtonContainerForDesktop = styled(IonButtons)`
padding-right: 24px;
padding-left: ${headerPaddingLeft}
${styledHideIfMobile}
`

export const HomeBtnContForDesktWithBackBtn = styled(IonButtons)`
${styledHideIfMobile}
`
