import {
    CLIENT_PAYMENT_BASE_PATH,
    TRANSACTIONS_BASE_PATH
} from "constants/routeConstants"

/*
/pay/accept_link?uuid=ajsdhfshdf
/transactions/update_accept_link?uuid=ajsdhfshdf

/pay/client_bank?uuid=ajsdhfshdf
/transactions/update_client_bank?uuid=ajsdhfshdf

/pay/waiting_for_link?uuid=ajsdhfshdf
*/
const clientPaymentPathHelper = (uuid) => {
    const withSearch = uuid ? `?uuid=${uuid}` : ''
    const result = {
        showClientAcceptPaymentPath: `/${CLIENT_PAYMENT_BASE_PATH}/accept_link${withSearch}`,
        updateClientAcceptPaymentPath: `/${TRANSACTIONS_BASE_PATH}/update_link_accepted${withSearch}`,
        showClientSelectBankPath: `/${CLIENT_PAYMENT_BASE_PATH}/client_bank${withSearch}`,
        getClientSelectBankPath: `/${TRANSACTIONS_BASE_PATH}/client_bank${withSearch}`,
        updateClientSelectBankPath: `/${TRANSACTIONS_BASE_PATH}/update_client_bank${withSearch}`,
        showExplainClientSelectedBankPath: `/${CLIENT_PAYMENT_BASE_PATH}/explain_client_bank${withSearch}`,
        updateExplainClientSelectedBankPath: `/${TRANSACTIONS_BASE_PATH}/update_client_explain_bank_acknowledgement${withSearch}`,
        showClientWaitingForLinkPath: `/${CLIENT_PAYMENT_BASE_PATH}/waiting_for_link${withSearch}`,
        showClientPaymentSuccessPath: `/${CLIENT_PAYMENT_BASE_PATH}/success`,
        showClientPaymentErrorPath: `/${CLIENT_PAYMENT_BASE_PATH}/error`,
        showTransactionToClientPath: `/${TRANSACTIONS_BASE_PATH}/show_to_client${withSearch}`,
    }
    result['showClientAcceptPaymentUri'] = `${process.env.REACT_APP_BASE}${result.showClientAcceptPaymentPath}`
    return result
}

export default clientPaymentPathHelper
