import styled from 'styled-components';
import ContentListItem from 'components/ContentListItem';
import {
    TitleLabel as T,
    DetailLabel as D,
} from 'components/BaseLabels'

const styledGetColor = ({ active }) => active ? 'color: var(--ion-background-color)' : ''

const styledGetBackgroundColor = ({ active }) => active ? '--background: var(--ion-color-primary-greeny-blue)' : ''

export const Container = styled(ContentListItem)`
${styledGetBackgroundColor}
`

export const TitleLabel = styled(T)`
${styledGetColor}
`

export const DetailLabel = styled(D)`
${styledGetColor}
`
