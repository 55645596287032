import { Container } from './styled'

const PageSideTitle = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default PageSideTitle
