import PrimaryButton from 'components/PrimaryButton';
import PrimaryLink from 'components/PrimaryLink';
import RequestErrorMessages from 'components/RequestErrorMessages';
import { SponsorshopForm } from 'forms/sponsorshipsForm';
// import onErrorRedirect from 'helper/onErrorRedirect';
import userInvitationsPathHelper from 'helper/pathHelper/userInvitations';
// import userClientsPathHelper from 'helper/pathHelper/userClients';
import usersPathHelper from 'helper/pathHelper/users';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import PageContent from 'layout/PageContent';
import PageFooter from 'layout/PageFooter';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSignUpUserMutation } from 'store/services/users';
import { ContentTitle, ContentTextWrapper, Detail, TextBold, ContentWrapper } from './styled';
import useCustomIonAlert from 'hooks/useCustomIonAlert';

const SponsorshipNewPage = () => {
    const { t } = useTranslation(['sponsorship', 'application'])
    const history = useHistory();
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const { indexUserInvitationsPath } = userInvitationsPathHelper()
    const { createUserRegistrationsPath } = usersPathHelper();
    const [createUserRegistration, { isLoading, error }] = useSignUpUserMutation();

    const [alertPresent] = useCustomIonAlert()
    const handleComfirmAlert = () => {
        history.goBack()
    }
    const handleSubmit = async (data) => {
        try {
            await createUserRegistration({
                url: createUserRegistrationsPath,
                body: data
            }).unwrap()

            // Show alert dialog
            alertPresent({
                header: t('new_page.popup_title'),
                message: t('new_page.popup_detail'),
                buttons: [
                    {
                        text: t('ok'),
                        handler: handleComfirmAlert
                    }
                ],
                backdropDismiss: false,
            })
        } catch (error) {
            console.error('SponsorshipNewPage#handleSubmit', error)
        }
    }

    // TODO: temp month to be check after subscription was add
    const month = 1
    const month2 = 2

    return (
        <PageLayout>
            <PageHeader
                title={t('new_page.title')}
                withBackButton
                backTo={indexUserInvitationsPath}
            />

            <PageContent>
                <RequestErrorMessages error={error} />

                <ContentWrapper>
                    <ContentTextWrapper>
                        <ContentTitle>
                            {t("new_page.content_title")}
                        </ContentTitle>

                        <Detail>
                            {t("new_page.detail1")}
                            <div>
                                {t("new_page.detail2")}
                            </div>
                        </Detail>
                        <Detail>
                            {t("new_page.detail3", { month })}
                            <TextBold>{t("new_page.detail3_p2")}</TextBold>
                        </Detail>
                        <Detail>
                            {t("new_page.detail4", { month: month2 })}
                        </Detail>
                    </ContentTextWrapper>

                    <SponsorshopForm
                        submitButtonRef={submitButtonRef}
                        onSubmit={handleSubmit}
                    />
                </ContentWrapper>

            </PageContent>

            <PageFooter>
                <div className='ion-margin-bottom'>
                    <PrimaryLink to={indexUserInvitationsPath}>
                        {t('cancel')}
                    </PrimaryLink>
                </div>

                <div>
                    <PrimaryButton
                        onClick={handleClick}
                        disabled={isLoading}
                    >
                        {t('send')}
                    </PrimaryButton>
                </div>

            </PageFooter>
        </PageLayout>
    );
};

export default SponsorshipNewPage;
