import { styledOpacity } from "helper/transactionsStatusHelper";
import styled from "styled-components";

export const Container = styled.div`
color: var(--ion-color-text-primary);
font-size: 13px;

${({ bold }) => bold && 'font-weight: 700;'}

${styledOpacity}

`
