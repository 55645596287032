import { FieldArray, Form } from 'formik'
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import BaseField from 'components/fields/BaseField';
import { useTranslation } from 'react-i18next';
import TextAreaField from 'components/inputs/TextAreaField';
import ToggleField from 'components/inputs/ToggleField';
import { Fragment } from 'react';
import ImagePickerField from 'components/inputs/ImagePickerField';
import ResetPaymentMessageToDefaultField from 'components/fields/userPreferences/ResetPaymentMessageToDefaultField';
import SiretNumberField from 'components/fields/transactions/SiretNumberField';

const Index = ({ submitButtonRef, values }) => {
    const { t: uPt } = useTranslation('user_preference')
    const { t: cDt } = useTranslation('contact_datum')
    const { t: oT } = useTranslation('office')

    const rootKey = 'user'

    const toUserPerferenceName = (field) => `${rootKey}.user_preference_attributes.${field}`

    const toOfficeName = (field) => `${rootKey}.office_attributes.${field}`

    return (
        <Form className='first-form-control first-form-control--second-input-margin'>
            <TextAreaField
                label={uPt('payment_message')}
                placeholder={uPt('payment_message_placeholder')}
                name={toUserPerferenceName('payment_message')}
            />

            <ResetPaymentMessageToDefaultField
                paymentMessageName={toUserPerferenceName('payment_message')}
            />

            <ToggleField
                label={uPt('redirect_to_pending_on_app_open')}
                name={toUserPerferenceName('redirect_to_pending_on_open')}
            />

            <BaseField
                placeholder={uPt('consultation_label')}
                name={toUserPerferenceName('consultation_label')}
            />

            <BaseField
                placeholder={uPt('consultation_place')}
                name={toUserPerferenceName('consultation_place')}
            />

            <FieldArray name={toOfficeName('contact_data_attributes')}>
                {
                    ({ name }) => {
                        return values.user.office_attributes.contact_data_attributes.map((_, index) => {
                            return (
                                <Fragment key={index}>
                                    <BaseField
                                        name={`${name}.${index}.address`}
                                        placeholder={cDt('address')}
                                    />

                                    <BaseField
                                        name={`${name}.${index}.postal_code`}
                                        placeholder={cDt('zip_code')}
                                    />

                                    <BaseField
                                        name={`${name}.${index}.city`}
                                        placeholder={cDt('city')}
                                    />
                                </Fragment>
                            )
                        })
                    }
                }
            </FieldArray>

            <SiretNumberField
                name={toOfficeName('siret_number')}
            />

            <BaseField
                placeholder={oT('ape_code')}
                name={toOfficeName('ape_code')}
            />

            <BaseField
                placeholder={oT('adeli_number')}
                name={toOfficeName('adeli_number')}
            />

            <ImagePickerField
                label={uPt('submit_signature_file')}
                name={`${rootKey}.signature_image`}
            />

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
