import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import TextField from '../TextField';
import { getIn, useField, useFormikContext } from 'formik';
import { dateIMaskOptions } from 'hooks/useIMaskPipe';

const DateTextMask = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            {...dateIMaskOptions}
            lazy={false}
            unmask={true}
            overwrite
            inputRef={ref}
            onAccept={(value) => onChange(value)}
        />
    );
});

DateTextMask.propTypes = {
    name: PropTypes.string.isRequired,
};

const DateField = ({ name, ...rest }) => {
    const [, meta, helper] = useField(name)
    const { value } = meta
    const { setValue } = helper
    const form = useFormikContext()

    const handleChange = (newValue) => {
        setValue(newValue)
    }

    return (
        <TextField
            {...rest}
            name={name}
            value={value}
            InputProps={{
                inputComponent: DateTextMask,
            }}
            onChange={handleChange}
            error={getIn(form.touched, name) && Boolean(getIn(form.errors, name))}
            helperText={getIn(form.touched, name) && getIn(form.errors, name)}
        />
    );
}

export default DateField
