import PropTypes from 'prop-types'

import { Wrapper } from './styled'
import Spinner from 'components/Spinner'

const FullScreenSpinner = ({
    size,
    overlaid,
    wrapperClassname,
    ...rest
}) => {
    return (
        <Wrapper
            overlaid={overlaid}
            className={wrapperClassname}
        >
            <Spinner
                {...rest}
            />
        </Wrapper>
    )
}

FullScreenSpinner.propTypes = {
    overlaid: PropTypes.bool,
    wrapperClassname: PropTypes.string
}

export default FullScreenSpinner
