import { NSDetailLabel } from "components/BaseLabels"
import RequestErrorMessages from "components/RequestErrorMessages"
import Spinner from "components/Spinner"
import clientPaymentPathHelper from "helper/pathHelper/clientPayment"
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper"
import PageContentSmaller from "layout/PageContentSmaller"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo"
import PageLayout from "layout/PageLayout"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
    useUpdateClientAcceptPaymentMutation,
} from "store/services/clientPayments"

const ClientAcceptPaymentLinkPage = () => {
    const uuid = getURLSearchParamsHelper('uuid')
    const {
        updateClientAcceptPaymentPath,
        showClientSelectBankPath,
        showClientWaitingForLinkPath,
    } = clientPaymentPathHelper(uuid)

    const history = useHistory()

    const { t } = useTranslation('client_payment')

    const [updateTrigger, { isLoading }] = useUpdateClientAcceptPaymentMutation()
    const [error, setError] = useState()

    useEffect(() => {
        const updateAcceptLink = async () => {
            try {
                const updateResult = await updateTrigger({ url: updateClientAcceptPaymentPath })
                if (updateResult.data) {
                    const redirectPath = updateResult.data.data.should_select_bank ? showClientSelectBankPath : showClientWaitingForLinkPath
                    history.replace(redirectPath)
                } else if (updateResult?.error?.status === 422) {
                    setError(updateResult.error)
                }
            } catch (err) {
                console.error('UpdateClientAcceptPayment', err);
            }
        }
        updateAcceptLink()
    }, [
        updateTrigger,
        history,
        showClientSelectBankPath,
        showClientWaitingForLinkPath,
        updateClientAcceptPaymentPath,
    ])

    return (
        <PageLayout>
            <PageContentSmaller>
                <PageContentWithAppLogo
                    pageName='clientPayment'
                    body={
                        <>
                            <RequestErrorMessages error={error} />

                            <NSDetailLabel className="text-margin">
                                {t('accept_payment_page.loading_transaction')}
                            </NSDetailLabel>

                            {
                                isLoading
                                &&
                                <div className="ion-margin-top">
                                    <Spinner />
                                </div>
                            }
                        </>
                    }
                />
            </PageContentSmaller>
        </PageLayout>
    )
}

export default ClientAcceptPaymentLinkPage
