import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { App } from '@capacitor/app';
import { useIonRouter } from '@ionic/react';
import appPathHelper from 'helper/pathHelper/app';
import settingsPathHelper from 'helper/pathHelper/settings';
import { isAnyOfMainPage } from 'helper/applicationHelper';

const PressBackToExitApp = () => {
    const ionRouter = useIonRouter();
    const location = useLocation()
    const { rootPath } = appPathHelper()
    const { indexSettingsPath } = settingsPathHelper()

    const mainPagePaths = [
        rootPath,
        indexSettingsPath,
    ]

    useEffect(() => {
        document.addEventListener('ionBackButton', (ev) => {
            ev.detail.register(-1, () => {
                const cantGoBack = !ionRouter.canGoBack()
                const isOnMainPage = isAnyOfMainPage(location.pathname)
                const shouldExitApp = cantGoBack || isOnMainPage
                if (shouldExitApp) {
                    App.exitApp();
                }
            });
        });
    }, [])

    return null
}

export default PressBackToExitApp
