import { useFormikContext, getIn } from 'formik'
import CustomCheckBox from '../CustomCheckBox'
import {
    Container,
    LabelContainer,
} from '../ToggleField/styled'
import { CheckBoxContainer } from './styled'

const CheckBoxField = ({
    label,
    name,
    disabled,
}) => {
    const {
        setFieldValue,
        setFieldTouched,
        values,
    } = useFormikContext()

    const value = getIn(values, name)

    const handleChange = (event) => {
        setFieldTouched(name)
        setFieldValue(name, event.target.checked)
    };

    return (
        <Container>
            <LabelContainer>
                {label}
            </LabelContainer>
            <CheckBoxContainer>
                <CustomCheckBox
                    disabled={disabled}
                    checked={value}
                    onChange={handleChange}
                />
            </CheckBoxContainer>
        </Container>
    )
}

export default CheckBoxField
