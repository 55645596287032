import { StatusBar, Style } from '@capacitor/status-bar';
import { isPlatform } from '@ionic/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const listCustomStylePathname = [
    '/transactions/new'
]

const inListChangeStylePathname = pathname => listCustomStylePathname.includes(pathname)

const AppCustomStatusBar = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (isPlatform('capacitor')) {
            if (inListChangeStylePathname(pathname)) {
                StatusBar.setStyle({ style: Style.Dark });
                StatusBar.setBackgroundColor({ color: '#2E3C42' }) // Check color in src/theme/variables.scss for more detail
            } else {
                StatusBar.setStyle({ style: Style.Light });
                StatusBar.setBackgroundColor({ color: '#FFFFFF' })
            }
        }

    }, [pathname])

    return null
}

export default AppCustomStatusBar
