import {
    IonLabel,
} from '@ionic/react';
import IconWrapper from 'components/icons/IconWrapper';
import { useLocation } from 'react-router-dom';
import {
    Container,
    TitleLabel,
    DetailLabel,
} from './styled'

const SettingMenuItem = ({ menu }) => {
    const { pathname } = useLocation()
    const { to } = menu
    const active = to === pathname

    const Icon = menu.icon

    return <Container
        active={active}
        withBorder
        routerLink={to}
    >
        <IconWrapper slot='start'>
            <Icon
                style={{
                    fontSize: '40px',
                    color: active ? 'white' : 'var(--ion-color-text-primary)',
                }}
            />
        </IconWrapper>

        <IonLabel>
            <TitleLabel active={active}>
                {menu.title}
            </TitleLabel>
            <DetailLabel active={active}>
                {menu.detail}
            </DetailLabel>
        </IonLabel>
    </Container>
}

export default SettingMenuItem
