import { Form } from 'formik'
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import { IbanField } from 'components/fields/bankAccounts';
import BaseField from 'components/fields/BaseField';
import { useTranslation } from 'react-i18next';
import {
    BankNameField,
    BankCountryField,
    BankCityField,
} from 'components/fields/bankAccounts';

const Index = ({ submitButtonRef }) => {
    const { t } = useTranslation('bank_account')

    return (
        <Form className='third-form-control third-form-control--first-input-margin'>
            <IbanField
                autoHideKeyboard
                name='bank_account.iban'
            />

            <BankNameField
                name='bank_account.bank_name'
            />

            <BankCountryField
                name='bank_account.bank_country'
            />

            <BankCityField
                name='bank_account.bank_city'
            />

            <BaseField
                placeholder={t('bank_account_name')}
                name='bank_account.bank_account_name'
            />

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
