import { withFormik } from 'formik'
import Form from './TransactionNewForm'
import { get } from 'lodash'
import * as Yup from 'yup'
import { BaseFormikConfig } from 'forms/_baseForm'
import dayjs from 'dayjs'
import { isBlank, isPresent } from 'helper/applicationHelper'
import { phone, validatePhone } from 'forms/userClientsForm/validation'

export const EXIST_CLIENT = "exist_client"
export const NEW_CLIENT = "new_client"

function isExistClient(context) {
    return !(context.from[1].value.submit_new_client_form)
}

function validateNewClientFieldPresent(value, context) {
    if (isExistClient(context)) return true
    return isPresent(value)
}

function validateNewClientEmail(value, context) {
    if (isExistClient(context)) return true
    if (isBlank(value)) return true

    return value
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

function validateNewClientPhone(value, context) {
    if (isExistClient(context)) return true
    return validatePhone(value, context)
}

const TransactionFormValidationSchema = Yup
    .object()
    .shape({
        submit_new_client_form: Yup.boolean().required(),
        user_client: Yup.object().shape({
            last_name: Yup.string().test('last_name', "Le nom de famille doit être rempli.", validateNewClientFieldPresent),
            first_name: Yup.string().test('first_name', "Le prénom doit être rempli.", validateNewClientFieldPresent),
            phone: Yup.string().test('phone', "Le numéro de téléphone doit être renseigné.", validateNewClientPhone),
            phone_iso2: Yup.string().test('phone_iso2', "Le numéro de téléphone country doit être renseigné.", validateNewClientFieldPresent),
            email: Yup.string().test('email', "L’e-mail doit être un e-mail valide.", validateNewClientEmail),
        }),
        transaction_form: Yup.object().shape(
            {
                label: Yup.string(),
                user_client_id: Yup.number(),
                date_amounts: Yup.array()
                    .of(Yup.object().shape({
                        execution_date: Yup.date().required(`La date d'exécution est requise`).test('date_range', 'La date n’est pas valide', function (value, context) {
                            const index = context.options.index
                            const cycle = context.from[1].value.cycle

                            // Skip validate in case of fill is more than the choosen cycle
                            if (index >= cycle) return true

                            // Use method .startOf('d') to reset time unit to 00:00:00
                            const chooseDate = dayjs(value).startOf('d')

                            if (index === 0) {
                                const currentDate = dayjs().startOf('d')
                                return chooseDate.diff(currentDate, 'd') >= 0
                            } else {
                                const previousDate = get(context.from[2].value, `transaction_form.date_amounts[${index - 1}].execution_date`).startOf('d')
                                return chooseDate.diff(previousDate, 'd') > 0
                            }
                        }),
                        amount: Yup.number().required('plus de 0').positive('plus de 0').typeError('plus de 0')

                    })),
                total_amount: Yup.number().required(`Le montant est requis`).test('positive', `Le montant n'est pas valide`, function (value, context) {
                    const index = context.options.index
                    const cycle = context.from[1].value.cycle

                    // Skip validate in case of fill is more than the choosen cycle
                    if (index >= cycle) return true

                    return value > 0
                }),
                clear_bank: Yup.boolean()
            }
        )
    })

const TransactionFormikConfig = {
    ...BaseFormikConfig,
    mapPropsToValues: ({ data }) => {
        const user_client_id = data?.user_client_id
        const total_amount = data?.total_amount
        const date_amounts = data?.date_amounts
        const full_name = data?.full_name

        return {
            submit_new_client_form: false,
            user_client: {
                last_name: '',
                first_name: '',
                phone: '',
                phone_iso2: '',
                email: '',
            },
            transaction_form: {
                label: '',
                full_name: full_name ?? '',
                user_client_id: user_client_id ?? '',
                total_amount: total_amount ?? '',
                date_amounts: date_amounts ?? [
                    {
                        execution_date: dayjs(),
                        amount: 0
                    }
                ],
                clear_bank: false
            }
        }
    },
    validationSchema: TransactionFormValidationSchema,
}

export default withFormik(TransactionFormikConfig)(Form)
