// https://github.com/codler/react-ga4

import { useEffect } from "react";
import ReactGA from "react-ga4";

const InitializeGoogleAnalyticsV4 = () => {
    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_V4_ID);
    }, [])

    return null
}

export default InitializeGoogleAnalyticsV4
