import PageContent from "layout/PageContent"

const PageContentSmaller = ({ children, ...rest }) => {
    return (
        <PageContent
            backgroundImageEnabled
            contentProps={{
                sizeLg: '5'
            }}
            {...rest}>
            {children}
        </PageContent>
    )
}

export default PageContentSmaller
