import { useTranslation } from 'react-i18next';
import { Container } from './styled'

const RequestErrorMessages = ({ error }) => {
    const { t } = useTranslation('application');

    if (!error) {
        return null
    }

    const { data, status } = error

    if (status === 'FETCH_ERROR') {
        // There are some errors in the backend.
        return <Container>
            {t('something_went_wrong')}
        </Container>
    }

    if (!data) {
        return null
    }

    return (
        data instanceof Array ? <Container>
            <ul>
                {
                    data.map((error, index) => {
                        return <li key={index}>{error}</li>
                    })
                }
            </ul>
        </Container> : <Container>
            {data?.error || t('something_went_wrong')}
        </Container>
    )
};

export default RequestErrorMessages;
