import { Container } from './styled'

const TransactionLabel = ({
    children,
    ...rest
}) => {
    return (
        <Container {...rest}>
            {children}
        </Container>
    )
}
export default TransactionLabel
