import { InputAdornment } from '@mui/material'
import { FieldArray, useField, useFormikContext, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material';
import './style.scss';
import dayjs from 'dayjs'
import { CycleDateAmountWrapper, DueDateContainer, SpanAmountWidthRef } from './styled'
import { useEffect, useRef, useState } from 'react'
import produce from "immer"
import { Remove } from '@mui/icons-material';
import clsx from 'clsx'
import DatePicker from './DatePicker';
import PrimaryButton from 'components/PrimaryButton';
import MainAmountField from './MainAmountField';
import useDialogMutipleTransaction from 'hooks/transactions/useDialogMutipleTransaction';
import AmountFieldMask from './MainAmountField/AmountFieldMask';
import { isEmpty } from 'lodash';
import { ResizeObserver } from 'resize-observer';
import floatingPointFix from 'js-floating-point';
import SubAmountField from './SubAmountField';

const generateExecutionDate = (dataLength) => {
    // '2' means 2 weeek, the calculation is when add more transacion, date is increase 2 week for each transaction
    const week_amount = 2 * dataLength
    return dayjs().add(week_amount, 'w')
}

const CycleDateAmountField = () => {

    const field_name = 'transaction_form.date_amounts'
    const total_amount_name = 'transaction_form.total_amount'
    const { t } = useTranslation(['transaction'])
    const [, date_amounts_meta, date_amounts_helpers] = useField(field_name)
    const [, total_amount_meta, total_amount_helpers] = useField('transaction_form.total_amount')
    const form = useFormikContext()

    const dateAmountMoreThanOne = date_amounts_meta.value.length > 1
    const [showDialogMutipleTransaction, dismiss, confirmed, multiplePaymentsPopupHidden] = useDialogMutipleTransaction()

    // Remove popup when it show and user press back button
    useEffect(() => {
        return () => dismiss()
    }, [])

    // Note : I order code (UE[1], UE[2] to make it work on changing amount)
    // UE[1] : Change of sub_total_amount
    useEffect(() => {
        const total_amount = parseFloat(total_amount_meta.value)
        if (total_amount >= 0) {
            const amount_size = date_amounts_meta.value.length
            const loopRange = amount_size - 1
            let calculated_amounts = []

            // Store all amounts except last
            for (let i = 0; i < loopRange; i++) {
                calculated_amounts.push(parseFloat(date_amounts_meta.value[i].amount))
            }

            // Calculate last amount and store it
            const last_amount = floatingPointFix(total_amount - calculated_amounts.reduce((previousValue, currentValue) => previousValue + currentValue, 0))
            calculated_amounts.push(last_amount)

            // Update value here
            const body = produce(date_amounts_meta.value, draftState => {
                for (let i = 0; i < amount_size; i++) {
                    draftState[i].amount = calculated_amounts[i]
                }
            })

            date_amounts_helpers.setValue(body)
        }

    }, [date_amounts_meta.value])

    // UE[2] : Change of total_amount and when add multiple payment
    useEffect(() => {
        const total_amount = total_amount_meta.value

        if (total_amount >= 0) {
            const amount_size = date_amounts_meta.value.length
            let calculated_amounts = []

            const hasPrecision = (total_amount % amount_size) > 0
            if (hasPrecision) {
                let each_amount = total_amount / amount_size
                each_amount = parseFloat(each_amount.toFixed(2))

                for (let i = 0; i < amount_size - 1; i++) {
                    calculated_amounts.push(each_amount)
                }

                const total_except_last = calculated_amounts.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                const last_amount = floatingPointFix(total_amount - total_except_last)
                calculated_amounts.push(last_amount)

            } else {
                // Perfect separate
                const calculated_amount = total_amount / amount_size
                for (let i = 0; i < amount_size; i++) {
                    calculated_amounts.push(calculated_amount)
                }
            }

            // Update value here
            const body = produce(date_amounts_meta.value, draftState => {
                for (let i = 0; i < amount_size; i++) {
                    draftState[i].amount = calculated_amounts[i]
                }
            })

            date_amounts_helpers.setValue(body)
        }
    }, [total_amount_meta.value, date_amounts_meta.value.length])

    // Dynamic Amount width
    const amountValue = getIn(form.values, total_amount_name)
    const amountFontSize = 40
    const minAmountInputWidth = 100
    const currencyAreaWidth = 50
    const observeRef = useRef()
    const spanRef = useRef()
    const [inputWidth, setInputWidth] = useState(minAmountInputWidth)
    const [textAmountMask, setTextAmountMask] = useState("")
    const amountText = total_amount_meta.value

    useEffect(() => {
        if (isEmpty(amountText) || isEmpty(spanRef.current)) {
            return
        }

        if (spanRef.current && !observeRef.current) {
            observeRef.current = true

            const resizeObserver = new ResizeObserver((event) => {
                // To make it compatible with old version browser, we need to use external library of 'ResizeObserver'
                // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize for how to use it in native, the custom lib is similar to this solution
                const inlineSize = event[0].contentRect.width
                const amountWidth = inlineSize + currencyAreaWidth
                if (amountWidth > minAmountInputWidth) {
                    setInputWidth(amountWidth)
                } else {
                    setInputWidth(minAmountInputWidth)
                }
            });

            resizeObserver.observe(spanRef.current);
        }
    }, [amountText, spanRef.current])


    const renderRemoveButton = (arrayHelpers, index, date_amount_size) => {
        if (index !== 0) {
            return (
                <IconButton
                    onClick={() => arrayHelpers.remove(index)}
                >
                    <Remove style={{ color: 'var(--ion-color-text-secondary)' }} />
                </IconButton>
            )
        } else {
            if (date_amount_size > 1) {
                return (
                    // Add to reserve area for adjust position
                    <IconButton
                        className='date-amount-container__hidden'
                    >
                        <Remove />
                    </IconButton>
                )
            }
        }
    }

    const renderAddPaymentButton = (arrayHelpers) => {
        if (date_amounts_meta.value.length > 0 && date_amounts_meta.value.length < 3) {
            let message
            if (date_amounts_meta.value.length > 1)
                message = t('new_page.add_due_date')
            else (
                message = t('new_page.split_receipt')
            )

            return (
                <PrimaryButton
                    onClick={() => {
                        if (!confirmed && !multiplePaymentsPopupHidden) {
                            showDialogMutipleTransaction()
                        }

                        arrayHelpers.push({ execution_date: generateExecutionDate(date_amounts_meta.value.length), amount: 0 })
                    }}
                    className='add-due-date-button'
                    toUpperText={false}
                    maxWidth='unset'
                >
                    {message}
                </PrimaryButton>
            )
        }

        return null
    }

    const renderDateAmount = arrayHelpers => (

        <CycleDateAmountWrapper>
            <div>
                <DueDateContainer>
                    {t('new_page.execution_date')}
                </DueDateContainer>

                {
                    date_amounts_meta.value.map(
                        (date_amount, index) => {
                            const disabled = date_amounts_meta.value.length === (index + 1)

                            return (
                                <div
                                    key={index}
                                    className='date-amount-container'
                                >
                                    <DatePicker
                                        name={`${field_name}.${index}.execution_date`}
                                        placeholder={`${t('new_page.due_date')} ${index + 1}`}
                                        className={clsx(dateAmountMoreThanOne && 'date-amount-container__date', '')}
                                    />

                                    {
                                        dateAmountMoreThanOne &&
                                        <SubAmountField
                                            name={`${field_name}.${index}.amount`}
                                            disabled={disabled}
                                        />
                                    }

                                    {renderRemoveButton(arrayHelpers, index, date_amounts_meta.value.length)}

                                </div>
                            )
                        }
                    )
                }

                {renderAddPaymentButton(arrayHelpers)}
            </div>

            <MainAmountField
                name={total_amount_name}
                margin="normal"
                variant="standard"
                InputProps={{
                    inputComponent: AmountFieldMask,
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            sx={{
                                '.MuiTypography-root': {
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                    color: 'var(--ion-color-text-secondary)'
                                }
                            }}
                        >
                            €
                        </InputAdornment>
                    ),
                    style: {
                        fontSize: amountFontSize,
                        width: `${inputWidth}px`,
                        alignSelf: 'center',
                        marginTop: 60,
                        fontWeight: 'bold',
                        color: 'var(--ion-color-text-secondary)',
                        borderColor: 'var(--ion-color-text-secondary)',
                    }
                }}
                FormHelperTextProps={{
                    style: { alignSelf: 'center' }
                }}
                InputLabelProps={{
                    shrink: true
                }}
                value={amountValue}
                onChange={(newValue, maskNewValue) => {
                    total_amount_helpers.setValue(newValue)
                    setTextAmountMask(maskNewValue)
                }}
                error={getIn(form.touched, total_amount_name) && Boolean(getIn(form.errors, total_amount_name))}
                helperText={getIn(form.touched, total_amount_name) && getIn(form.errors, total_amount_name)}
                type="number"
                inputMode='numeric'
                pattern="[0-9]*"
            />

            {/* This component is used for make dynamic width amount */}
            <SpanAmountWidthRef ref={spanRef} >
                {textAmountMask}
            </SpanAmountWidthRef>

        </CycleDateAmountWrapper>
    )

    return (
        <FieldArray
            name='transaction_form.date_amounts'
            render={renderDateAmount}
        >

        </FieldArray>
    )
}

export default CycleDateAmountField
