import { Autocomplete, TextField } from '@mui/material';
import { styled as mStyled } from '@mui/material/styles';

export const CssTextField = mStyled(TextField)({
    // helperText
    '& label': {
        fontSize: '14px',
    },
    // Input text that the user is entering.
    '& input': {
        fontSize: '14px',
        paddingTop: '5px !important',

        '::placeholder': {
            color: 'var(--ion-color-text-primary)',
            opacity: 1 /* Firefox */
        },
        ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'var(--ion-color-text-primary)'
        },
        '::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'var(--ion-color-text-primary)'
        },
    },
    // Errors text.
    '& p': {
        fontSize: '14px',
    },

    '& .MuiOutlinedInput-root': {
        background: 'var(--ion-background-color)',
        borderRadius: '15px',

        '& fieldset': {
            borderColor: 'var(--ion-color-primary-greeny-blue)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--ion-color-primary-greeny-blue)',
        }
    },
})

export const CssAutocomplete = mStyled(Autocomplete)({
    '& .MuiOutlinedInput-root': {
        padding: '0 9px',
        height: 30,
    },
    '& .MuiAutocomplete-popupIndicator': {
        transform: "none"
    }
})
