import TextField from './styled';

const MainAmountField = (props) => {
    return (
        <TextField
            {...props}
        />
    )
}

export default MainAmountField
