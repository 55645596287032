import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import { TransactionNewForm } from 'forms/transactionsForm2';
import produce from "immer"
import { close } from 'ionicons/icons';
import './style.scss'
import { useCreateTransactionMutation } from 'store/services/transactions';
import transactionsPathHelper from 'helper/pathHelper/transactions';
import { useHistory } from 'react-router';
import TransactionPageFooter from 'layout/TransactionPageFooter';
import appPathHelper from 'helper/pathHelper/app';
import { IonHeader, IonIcon } from '@ionic/react';
import { ToolbarWrapper, TpBgButton, TransparentPageContent } from './styled';
import useDialogSingleTransaction from 'hooks/transactions/useDialogSingleTransaction';
import dayjs from 'dayjs';
import NewTransactionButton from 'components/transactions/NewTransactionButton';
import { useEffect, useRef } from 'react';
import PageTitle from 'layout/PageHeader/PageTitle';
import useErrorModal from 'hooks/useErrorModal';

const TransactionNewPage = ({ duplicateData }) => {
    const toolbarRef = useRef()
    const { t } = useTranslation(['application', 'transaction'])
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const { rootPath } = appPathHelper()

    // API
    const [createTransaction, { isLoading, error }] = useCreateTransactionMutation()
    const { createTransactionsPath } = transactionsPathHelper()
    const history = useHistory();
    const [presentErrorModal] = useErrorModal({
        errors: error?.data
    })

    // Dialog
    const [showDialogSingleTransaction, dismiss, confirmed, singlePaymentPopupHidden] = useDialogSingleTransaction()
    const shouldShowSingleTransactionDialog = (data) => {
        // The condition is if date of first payment is more than tomorrow?
        const targetDifferentDate = 1
        const firstPaymentDate = data.transaction_form.date_amounts[0].execution_date.startOf('date')
        const todayDate = dayjs().startOf('date')
        const diff = firstPaymentDate.diff(todayDate, 'day')

        return diff > targetDifferentDate
    }

    // Remove popup when it show and user press back button
    useEffect(() => {
        return () => dismiss()
    }, [])

    const handleSubmit = async (data) => {
        // Show modal if need, then if submit again 
        if (!confirmed && !singlePaymentPopupHidden && shouldShowSingleTransactionDialog(data)) {
            showDialogSingleTransaction()
        } else {
            const body = produce({}, draftState => {
                draftState.transaction = {}

                for (let i = 0; i < data.transaction_form.date_amounts.length; i++) {
                    let transaction = {}
                    let date_amount = data.transaction_form.date_amounts[i]

                    transaction.amount = date_amount.amount
                    transaction.execution_date = date_amount.execution_date.format('YYYY-MM-DD')
                    transaction.user_client_id = data.transaction_form?.user_client_id
                    transaction.phone = data.transaction_form?.phone
                    transaction.email = data.transaction_form?.email

                    draftState.transaction[i] = { ...transaction }
                }

                draftState.clear_bank = data.transaction_form.clear_bank

                draftState.user_client = {
                    email: data.user_client?.email,
                    first_name: data.user_client?.first_name,
                    last_name: data.user_client?.last_name,
                    phone: data.user_client?.phone,
                    phone_iso2: data.user_client?.phone_iso2,
                }

                draftState.submit_new_client_form = data.submit_new_client_form

            })

            try {
                const { data: { id } } = await createTransaction({
                    url: createTransactionsPath,
                    body: body
                }).unwrap()
                const { afterCreateTransactionsPath } = transactionsPathHelper(id)
                history.replace(afterCreateTransactionsPath)
            } catch (error) {
                if (error?.data) presentErrorModal()

                console.error('TransactionNewPage#handleSubmit', error)
            }
        }
    }

    return (
        <PageLayout className='transaction-background'>
            <IonHeader
                className='ion-no-border'
                style={{ marginTop: 'env(safe-area-inset-top)' }}
            >
                <ToolbarWrapper ref={toolbarRef}>
                    <TpBgButton
                        slot='start'
                        fill='clear'
                        onClick={() => {
                            history.replace(rootPath)
                        }}
                    >
                        <IonIcon
                            slot="icon-only"
                            icon={close}
                        />
                    </TpBgButton>

                    <PageTitle
                        fontSize='20px'
                        color='--ion-color-text-secondary'
                        title={t('new_page.title')}
                        toolbarRef={toolbarRef}
                    />
                </ToolbarWrapper>
            </IonHeader>

            <TransparentPageContent>
                <TransactionNewForm
                    data={duplicateData}
                    submitButtonRef={submitButtonRef}
                    onSubmit={handleSubmit}
                />
            </TransparentPageContent>

            <TransactionPageFooter
                backgroundColor='none'
            >
                <NewTransactionButton
                    onClick={handleClick}
                    disabled={isLoading}
                    isInvert
                >
                    {t('submit')}
                </NewTransactionButton>
            </TransactionPageFooter>




        </PageLayout >
    )
}

export default TransactionNewPage
