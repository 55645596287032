import {
    TRANSACTION_STATUS_SCHEDULED,
    TRANSACTION_STATUS_SUCCESS,
    TRANSACTION_STATUS_FAILED,
    TRANSACTION_STATUS_PENDING
} from "constants/transactionContants"

export const isScheduled = (status) => status === TRANSACTION_STATUS_SCHEDULED

export const isPending = (status) => status === TRANSACTION_STATUS_PENDING

export const isSuccess = (status) => status === TRANSACTION_STATUS_SUCCESS

export const isFailed = (status) => status === TRANSACTION_STATUS_FAILED

export const styledOpacity = ({ status }) => isFailed(status) ? 'opacity: 0.5;' : ''

export const styledFontWeight = ({ bold }) => bold && 'font-weight: bold;'
