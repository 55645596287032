import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from './SponsorshipForm'
import { BaseFormikConfig, id } from 'forms/_baseForm'
import {
    last_name,
    first_name,
    email,
    terms_and_conditions_accepted
} from './validation';

const FormValidationSchema = Yup
    .object()
    .shape({
        user: Yup.object().shape({
            email,
            terms_and_conditions_accepted,
            individual_attributes: Yup.object().shape({
                first_name,
                last_name,
            }),
        })
    })

const FormikConfig = {
    ...BaseFormikConfig,
    mapPropsToValues: ({ data }) => ({
        user: {
            email: '',
            terms_and_conditions_accepted: true,
            individual_attributes: {
                first_name: '',
                last_name: '',
            },
        }
    }),
    validationSchema: FormValidationSchema,
}

export default withFormik(FormikConfig)(Form)
