import CustomCheckBox from "components/inputs/CustomCheckBox"
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from "react-i18next";
import { Title, Detail, AcceptLabelContainer } from './styled';
import { getIn, useFormikContext } from "formik";
import PrimaryLink from "components/PrimaryLink";

const AcceptTermOfServiceField = ({
    name,
    tosURI,
}) => {
    const { t } = useTranslation('user')
    const parentPath = name.replace('.terms_and_conditions_accepted', '')

    const {
        setFieldValue,
        errors,
        setFieldTouched,
        touched,
        values,
    } = useFormikContext()

    const handleChange = (event) => {
        setFieldTouched(name)
        setFieldValue(name, event.target.checked)
    };

    const value = getIn(values, name)

    // We will show terms and conditions error only if it's the only one left.
    const isFieldTouched = getIn(touched, name)
    const isFieldError = Boolean(getIn(errors, name))
    const isOnlyFieldError = Object.keys(getIn(errors, parentPath, [])).length === 1
    const shouldShowTAndCError = isFieldTouched && isFieldError && isOnlyFieldError

    return (
        <FormControl
            component="fieldset"
            error
        >
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    value="end"
                    sx={{
                        alignItems: 'flex-start'
                    }}
                    control={
                        <CustomCheckBox
                            checked={value}
                            onChange={handleChange}
                        />
                    }
                    label={
                        <AcceptLabelContainer>
                            <PrimaryLink
                                to={{ pathname: tosURI }}
                                target='_blank'
                            >
                                <div className="ion-text-start">
                                    <Detail>
                                        {t('sign_up_page.i_accept')}
                                    </Detail>
                                    {' '}
                                    <Title>
                                        {t('sign_up_page.term_of_service')}
                                    </Title>
                                    {' '}
                                    <Detail>
                                        {t('sign_up_page.of_service')}
                                    </Detail>
                                </div>
                            </PrimaryLink>
                        </AcceptLabelContainer>
                    }
                    labelPlacement="end"
                />
            </FormGroup>
            {shouldShowTAndCError && <FormHelperText>{getIn(errors, name)}</FormHelperText>}
        </FormControl>
    )
}

export default AcceptTermOfServiceField
