import * as Yup from 'yup'

export const label = Yup
    .string()
    .required('Label is required.')

export const full_name = Yup
    .string()
    .required('Full name is required.')

export const bridgeapi_bank_no = Yup
    .string()
    .required('La banque est obligatoire.')
