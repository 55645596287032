import HiddenSubmitButton from 'components/HiddenSubmitButton';
import UserInfoFields from './UserInfoFields';
import BankAccountFields from './BankAccountFields';
import SubscriptionFields from './SubscriptionFields';
import { Container } from './styled'

const Index = ({
    submitButtonRef,
    values,
    currentStep,
}) => {

    let RenderContent
    if (currentStep === 1) {
        RenderContent = <UserInfoFields />
    } else if (currentStep === 2) {
        RenderContent = <BankAccountFields values={values} />
    } else if (currentStep === 3) {
        RenderContent = <SubscriptionFields />
    } else {
        RenderContent = `Step ${currentStep} not found.`
    }

    return (
        <Container className='third-form-control third-form-control--first-input-margin'>

            {RenderContent}

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Container>
    )
}

export default Index
