import transactionsPathHelper from 'helper/pathHelper/transactions'
import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { useLazyGetLatestFailedForTransactionQuery } from 'store/services/transactions'
import {
    useLocation,
    useHistory,
} from 'react-router-dom';

const RedirectToLatestFailedForTransaction = () => {
    const { showLatestFailedForTransactionsPath } = transactionsPathHelper()
    const { user } = useAuth()
    const { pathname } = useLocation()
    const history = useHistory()

    const [trigger, {
        data,
        isSuccess,
    }] = useLazyGetLatestFailedForTransactionQuery()

    useEffect(() => {
        if (user) {
            trigger(
                {
                    url: showLatestFailedForTransactionsPath
                })
        }
    }, [user])

    useEffect(() => {
        if (isSuccess && data.data) {
            const regex = /\/transactions\/\d+/
            const testResult = pathname.match(regex)
            if (testResult === null) {
                const currentIdMatch = pathname.match(/\d+/)
                if (Number(currentIdMatch?.[0]) !== data.data.id) {
                    const { showTransactionsPath } = transactionsPathHelper(data.data.id)
                    // Redirect the use only if currect path is not in the individual path of the failed transaction
                    // '/transactions/1', '/transactions/1/after_create' and etc.
                    history.push(showTransactionsPath)
                }
            }
        }
    }, [data, isSuccess])

    return null
}

export default RedirectToLatestFailedForTransaction
