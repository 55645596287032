import styled from "styled-components";

export const Container = styled.div`
margin-top: 70px;
`

export const Amount = styled.div`
font-size: 35px;
font-weight: 500;
color: var(--ion-color-text-primary);
`

export const StatusContainer = styled.div`
margin-top: 84px;
`
