import usersPathHelper from "helper/pathHelper/users";
import appPathHelper from "helper/pathHelper/app";
import {
    NOT_FOUND_CODE,
    UNAUTHORIZED_CODE,
    UNPROCESSABLE_ENTITY_CODE,
} from "constants/networkContants";
import { Redirect } from "react-router-dom";

const onErrorRedirect = (error, handlers = {}) => {
    if (!error) {
        return null
    }

    const { status } = error

    // Handle only errors normal errors from the API.
    if (!status) {
        return null
    }

    const { newUserSessionsPath } = usersPathHelper()
    const { rootPath, pageNotFoundPath } = appPathHelper()

    switch (status) {
        case UNAUTHORIZED_CODE:
            const handleUC = handlers[UNAUTHORIZED_CODE]
            if (handleUC) return handleUC
            return <Redirect to={newUserSessionsPath} />;
        case NOT_FOUND_CODE:
            const handleNFC = handlers[NOT_FOUND_CODE]
            if (handleNFC) return handleNFC
            return <Redirect to={pageNotFoundPath} />;
        case UNPROCESSABLE_ENTITY_CODE:
            const handleUE = handlers[UNPROCESSABLE_ENTITY_CODE]
            if (handleUE) return handleUE
            // Do nothing, show error messages to the user.
            return null
        default:
            const handleDefault = handlers['default']
            if (handleDefault) return handleDefault
            return <Redirect to={rootPath} />;
    }
}

export default onErrorRedirect
