import { USER_SETTINGS_BASE_PATH } from 'constants/routeConstants'

const userSettingsPathHelper = () => {
    return {
        // @Used for: internal
        indexUserSettingsPath: `/${USER_SETTINGS_BASE_PATH}`,
        // @Used for: external
        updateUserSettingsPath: `/${USER_SETTINGS_BASE_PATH}`,
        // @Used for: external
        getCompletionInfoPercentagePath: `/${USER_SETTINGS_BASE_PATH}/completion_info_percentage`,
        // @Used for: external
        updateRequestInfoPath: `/${USER_SETTINGS_BASE_PATH}/request_information`
    }  
}

export default userSettingsPathHelper
