import { Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import SignUpPage from 'pages/users/SignUpPage';
import PageNotFound from 'layout/PageNotFound';
import PrivateRoute from 'components/PrivateRoute';
import usersPathHelper from 'helper/pathHelper/users';
import appPathHelper from 'helper/pathHelper/app';
import SignInPage from 'pages/users/SignInPage';
import PreSignUpPage from 'pages/users/PreSignUpPage';
import AfterSignUpPage from 'pages/users/AfterSignUpPage';
import transactionsPathHelper from 'helper/pathHelper/transactions';
import TransactionNewPage from 'pages/transactions/TransactionNewPage';
import SetupSignUpPage from 'pages/users/SetupSignUpPage';
import AfterSetupSignupPage from 'pages/users/AfterSetupSignupPage';
import ForgotPasswordPage from 'pages/users/ForgotPasswordPage';
import SendForgotPasswordPage from 'pages/users/SendForgotPasswordPage';
import ResetPasswordPage from 'pages/users/ResetPasswordPage';
import UserSettingsPage from 'pages/userSettings/UserSettingsPage';
import userSettingsPathHelper from 'helper/pathHelper/userSettings';
import bankAccountsPathHelper from 'helper/pathHelper/bankAccounts';
import BankAccountsIndexPage from 'pages/bankAccounts/BankAccountsIndexPage';
import BankAccountShowPage from 'pages/bankAccounts/BankAccountShowPage';
import BankAccountNewPage from 'pages/bankAccounts/BankAccountNewPage';
import settingsPathHelper from 'helper/pathHelper/settings';
import TransactionsIndexPage from 'pages/transactions/TransactionsIndexPage';
import TransactionShowPage from 'pages/transactions/TransactionShowPage';
import userClientsPathHelper from 'helper/pathHelper/userClients';
import UserClientsIndexPage from 'pages/userClients/UserClientsIndexPage';
import UserClientShowPage from 'pages/userClients/UserClientShowPage';
import UserClientNewPage from 'pages/userClients/UserClientNewPage';
import MainPageTabs from 'layout/MainPageTabs';
import TransactionInfoIndexPage from 'pages/transactionInfo/TransactionInfoIndexPage';
import transactionInfoPathHelper from 'helper/pathHelper/transactionInfo';
import clientPaymentPathHelper from 'helper/pathHelper/clientPayment';
import ClientAcceptPaymentLinkPage from 'pages/clientPayments/ClientAcceptPaymentLinkPage';
import ClientSelectBankPage from 'pages/clientPayments/ClientSelectBankPage';
import ClientPaymentSuccessPage from 'pages/clientPayments/ClientPaymentSuccessPage';
import ClientPaymentErrorPage from 'pages/clientPayments/ClientPaymentErrorPage';
import ClientWaitingForPaymentLinkPage from 'pages/clientPayments/ClientWaitingForPaymentLinkPage';
import TransactionAfterCreatePage from 'pages/transactions/TransactionAfterCreatePage';
import TransactionDuplicatePage from 'pages/transactions/TransactionDuplicatePage';
import AppUrlListener from 'AppUrlListener';
import PressBackToExitApp from 'PressBackToExitApp';
import AppCustomStatusBar from 'AppCustomStatusBar';
import subscriptionsPathHelper from 'helper/pathHelper/subscriptions';
import SubscriptionShowPage from 'pages/subscriptions/SubscriptionShowPage';
import DebugPage from 'pages/debug/DebugPage/DebugPage';
import debugsPathHelper from 'helper/pathHelper/debugs';
import SponsorshipIndexPage from 'pages/sponsorships/SponsorshipIndexPage';
import userInvitationsPathHelper from 'helper/pathHelper/userInvitations';
import SponsorshipNewPage from 'pages/sponsorships/SponsorshipNewPage';
import RedirectToLatestFailedForTransaction from 'RedirectToLatestFailedForTransaction';
import SideMenuVisibilityOnPage from 'SideMenuVisibilityOnPage';
import NotificationPreferencesIndexPage from 'pages/notificationPreferences/NotificationPreferencesIndexPage';
import userPreferencesPathHelper from 'helper/pathHelper/userPreferences';
import PushNotification from './PushNotification'
import SetupPrevLocation from './SetupPrevLocation'
import ClientExplainBankPage from 'pages/clientPayments/ClientExplainBankPage';
import InitializeGoogleAnalyticsV3 from './InitializeGoogleAnalyticsV3';
import InitializeGoogleAnalyticsV4 from './InitializeGoogleAnalyticsV4';
import InitializeGoogleTagManager from './InitializeGoogleTagManager';
import SignUpEmailPage from 'pages/users/SignUpEmailPage';

const AppRoute = () => {
    const {
        newUserRegistrationsPath,
        emailUserRegistrationsPath,
        newUserSessionsPath,
        preUserRegistrationsPath,
        afterUserRegistrationsPath,
        setupUserRegistrationsPath,
        afterSetupUserRegistrationsPath,
        forgotUserPasswordPath,
        sendForgotUserPasswordPath,
        resetUserPasswordPath
    } = usersPathHelper()

    const {
        indexTransactionsPath,
        newTransactionsPath
    } = transactionsPathHelper()

    const {
        indexUserSettingsPath
    } = userSettingsPathHelper()

    const { indexTransactionInfoPath } = transactionInfoPathHelper()

    const { pageNotFoundPath, rootPath } = appPathHelper()

    const { indexSettingsPath } = settingsPathHelper()

    const {
        indexBankAccountsPath,
        newBankAccountsPath
    } = bankAccountsPathHelper()

    const {
        indexUserClientsPath,
        newUserClientsPath
    } = userClientsPathHelper()

    const {
        showClientAcceptPaymentPath,
        showClientSelectBankPath,
        showExplainClientSelectedBankPath,
        showClientWaitingForLinkPath,
        showClientPaymentSuccessPath,
        showClientPaymentErrorPath,
    } = clientPaymentPathHelper()

    const { showSubscriptionsPath } = subscriptionsPathHelper()

    const {
        indexUserInvitationsPath,
        newUserInvitationsPath
    } = userInvitationsPathHelper()

    const {
        showNotificationUserPreferencesPath,
    } = userPreferencesPathHelper()

    const { indexDebugsPath } = debugsPathHelper()

    return (
        <>
            <SetupPrevLocation />

            <AppUrlListener />

            <PushNotification />

            <SideMenuVisibilityOnPage />

            <AppCustomStatusBar />

            <PressBackToExitApp />

            <RedirectToLatestFailedForTransaction />

            <InitializeGoogleTagManager />
            <InitializeGoogleAnalyticsV3 />
            <InitializeGoogleAnalyticsV4 />

            <IonRouterOutlet>
                <Route exact path={preUserRegistrationsPath} component={PreSignUpPage} />
                <Route exact path={newUserRegistrationsPath} component={SignUpPage} />
                <Route exact path={emailUserRegistrationsPath} component={SignUpEmailPage} />
                <Route exact path={afterUserRegistrationsPath} component={AfterSignUpPage} />
                <Route exact path={setupUserRegistrationsPath} component={SetupSignUpPage} />
                <Route exact path={newUserSessionsPath} component={SignInPage} />
                <Route exact path={afterSetupUserRegistrationsPath} component={AfterSetupSignupPage} />
                <Route exact path={forgotUserPasswordPath} component={ForgotPasswordPage} />
                <Route exact path={sendForgotUserPasswordPath} component={SendForgotPasswordPage} />
                <Route exact path={resetUserPasswordPath} component={ResetPasswordPage} />
                <Route exact path={indexDebugsPath} component={DebugPage} />

                <PrivateRoute path={[rootPath, indexSettingsPath, '/']} exact>
                    <MainPageTabs />
                </PrivateRoute>

                <PrivateRoute exact path={indexUserClientsPath}>
                    <UserClientsIndexPage />
                </PrivateRoute>
                <PrivateRoute exact path={newUserClientsPath}>
                    <UserClientNewPage />
                </PrivateRoute>
                <PrivateRoute exact path='/user_clients/:id(\d+)'>
                    <UserClientShowPage />
                </PrivateRoute>

                <PrivateRoute exact path={indexTransactionsPath}>
                    <TransactionsIndexPage />
                </PrivateRoute>
                <PrivateRoute exact path={newTransactionsPath}>
                    <TransactionNewPage />
                </PrivateRoute>
                <PrivateRoute exact path='/transactions/:id(\d+)'>
                    <TransactionShowPage />
                </PrivateRoute>
                <PrivateRoute exact path='/transactions/:id(\d+)/after_create'>
                    <TransactionAfterCreatePage />
                </PrivateRoute>
                <PrivateRoute exact path='/transactions/:id(\d+)/duplicate'>
                    <TransactionDuplicatePage />
                </PrivateRoute>

                <PrivateRoute exact path={indexUserSettingsPath}>
                    <UserSettingsPage />
                </PrivateRoute>

                <PrivateRoute exact path={indexTransactionInfoPath}>
                    <TransactionInfoIndexPage />
                </PrivateRoute>

                <PrivateRoute exact path='/bank_accounts/:id(\d+)'>
                    <BankAccountShowPage />
                </PrivateRoute>
                <PrivateRoute exact path={newBankAccountsPath}>
                    <BankAccountNewPage />
                </PrivateRoute>
                <PrivateRoute exact path={indexBankAccountsPath}>
                    <BankAccountsIndexPage />
                </PrivateRoute>

                <PrivateRoute exact path={showSubscriptionsPath}>
                    <SubscriptionShowPage />
                </PrivateRoute>

                <PrivateRoute exact path={showNotificationUserPreferencesPath}>
                    <NotificationPreferencesIndexPage />
                </PrivateRoute>

                <PrivateRoute exact path={newUserInvitationsPath}>
                    <SponsorshipNewPage />
                </PrivateRoute>
                <PrivateRoute exact path={indexUserInvitationsPath}>
                    <SponsorshipIndexPage />
                </PrivateRoute>

                <Route exact path={showClientAcceptPaymentPath} component={ClientAcceptPaymentLinkPage} />
                <Route exact path={showClientSelectBankPath} component={ClientSelectBankPage} />
                <Route exact path={showClientWaitingForLinkPath} component={ClientWaitingForPaymentLinkPage} />
                <Route exact path={showExplainClientSelectedBankPath} component={ClientExplainBankPage} />
                <Route exact path={showClientPaymentSuccessPath} component={ClientPaymentSuccessPage} />
                <Route exact path={showClientPaymentErrorPath} component={ClientPaymentErrorPage} />

                <Route exact path={pageNotFoundPath} component={PageNotFound} />
            </IonRouterOutlet>
        </>

    )
}

export default AppRoute
