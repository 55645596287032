import { IonButton, IonInput } from "@ionic/react"
import PageContent from "layout/PageContent"
import PageHeader from "layout/PageHeader"
import PageLayout from "layout/PageLayout"
import { ContentContainer } from "pages/subscriptions/SubscriptionShowPage/styled"
import { useTranslation } from "react-i18next"
import { Value, Wrapper } from "./styled"
import { SplashScreen } from '@capacitor/splash-screen';
import { useState } from "react"
import { useRollbar } from '@rollbar/react';

const DebugPage = () => {
    const rollbar = useRollbar();
    const { t } = useTranslation(['sponsorship', 'application'])
    const showSplashScreen = async () => {
        await SplashScreen.show({
            showDuration: 5000,
            autoHide: true,
        });
    }

    const [text, setText] = useState('')

    const handleSendTestErrorToRollbar = () => {
        const message = `Test error from ${process.env.REACT_APP_BASE} with ${process.env.REACT_APP_ROLLBAR_ENV} ROLLBAR_ENV`
        console.log('sending:', message);
        rollbar.error(message)
    }

    return (
        <PageLayout>
            <PageHeader
                title="DEBUG(BE REMOVE LATER)"
                withBackButton
                backTo="/users/sign_in"
            />

            <PageContent>
                <ContentContainer>
                    <Wrapper>
                        REACT_APP_BASE_API
                        <Value>
                            {process.env.REACT_APP_BASE_API}
                        </Value>
                    </Wrapper>

                    <Wrapper>
                        REACT_APP_IBANAPI_API_KEY

                        <Value>
                            {process.env.REACT_APP_IBANAPI_API_KEY}
                        </Value>
                    </Wrapper>

                    <Wrapper>
                        REACT_APP_BASE
                        <Value>
                            {process.env.REACT_APP_BASE}
                        </Value>
                    </Wrapper>

                    <Wrapper>
                        REACT_APP_CABLE_URL
                        <Value>
                            {process.env.REACT_APP_CABLE_URL}
                        </Value>
                    </Wrapper>

                    <Wrapper>
                        <IonButton
                            style={{ textTransform: 'uppercase' }}
                        >
                            {t('index_page.new_sponsorship')}
                        </IonButton>
                    </Wrapper>
                    <Wrapper>
                        <IonButton onClick={showSplashScreen}>Show SplashScreen</IonButton>
                    </Wrapper>

                    <Wrapper>
                        <IonButton onClick={handleSendTestErrorToRollbar}>Send test error to rollbar</IonButton>
                    </Wrapper>

                    <IonInput
                        placeholder="Type me!"
                        value={text}
                        onIonChange={(e) => setText(e.detail.value)}
                    />
                </ContentContainer>
            </PageContent>
        </PageLayout>
    )
}

export default DebugPage
