import PrimaryButton from 'components/PrimaryButton';
import PrimaryLink from 'components/PrimaryLink';
import RequestErrorMessages from 'components/RequestErrorMessages';
import { UserClientForm } from 'forms/userClientsForm';
import onErrorRedirect from 'helper/onErrorRedirect';
import userClientsPathHelper from 'helper/pathHelper/userClients';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import PageContent from 'layout/PageContent';
import PageFooter from 'layout/PageFooter';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCreateUserClientMutation } from 'store/services/userClients';

const UserClientNewPage = () => {
  const history = useHistory();
  const {
    createUserClientsPath,
    indexUserClientsPath
  } = userClientsPathHelper()
  const [submitButtonRef, handleClick] = useSubmitButtonRef()
  const [createUserClient, { isLoading, error }] = useCreateUserClientMutation()
  const { t } = useTranslation(['user_client', 'application'])

  const handleSubmit = async (data) => {
    try {
      await createUserClient({
        url: createUserClientsPath,
        body: data
      }).unwrap()
      history.replace(indexUserClientsPath)
    } catch (error) {
      console.error('UserClientNewPage#handleSubmit', error)
    }
  }

  if (onErrorRedirect(error)) return onErrorRedirect(error)

  return (
    <PageLayout>
      <PageHeader
        title={t('new_page.title')}
        withBackButton
        backTo={indexUserClientsPath}
      />

      <PageContent>
        <RequestErrorMessages error={error} />

        <UserClientForm
          submitButtonRef={submitButtonRef}
          onSubmit={handleSubmit}
        />

      </PageContent>

      <PageFooter>
        <div className='ion-margin-bottom'>
          <PrimaryLink to={indexUserClientsPath}>
            {t('cancel')}
          </PrimaryLink>
        </div>

        <div>
          <PrimaryButton
            onClick={handleClick}
            disabled={isLoading}
          >
            {t('submit')}
          </PrimaryButton>
        </div>

      </PageFooter>
    </PageLayout>
  );
};

export default UserClientNewPage;
