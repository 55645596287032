import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from './ClientSelectBankForm'
import { BaseFormikConfig } from 'forms/_baseForm'
import {
    bridgeapi_bank_no
} from '../transactionsForm/validation';
import {
    terms_and_conditions_accepted
} from '../usersForm/validation'

const FormValidationSchema = Yup
    .object()
    .shape({
        transaction: Yup.object().shape({
            bridgeapi_bank_no,
            terms_and_conditions_accepted,
        })
    })

const FormikConfig = {
    ...BaseFormikConfig,
    mapPropsToValues: ({ data }) => ({
        transaction: {
            bridgeapi_bank_no: data?.bridgeapi_bank_no ?? '',
            terms_and_conditions_accepted: data?.terms_and_conditions_accepted ?? false
        }
    }),
    validationSchema: FormValidationSchema,
}

export default withFormik(FormikConfig)(Form)
