import { Provider, ErrorBoundary } from '@rollbar/react';

// same configuration you would create for the Rollbar.js SDK
const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: process.env.REACT_APP_ROLLBAR_ENV,
    server: {
        host: process.env.REACT_APP_BASE
    },
    enabled: !!process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN
}

const RollbarProvider = ({ children }) => {
    /* Provider instantiates Rollbar client instance handling any uncaught errors or unhandled promises in the browser */
    return (
        <Provider config={rollbarConfig} >
            {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </Provider >
    )
}

export default RollbarProvider
