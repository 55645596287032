import styled from "styled-components";

// export const Container = styled.div`
// height: 100%;
// `

export const EmailContainer = styled.div`
  margin-top: min(calc(3.36vh + 3.36vw), 60px);
`;
export const EmailContainerTwo = styled.div`
  margin-top: min(calc(2.24vh + 2.24vw), 40px);
`;

export const PhoneContainerTwo = styled.div`
  margin-top: min(calc(2.24vh + 2.24vw), 40px);
`;

export const AcceptTermsLabel = styled.div`
  margin-top: min(calc(2.24vh + 2.24vw), 20px);
`;
export const CGUUnderline = styled.div`
  text-decoration: underline;
`;
export const EnterTheCode = styled.div`
  margin-top: min(calc(3.36vh + 3.36vw), 40px);
  margin-bottom: min(calc(3.36vh + 3.36vw), 20px);
  font-size: 20px;
`;

export const ContainerCode = styled.div`
  display: flex;
  justify-content: center;
`;
export const ButtonSendCode = styled.div`
  margin-top: min(calc(2.24vh + 2.24vw), 10px);
`;
export const PrimaryButton = styled.button`
  background-color: #3ab095;
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 7px;
  font-weight: bold;
`;
export const AcceptTermContainer = styled.div`
  margin-top: min(calc(3.36vh + 3.36vw), 60px);
`;

export const PasswordContainer = styled.div`
  margin-top: min(calc(2.24vh + 2.24vw), 40px);
`;
