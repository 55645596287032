import { ThemeProvider, createTheme } from '@mui/material/styles';
/* Datepicker from materual UI */
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const theme = createTheme({
    typography: {
        allVariants: {
            fontSize: '14px',
            fontFamily: 'Lexend Deca, serif',
        },
    },
    components: {
        MuiIcon: {
            defaultProps: {
                sx: {
                    color: 'red' // Did not work
                }
            }
        },
    },
});

const MUIProvider = ({ children }) => {
    return <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {children}
        </LocalizationProvider>
    </ThemeProvider>;
}
export default MUIProvider
