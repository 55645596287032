import PropTypes from 'prop-types'
import {
    IonRefresher,
    IonRefresherContent,
} from '@ionic/react'
import FullScreenSpinner from 'components/FullScreenSpinner'

import {
    Wrapper,
    Container,
    Row,
    Col,
} from './styled.js'
import { SafeAreaFading } from '../PageFooter/styled'
import { useEffect, useRef, useState } from 'react'

import { isPlatform } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard'
import { ResizeObserver } from 'resize-observer';
import { isEmpty } from 'lodash';

const PageContent = ({
    children,
    onRefresh,
    loading,
    contentProps = {},
    withAreaFading = true,
    ...rest
}) => {
    const {
        sizeSm = '8',
    } = contentProps
    const refreshTimeoutRef = useRef()

    const handleRefresh = (e) => {
        onRefresh()
        refreshTimeoutRef.current = setTimeout(() => {
            e.detail.complete()
        }, 1000);
    }

    useEffect(() => {
        return () => clearTimeout(refreshTimeoutRef.current)
    }, [])

    // Dynamaic adjust height when focus at Input field on device with not height
    const [adjustHeight, setAdjustHeight] = useState(0)
    useEffect(() => {
        if (isPlatform('capacitor')) {
            Keyboard.addListener('keyboardDidShow', info => {
                const elements = document.getElementsByTagName('ion-footer')
                if (elements) {
                    const footer = elements[0]
                    const refHeight = footer.offsetHeight / 1.5
                    setAdjustHeight(refHeight)
                }
            });

            Keyboard.addListener('keyboardDidHide', () => {
                const elements = document.getElementsByTagName('ion-footer')
                if (elements) {
                    setAdjustHeight(0)
                }
            });
        }

        return () => {
            if (isPlatform('capacitor')) {
                setAdjustHeight(0)
                Keyboard.removeAllListeners()
            }
        }
    }, [])
    const adjustDivRef = useRef()
    const observeRef = useRef()
    useEffect(() => {
        if (isPlatform('capacitor')) {
            if (isEmpty(adjustDivRef.current)) {
                return
            }

            if (adjustDivRef.current && !observeRef.current) {
                observeRef.current = true

                const resizeObserver = new ResizeObserver((event) => {
                    // To make it compatible with old version browser, we need to use external library of 'ResizeObserver'
                    // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize for how to use it in native, the custom lib is similar to this solution
                    const inlineSize = event[0].contentRect.height
                    const adjustHeight = inlineSize
                    if (adjustHeight) {
                        document.activeElement.scrollIntoView({ behavior: 'auto' })
                    }
                });

                resizeObserver.observe(adjustDivRef.current);
            }
        }
    }, [adjustHeight, adjustDivRef.current])

    return (
        <>
            <Wrapper {...rest}>
                {
                    onRefresh && (
                        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
                            <IonRefresherContent refreshingSpinner='circles' />
                        </IonRefresher>
                    )
                }
                <Container className='ion-no-margin'>
                    <Row className='ion-justify-content-center'>
                        <Col
                            className='ion-no-margin'
                            sizeSm={sizeSm}
                            {...contentProps}
                        >
                            {
                                loading
                                    ?
                                    <FullScreenSpinner />
                                    :
                                    <>
                                        {children}
                                        <div
                                            style={{
                                                height: adjustHeight,
                                                // border: "1px solid red"
                                            }}
                                            ref={adjustDivRef}
                                        />
                                        {
                                            withAreaFading ?
                                                <SafeAreaFading /> : null
                                        }
                                    </>
                            }

                        </Col>
                    </Row>

                </Container>
            </Wrapper>

        </>
    )
}

PageContent.propTypes = {
    children: PropTypes.node,
    onRefresh: PropTypes.func,
    loading: PropTypes.bool,
    contentSize: PropTypes.string,
}

export default PageContent
