import { IonTitle } from "@ionic/react"
import styled from "styled-components"

export const TitileContainer = styled(IonTitle)`
font-size: ${({ fontSize }) => fontSize};
color: var(${({ color }) => color});
font-weight: var(--ion-font-weight-medium);

& > div.page-title {    
    white-space: normal;
}
`
