import { createSlice } from '@reduxjs/toolkit'
import {
    ORAATQS_CLIENT_PAYMENT_ACCEPT_PAYMENT_LINK
} from 'constants/oneRequestAtATimeQueries'
import { ORAATQS_SLICE_NAME } from 'constants/sliceNameConstants'
import { clientPaymentsApi } from 'store/services/clientPayments'

// ORAATQS === oneRequestAtATimeQueries

const baseInitialSyncState = {
    loading: 'idle',
    currentRequestId: undefined,
    nextRequestId: undefined,
    timestamp: undefined,
}

const initialState = {}

// api: have to be a callback to prevent uninitialized error
const oraatqsApis = [
    {
        name: ORAATQS_CLIENT_PAYMENT_ACCEPT_PAYMENT_LINK,
        api: () => clientPaymentsApi.endpoints.updateClientAcceptPayment
    },
]


oraatqsApis.forEach((oraatqApi) => {
    initialState[oraatqApi.name] = baseInitialSyncState
})

initialState[ORAATQS_CLIENT_PAYMENT_ACCEPT_PAYMENT_LINK] = { ...baseInitialSyncState }

const addBuilderMatchers = (builder) => {
    oraatqsApis.forEach((oraatqApi) => {
        const stateSelector = localSelectCurrentFromOraatqsState(oraatqApi.name)
        builder
            .addMatcher(oraatqApi.api().matchPending, (parentState, action) => {
                const state = stateSelector(parentState)
                const incomingRequestId = action.meta.requestId
                state.nextRequestId = incomingRequestId
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = incomingRequestId
                }
            })
            .addMatcher(oraatqApi.api().matchFulfilled, (parentState, action) => {
                const state = stateSelector(parentState)
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                    state.nextRequestId = undefined
                    state.timestamp = Date().toString()
                }
            })
            .addMatcher(oraatqApi.api().matchRejected, (parentState, action) => {
                const state = stateSelector(parentState)
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined
                    state.nextRequestId = undefined
                }
            })
    })
}


const oraatqsSlice = createSlice({
    name: ORAATQS_SLICE_NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        addBuilderMatchers(builder)
    },
})

const selectOraatqsState = (state) => state[ORAATQS_SLICE_NAME]

export const localSelectCurrentFromOraatqsState = (name) => {
    return (state) => state[name]
}

export const generateGlobalSelectCurrentFromOraatqsState = (name) => {
    return (state) => {
        return selectOraatqsState(state)[name]
    }
}

export const selectORAATQAcceptPaymentLinkState = generateGlobalSelectCurrentFromOraatqsState(ORAATQS_CLIENT_PAYMENT_ACCEPT_PAYMENT_LINK)

export const isORAATQpending = (queryApi, oraatqSelector) => {
    const { getState } = queryApi
    const {
        loading,
        currentRequestId,
        nextRequestId,
    } = oraatqSelector(getState())

    if (loading !== 'pending' || nextRequestId !== currentRequestId) {
        return {
            error: {
                code: "ORAATQ is pending",
                message: "This query is pending"
            }
        }
    }

    return false
}

export default oraatqsSlice
