import { Form, useFormikContext } from 'formik'
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import BaseField from 'components/fields/BaseField';
import { useTranslation } from 'react-i18next';
import { decorateEmailString } from 'helper/stringHelper';

const Index = ({ submitButtonRef }) => {
    const { t } = useTranslation('user_client')
    const { setFieldValue } = useFormikContext();

    return (
        <Form className='first-form-control first-form-control--second-input-margin'>

            <BaseField
                placeholder={t('first_name')}
                name='user.individual_attributes.first_name'
            />

            <BaseField
                placeholder={t('last_name')}
                name='user.individual_attributes.last_name'
            />

            <BaseField
                placeholder={t('email')}
                name='user.email'
                onChange={event => setFieldValue('user.email', decorateEmailString(event.target.value))}
            />

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
