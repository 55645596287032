import { createSlice } from '@reduxjs/toolkit'
import { AUTH_SLICE_NAME } from 'constants/sliceNameConstants'

const initialState = {
    user: null,
    token: null,
    ws_token: null,
}

const authSlice = createSlice({
    name: AUTH_SLICE_NAME,
    initialState,
    reducers: {
        setCredentials(
            state,
            {
                payload: {
                    user,
                    token,
                    ws_token,
                }
            }
        ) {
            state.user = user
            state.token = token
            state.ws_token = ws_token
        },
        resetCredentials(state) {
            state.user = null
            state.token = null
            state.ws_token = null
        }
    },
})

export const {
    setCredentials,
    resetCredentials
} = authSlice.actions

export default authSlice

export const selectCurrentUser = (state) => state[AUTH_SLICE_NAME]

export const selectCableURLWithToken = (getState) => {
    const { ws_token } = selectCurrentUser(getState)
    return `${process.env.REACT_APP_CABLE_URL}?jid=${ws_token}`
}
