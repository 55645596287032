import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import DateRange from '@mui/icons-material/DateRange';
import { useState } from 'react';
import { IonContent } from '@ionic/react';
import {
    ModalContainer,
    DateTimeContainer,
} from './styled.js'
import {
    formatDate,
    reverseFormatDate,
} from 'helper/dateHelper';
import TextField from '../TextField';
import { useTranslation } from 'react-i18next';

/*
For more information
DOC: https://ionicframework.com/docs/api/datetime
Source code: https://github.com/ionic-team/ionic-framework/blob/main/core/src/components/datetime/datetime.tsx
*/
const DatePicker = ({
    value,
    onShow,
    onClose,
    onChange,
    dateTimeComponentProps = {},
    iconColor = 'var(--ion-color-text-primary)',
    ...rest
}) => {
    const { t } = useTranslation('application')
    const [showModal, setShowModal] = useState(false);
    const { presentation = "date" } = dateTimeComponentProps

    const handleClickShowDateTimePicker = () => {
        onShow && onShow()
        setShowModal(true)
    };

    const handleMouseDownDateTimePicker = (event) => {
        event.preventDefault();
    };

    const handleChange = (e) => {
        const rawValue = e.detail.value
        let formattedValue = rawValue
        if (presentation === 'date') {
            formattedValue = formatDate(rawValue)
        }
        onChange && onChange(formattedValue)
    }

    return (
        <>
            <TextField
                {...rest}
                value={value}
                onClick={handleClickShowDateTimePicker}
                InputProps={{
                    endAdornment: <InputAdornment position="end" >
                        <IconButton
                            aria-label="open-datetime-picker"
                            onClick={handleClickShowDateTimePicker}
                            onMouseDown={handleMouseDownDateTimePicker}
                        >
                            <DateRange style={{ color: iconColor }} />
                        </IconButton>
                    </InputAdornment >

                }}
            />

            <ModalContainer
                isOpen={showModal}
                onDidDismiss={() => {
                    setShowModal(false)
                    onClose && onClose()
                }}
            >
                <IonContent>
                    <DateTimeContainer
                        presentation={presentation}
                        value={reverseFormatDate(value)}
                        locale="fr-FR"
                        onIonChange={handleChange}
                        cancelText={t('close')}
                        doneText='OK'
                        {...dateTimeComponentProps}
                    />
                </IonContent>
            </ModalContainer>
        </>
    )
}

export default DatePicker
