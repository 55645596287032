import PrimaryBackLink from "components/PrimaryBackLink"
import PageContent from "layout/PageContent"
import PageFooter from "layout/PageFooter"
import PageHeader from "layout/PageHeader"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import { NotificationPreferencesForm } from 'forms/notificationPreferencesForm'
import {
    useGetNotificationUserPreferencesQuery,
    useUpdateNotificationUserPreferencesMutation,
} from "store/services/userPreferences"
import { findAnyErrorState, isAnyStateIsLoading } from "helper/multipleStatesChecker"
import useSubmitButtonRef from "hooks/useSubmitButtonRef"
import RequestErrorMessages from "components/RequestErrorMessages"
import userPreferencesPathHelper from "helper/pathHelper/userPreferences"

const NotificationPreferencesIndexPage = () => {
    const {
        showNotificationUserPreferencesPath,
        updateNotificationUserPreferencesPath,
    } = userPreferencesPathHelper()

    const [submitButtonRef,] = useSubmitButtonRef()
    const { t } = useTranslation(['notification_preference', 'application'])

    const queryState = useGetNotificationUserPreferencesQuery({ url: showNotificationUserPreferencesPath })
    const {
        data,
        isSuccess,
    } = queryState

    const [updateNotification, mutationState] = useUpdateNotificationUserPreferencesMutation()

    const states = [queryState, mutationState]
    const isLoading = isAnyStateIsLoading(states)
    const error = findAnyErrorState(states)

    const handleSubmit = async (data) => {
        try {
            await updateNotification({
                url: updateNotificationUserPreferencesPath,
                body: data
            }).unwrap()
        } catch (error) {
            console.error('NotificationPreferencesIndexPage#handleSubmit', error)
        }
    }

    let RenderContent
    if (isSuccess) {
        RenderContent = <NotificationPreferencesForm
            disabled={isLoading}
            submitButtonRef={submitButtonRef}
            onSubmit={handleSubmit}
            data={data.data}
        />
    }

    return (
        <PageLayout>
            <PageHeader
                withBackButton
                title={t('index_page.title')}
            />

            <PageContent loading={queryState.isLoading}>
                <RequestErrorMessages error={error} />

                {RenderContent}


            </PageContent>

            <PageFooter>
                <PrimaryBackLink>
                    {t('back')}
                </PrimaryBackLink>
            </PageFooter>
        </PageLayout>
    )
}

export default NotificationPreferencesIndexPage
