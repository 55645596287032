import {
    IonContent,
    IonHeader,
    IonList,
    IonMenuToggle,
    IonButtons
} from '@ionic/react';
import Spinner from 'components/FullScreenSpinner';
import transactionsPathHelper from 'helper/pathHelper/transactions';
import useApplication from 'hooks/useApplication';
import PageFooter from 'layout/PageFooter'
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import Dashboard from '@mui/icons-material/Dashboard'
import Receipt from '@mui/icons-material/Receipt'
import appPathHelper from 'helper/pathHelper/app';
import SettingMenuItem from 'pages/settings/SettingsPage/SettingMenuItem';
import SettingMenuItems from 'pages/settings/SettingsPage/SettingMenuItems';
import {
    Wrapper,
    HeaderLabel,
    NewButton,
    NewTextContainer,
    NewText,
} from './styled'
import { ToolbarWrapper } from 'layout/PageHeader/styled';
import { TitleLabel } from 'components/BaseLabels';
import SignOutButton from 'components/SignOutButton';
import EuroSymbol from '@mui/icons-material/EuroSymbol';

const SideMenu = () => {
    const { t } = useTranslation('setting')

    const { t: homeT } = useTranslation('home')

    const {
        newTransactionsPath,
        indexTransactionsPath
    } = transactionsPathHelper()

    const { rootPath } = appPathHelper()

    const { isReady } = useApplication()

    const { user } = useAuth()

    const upperMenuList = [
        {
            icon: Dashboard,
            title: homeT('title'),
            detail: homeT('detail'),
            to: rootPath,
        },
        {
            icon: Receipt,
            title: t('transaction.title'),
            detail: t('transaction.detail'),
            to: indexTransactionsPath,
        },
    ]

    return (
        <Wrapper
            content-id="main-content"
            swipeGesture={false} // Prevent side menu to be opened on mobile by swipe gesture.
        >
            {
                isReady
                    ?
                    <>
                        <IonHeader
                            style={{ marginTop: 'env(safe-area-inset-top)' }}
                            className='ion-no-border'
                            translucent
                        >
                            <ToolbarWrapper>
                                <IonButtons slot='start'>
                                    <TitleLabel>
                                        {user?.full_name}
                                    </TitleLabel>
                                </IonButtons>

                                <IonButtons slot='end'>
                                    <SignOutButton />
                                </IonButtons>
                            </ToolbarWrapper>

                        </IonHeader>

                        <IonContent>
                            <IonList>
                                <IonMenuToggle auto-hide="false">
                                    {
                                        upperMenuList.map((menu, index) => {
                                            return <SettingMenuItem menu={menu} key={index} />
                                        })
                                    }
                                </IonMenuToggle>

                                <HeaderLabel>
                                    {t('upper_case_title')}
                                </HeaderLabel>

                                <IonMenuToggle auto-hide="false">
                                    <SettingMenuItems />
                                </IonMenuToggle>
                            </IonList>
                        </IonContent>

                        <PageFooter>
                            <NewButton routerLink={newTransactionsPath}>
                                <NewTextContainer>
                                    <div>
                                        <EuroSymbol style={{ fontSize: '40px' }} />
                                    </div>
                                    <NewText>
                                        {homeT('new_transaction')}
                                    </NewText>
                                </NewTextContainer>
                            </NewButton>
                        </PageFooter>
                    </>
                    : <Spinner />
            }
        </Wrapper>
    );
};

export default SideMenu;
