import { baseSplitApi } from './_base'

const enrollmentsSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        preEnrollment: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
        }),
        setEmailEnrollment: builder.mutation({
            query: config => ({
                ...config,
                method: 'PUT',
            }),
        }),
        verifyEmailEnrollment: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
        }),
        setPhoneEnrollment: builder.mutation({
            query: config => ({
                ...config,
                method: 'PUT',
            }),
        }),
        verifyPhoneEnrollment: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
        }),
    }),
})

export const {
    usePreEnrollmentMutation,
    useSetEmailEnrollmentMutation,
    useVerifyEmailEnrollmentMutation,
    useSetPhoneEnrollmentMutation,
    useVerifyPhoneEnrollmentMutation,
} = enrollmentsSlice
