import { IonCol, IonRow } from '@ionic/react'
import { DetailLabel, TitleLabel } from 'components/BaseLabels'
import ContentList from 'components/ContentList'
import ContentListItem from 'components/ContentListItem'
import PrimaryBackLink from 'components/PrimaryBackLink'
import PrimaryButton from 'components/PrimaryButton'
import RequestErrorMessages from 'components/RequestErrorMessages'
import onErrorRedirect from 'helper/onErrorRedirect'
import userClientsPathHelper from 'helper/pathHelper/userClients'
import { nationPhoneFormat } from 'helper/phoneHelper'
import PageContent from 'layout/PageContent'
import { GridContainer } from 'layout/PageContent/styled'
import PageFooter from 'layout/PageFooter'
import PageHeader from 'layout/PageHeader'
import PageLayout from 'layout/PageLayout'
import { useTranslation } from 'react-i18next'
import { useGetUserClientsQuery } from 'store/services/userClients'

const UserClientItem = ({ userClient }) => {
  const {
    id,
    full_name,
    phone,
    email,
    phone_iso2
  } = userClient
  const { showUserClientsPath } = userClientsPathHelper(id)

  return <ContentListItem
    withBorder
    routerLink={showUserClientsPath}
  >
    <GridContainer>
      <IonRow>
        <IonCol>
          <TitleLabel className='ion-sm-margin-top'>
            {full_name}
          </TitleLabel>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <DetailLabel className='ion-sm-margin-top'>
            {/* {maskValue(phone) || '\u00A0'} */}
            {nationPhoneFormat(phone, phone_iso2)}
          </DetailLabel>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <DetailLabel className='ion-sm-margin-top'>
            {email || '\u00A0'}
          </DetailLabel>
        </IonCol>
      </IonRow>
    </GridContainer>
  </ContentListItem >
}

const UserClientsIndexPage = () => {
  const {
    indexUserClientsPath,
    newUserClientsPath
  } = userClientsPathHelper()
  const {
    data,
    isFetching,
    error,
    isSuccess
  } = useGetUserClientsQuery({ url: indexUserClientsPath })
  const { t } = useTranslation(['user_client', 'application'])

  let RenderContent
  if (isSuccess) {
    if (data?.data?.length) {
      RenderContent = <ContentList>
        {
          data.data.map(userClient => <UserClientItem
            userClient={userClient}
            key={userClient.id} />
          )
        }
      </ContentList>
    } else {
      RenderContent = <TitleLabel>
        {t('no_data')}
      </TitleLabel>
    }
  } else {
    onErrorRedirect(error)
  }

  return (
    <PageLayout>
      <PageHeader
        title={t('index_page.title')}
        withBackButton
      />

      <PageContent loading={isFetching}>
        <RequestErrorMessages error={error} />

        {RenderContent}
      </PageContent>

      <PageFooter>

        <div className='ion-margin-bottom'>
          <PrimaryBackLink>
            {t('cancel')}
          </PrimaryBackLink>
        </div>

        <PrimaryButton routerLink={newUserClientsPath}>
          {t('index_page.new_client')}
        </PrimaryButton>
      </PageFooter>
    </PageLayout>
  )
}

export default UserClientsIndexPage
