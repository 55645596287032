import { SINGLE_PAYMENT_DIALOG } from 'constants/modalNameContants'
import userPreferencesPathHelper from 'helper/pathHelper/userPreferences'
import useCurrentToggle from 'hooks/useCurrentToggle'
import useCustomIonAlert from 'hooks/useCustomIonAlert'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSinglePaymentsPopupHiddenQuery, useUpdateSinglePaymentsPopupHiddenMutation } from 'store/services/userPreferences'

const useDialogSingleTransaction = (onConfirm = null) => {
    const { t } = useTranslation(['transaction', 'application'])
    const [alertPresent, dismiss] = useCustomIonAlert()
    const { value, toggle } = useCurrentToggle(SINGLE_PAYMENT_DIALOG)

    // Get isHidden
    const { singlePaymentPopupHiddenPath, updateSinglePaymentPopupHiddenPath } = userPreferencesPathHelper()
    const result = useSinglePaymentsPopupHiddenQuery({
        url: singlePaymentPopupHiddenPath
    })
    const singlePaymentsPopupHidden = result?.data?.data?.single_payment_popup_hidden

    // State on confirm button
    const [confirmed, setConfirmed] = useState(false)

    // DEBUG : To test data is respond in below useEffect
    const fetchApi = async () => {
        const body = {
            "single_payment_popup_hidden": true
        }
        const result = await updateSinglePaylemtPopupHidden({
            url: updateSinglePaymentPopupHiddenPath,
            body: body
        }).unwrap()

        console.log('result : ', result)
    }

    useEffect(() => {
        if (value && !singlePaymentsPopupHidden) {
            const body = {
                "single_payment_popup_hidden": true
            }
            updateSinglePaylemtPopupHidden({
                url: updateSinglePaymentPopupHiddenPath,
                body: body
            }).unwrap()

            // ================
            // fetchApi() // For debug respond only
        }

    }, [confirmed])

    // Update isHidden
    const [updateSinglePaylemtPopupHidden] = useUpdateSinglePaymentsPopupHiddenMutation()

    const handleConfirm = async () => {
        setConfirmed(true)
    }

    const handleToggle = () => {
        toggle()
    }

    const showDialogSingleTransaction = () => {
        alertPresent({
            header: t('new_page.single_transaction_alert_dialog_title'),
            message: t('new_page.single_transaction_alert_dialog_detail'),
            inputs: [
                {
                    name: 'checkbok1',
                    type: 'checkbox',
                    label: t('do_not_see_this_message'),
                    value: true,
                    handler: handleToggle,
                    checked: value
                },
            ],
            buttons: [
                {
                    text: t('i_get_it'),
                    handler: handleConfirm
                }
            ],
            backdropDismiss: false,
        })
    }

    return [showDialogSingleTransaction, dismiss, confirmed, singlePaymentsPopupHidden]
}

export default useDialogSingleTransaction
