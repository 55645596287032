import { Form } from 'formik'
import { useTranslation } from 'react-i18next'
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import PasswordField from 'components/inputs/PasswordField';

const Index = ({ submitButtonRef }) => {

    const { t } = useTranslation('user')

    return (
        <Form className='first-form-control first-form-control--first-input-margin'>
            <PasswordField
                name='user.password'
                placeholder={t('new_password')}
            />

            <PasswordField
                name='user.password_confirmation'
                placeholder={t('password_confirmation')}
            />

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
