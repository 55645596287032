import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { getIn, useFormikContext } from 'formik';
import TextField from 'components/inputs/TextField';
import { useTranslation } from 'react-i18next';

const SiretTextMask = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="000 000 000 000 00"
            unmask={true}
            overwrite
            inputRef={ref}
            onAccept={(value) => onChange(value)}
        />
    );
});

const SiretNumberField = ({ name, ...rest }) => {
    const { t } = useTranslation('office')
    const {
        touched,
        errors,
        values,
        setFieldValue
    } = useFormikContext()

    const handleChange = (newValue) => {
        setFieldValue(name, newValue)
    }

    const value = getIn(values, name)

    return (
        <TextField
            {...rest}
            name={name}
            value={value}
            InputProps={{
                inputComponent: SiretTextMask,
            }}
            placeholder={t('siret_number')}
            onChange={handleChange}
            error={getIn(touched, name) && Boolean(getIn(errors, name))}
            helperText={getIn(touched, name) && getIn(errors, name)}
        />
    );
}

export default SiretNumberField
