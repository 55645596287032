import PropTypes from 'prop-types'
import Spinner from 'components/FullScreenSpinner'
import { IonContent, IonPage } from '@ionic/react';
import SideMenu from 'components/SideMenu'
import { IonReactRouter } from '@ionic/react-router';
import useApplication from 'hooks/useApplication';
import PageSplitPane from 'layout/PageSplitPane'

const InitializedLayout = ({ children }) => {
    const { isReady, isSideMenuVisible } = useApplication()

    return (
        <IonReactRouter>
            <PageSplitPane
                /*
                For `when` prop.
                If you would like to change this prop
                to be other value other than `lg`, you may have to review
                the code about media query because we use this lg(992px) size to hide and show components
                for both desktop and mobile platforms.
                Search project with below code to review.
                @media (min-width: 992px)
                @media (max-width: 992px)
                For more `when` prop information: https://ionicframework.com/docs/api/split-pane#setting-breakpoints
                */                
                when="lg"
                content-id="main-content"
                disabled={!isSideMenuVisible}
            >
                <SideMenu />
                <IonPage className="ion-page" id="main-content">
                    <IonContent>
                        {
                            isReady
                                ? children
                                : <Spinner />
                        }
                    </IonContent>
                </IonPage>
            </PageSplitPane>
        </IonReactRouter >
    )
}

InitializedLayout.propTypes = {
    children: PropTypes.node
}

export default InitializedLayout
