import { MULTIPLE_PAYMENT_DIALOG } from 'constants/modalNameContants'
import userPreferencesPathHelper from 'helper/pathHelper/userPreferences'
import useCurrentToggle from 'hooks/useCurrentToggle'
import useCustomIonAlert from 'hooks/useCustomIonAlert'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMultiplePaymentsPopupHiddenQuery, useUpdateMultiplePaymentsPopupHiddenMutation } from 'store/services/userPreferences'

const useDialogMutipleTransaction = (onConfirm = null) => {
    const { t } = useTranslation(['transaction', 'application'])
    const [alertPresent, dismiss] = useCustomIonAlert()
    const { value, toggle } = useCurrentToggle(MULTIPLE_PAYMENT_DIALOG)

    // Get isHidden
    const { multiplePaymentsPopupHiddenPath, updateMultiplePaymentsPopupHiddenPath } = userPreferencesPathHelper()
    const result = useMultiplePaymentsPopupHiddenQuery({
        url: multiplePaymentsPopupHiddenPath
    })
    const multiplePaymentsPopupHidden = result?.data?.data?.multiple_payments_popup_hidden

    // State on confirm button
    const [confirmed, setConfirmed] = useState(false)

    // DEBUG : To test data is respond in below useEffect
    const fetchApi = async () => {
        const body = {
            "multiple_payments_popup_hidden": true
        }
        const result = await updateMultiplePaylemtPopupHidden({
            url: updateMultiplePaymentsPopupHiddenPath,
            body: body
        }).unwrap()

        console.log('result : ', result)
    }

    useEffect(() => {
        if (value && !multiplePaymentsPopupHidden) {
            const body = {
                "multiple_payments_popup_hidden": true
            }
            updateMultiplePaylemtPopupHidden({
                url: updateMultiplePaymentsPopupHiddenPath,
                body: body
            }).unwrap()

            // ================
            // fetchApi() // For debug respond only
        }

    }, [confirmed])

    // Update isHidden
    const [updateMultiplePaylemtPopupHidden] = useUpdateMultiplePaymentsPopupHiddenMutation()

    const handleConfirm = async () => {
        setConfirmed(true)
    }

    const handleToggle = () => {
        toggle()
    }

    const showDialogMutipleTransaction = () => {
        alertPresent({
            header: t('new_page.multiple_transaction_alert_dialog_title'),
            message: t('new_page.multiple_transaction_alert_dialog_detail'),
            inputs: [
                {
                    name: 'checkbok1',
                    type: 'checkbox',
                    label: t('do_not_see_this_message'),
                    value: true,
                    handler: handleToggle,
                    checked: value
                },
            ],
            buttons: [
                {
                    text: t('i_get_it'),
                    handler: handleConfirm
                }
            ],
            backdropDismiss: false,
        })
    }

    return [showDialogMutipleTransaction, dismiss, confirmed, multiplePaymentsPopupHidden]
}

export default useDialogMutipleTransaction
