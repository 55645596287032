import PrimaryButton from 'components/PrimaryButton';
import PrimaryLink from 'components/PrimaryLink';
import RequestErrorMessages from 'components/RequestErrorMessages';
import { BankAccountForm } from 'forms/bankAccountsForm';
import onErrorRedirect from 'helper/onErrorRedirect';
import bankAccountsPathHelper from 'helper/pathHelper/bankAccounts';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import PageContent from 'layout/PageContent';
import PageFooter from 'layout/PageFooter';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCreateBankAccountMutation } from 'store/services/bankAccounts';

const BankAccountNewPage = () => {
    const history = useHistory();
    const {
        createBankAccountsPath,
        indexBankAccountsPath
    } = bankAccountsPathHelper()
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const [createNewBankAccount, { isLoading, error }] = useCreateBankAccountMutation()
    const { t } = useTranslation(['bank_account', 'application'])

    const handleSubmit = async (data) => {
        try {
            await createNewBankAccount({
                url: createBankAccountsPath,
                body: data
            }).unwrap()
            history.replace(indexBankAccountsPath)
        } catch (error) {
            console.error('BankAccountNewPage#handleSubmit', error)
        }
    }

    if (onErrorRedirect(error)) return onErrorRedirect(error)

    return (
        <PageLayout>
            <PageHeader
                title={t('new_page.title')}
                withBackButton
                backTo={indexBankAccountsPath}
            />

            <PageContent>
                <RequestErrorMessages error={error} />

                <BankAccountForm
                    submitButtonRef={submitButtonRef}
                    onSubmit={handleSubmit}
                />

            </PageContent>

            <PageFooter>
                <div className='ion-margin-bottom'>
                    <PrimaryLink to={indexBankAccountsPath}>
                        {t('cancel')}
                    </PrimaryLink>
                </div>

                <div>
                    <PrimaryButton
                        onClick={handleClick}
                        disabled={isLoading}
                    >
                        {t('submit')}
                    </PrimaryButton>
                </div>

            </PageFooter>
        </PageLayout>
    );
};

export default BankAccountNewPage;
