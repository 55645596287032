import PropTypes from 'prop-types'
import DefualtTextField from '@mui/material/TextField';
import { styled as mStyled } from '@mui/material/styles';

export const CssTextField = mStyled(DefualtTextField)({
    // helperText
    '& label': {
        fontSize: '14px',
        fontFamily: 'Lexend Deca, sans-serif'
    },
    // Input text that the user is entering.
    '& input': {
        color: 'var(--ion-color-text-secondary)',
        fontSize: '14px',
        fontFamily: 'Lexend Deca, sans-serif',
        '::placeholder': {
            color: 'var(--ion-color-text-primary-hover)',
            opacity: 1 /* Firefox */
        },
        ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
            color: 'var(--ion-color-text-primary-hover)'
        },
        '::-ms-input-placeholder': { /* Microsoft Edge */
            color: 'var(--ion-color-text-primary-hover)'
        },
        "&.Mui-disabled": {
            textFillColor: 'var(--ion-color-text-secondary)'
        }
    },
    // Errors text.
    '& p': {
        fontSize: '14px',
        fontFamily: 'Lexend Deca, sans-serif'
    },

    // Outline
    '& .MuiInput-underline': {
        '&:after': {
            borderBottomColor: 'var(--ion-color-text-secondary)'
        },
        '&:before': {
            borderBottomColor: 'var(--ion-color-text-secondary)',
        },
        '&:hover::before': {
            borderBottom: '1px solid var(--ion-color-text-secondary) !important'
        }
    },

});

const TextField = ({
    value = '',
    multiline = false,
    ...rest
}) => {
    return (
        <CssTextField
            autoComplete="off"
            fullWidth
            variant="standard"
            value={value}
            multiline={multiline}
            {...rest}
        />
    )
}

TextField.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    multiline: PropTypes.bool
}

export default TextField
