export const formatNumberToCurrency = (number) => {
    return new Intl.NumberFormat('fr', {
        style: 'currency',
        currency: 'EUR',
    }).format(number).replace(',00', '')
}

export const formatNumber = (number) => {
    return new Intl.NumberFormat('fr').format(number)
}
