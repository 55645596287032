import {
    IonDatetime,
    IonModal,
} from '@ionic/react';
import styled from "styled-components";

export const DateTimeContainer = styled(IonDatetime)`
height: auto;
width: auto;
max-width: 350px;
--ion-color-base: var(--ion-color-primary-greeny-blue) !important;
`

const MODAL_HEIGHT = '382px'

export const ModalContainer = styled(IonModal)`
--width: 290px;
--height: ${MODAL_HEIGHT};
--border-radius: 8px;

ion-datetime {
    height: ${MODAL_HEIGHT};
}
`
