import {
    IonButton,
} from '@ionic/react';
import Notification from '@mui/icons-material/NotificationsOutlined'
import {
    useGetNotificationUserPreferencesQuery,
    useUpdateNotificationUserPreferencesMutation,
} from "store/services/userPreferences"
import { isAnyStateIsLoading } from "helper/multipleStatesChecker"
import userPreferencesPathHelper from "helper/pathHelper/userPreferences"

const NotificationButton = () => {
    const {
        showNotificationUserPreferencesPath,
        updateNotificationUserPreferencesPath,
    } = userPreferencesPathHelper()

    const queryState = useGetNotificationUserPreferencesQuery({ url: showNotificationUserPreferencesPath })
    const {
        data,
        isSuccess,
    } = queryState

    const [updateNotification, mutationState] = useUpdateNotificationUserPreferencesMutation()

    const states = [queryState, mutationState]
    const isLoading = isAnyStateIsLoading(states)

    const handleToggleNoti = async () => {
        try {
            await updateNotification({
                url: updateNotificationUserPreferencesPath,
                body: {
                    user_preference: {
                        app_notification: !data?.data?.app_notification
                    }
                }
            }).unwrap()
        } catch (error) {
            console.error('NotificationButton#handleToggleNoti', error)
        }
    }

    let RenderContent = null
    if (isSuccess) {
        const color = data.data.app_notification ? 'var(--ion-color-primary-greeny-blue)' : 'var(--ion-color-text-primary)'
        RenderContent = <IonButton
            onClick={handleToggleNoti}
            isLoading={isLoading}
        >
            <Notification
                style={{ fontSize: '32px', color }}
            />
        </IonButton>
    }

    return RenderContent
}

export default NotificationButton
