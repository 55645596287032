import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from './TransactionInfoForm'
import { BaseFormikConfig, id } from 'forms/_baseForm'
import {
    payment_message,
    redirect_to_pending_on_open,
    consultation_label,
    consultation_place,
} from '../userPreferencesForm/validation';
import {
    address,
    postal_code,
    city,
} from '../contactDataForm/validation'
import {
    siret_number,
    ape_code,
    adeli_number,
} from '../officesForm/validation'

const FormValidationSchema = Yup
    .object()
    .shape({
        user: Yup.object().shape({
            signature_image: Yup.mixed(),
            user_preference_attributes: Yup.object().shape({
                id,
                payment_message,
                redirect_to_pending_on_open,
                consultation_label,
                consultation_place,
            }),
            office_attributes: Yup.object().shape({
                id,
                siret_number,
                ape_code,
                adeli_number,
                contact_data_attributes: Yup
                    .array()
                    .of(Yup.object().shape({
                        id,
                        address,
                        postal_code,
                        city,
                    })),
            }),
        })
    })

const FormikConfig = {
    ...BaseFormikConfig,
    mapPropsToValues: ({
        data: {
            signature_image,
            user_preference,
            office,
        }
    }) => {
        const { primary_contact_datum } = office
        return {
            user: {
                signature_image: {
                    url: signature_image?.url
                },
                user_preference_attributes: {
                    id: user_preference.id ?? '',
                    payment_message: user_preference.payment_message ?? '',
                    default_payment_message: user_preference.default_payment_message ?? '',
                    redirect_to_pending_on_open: user_preference.redirect_to_pending_on_open,
                    consultation_label: user_preference.consultation_label ?? '',
                    consultation_place: user_preference.consultation_place ?? '',
                },
                office_attributes: {
                    id: office.id ?? '',
                    siret_number: office.siret_number ?? '',
                    ape_code: office.ape_code ?? '',
                    adeli_number: office.adeli_number ?? '',
                    contact_data_attributes: [{
                        id: primary_contact_datum?.id ?? null,
                        address: primary_contact_datum?.address ?? '',
                        postal_code: primary_contact_datum?.postal_code ?? '',
                        city: primary_contact_datum?.city ?? ''
                    }],
                },
            }
        }
    },
    validationSchema: FormValidationSchema,
}

export default withFormik(FormikConfig)(Form)
