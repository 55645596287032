import SortByAlpha from "@mui/icons-material/SortByAlpha"
import SwapVert from "@mui/icons-material/SwapVert"
import SortLink from "components/SortLink"
import { SORT_TRANSACTIONS_TOGGLE_NAME } from "constants/modalNameContants"
import { TRANSACTIONS_BASE_PATH } from "constants/routeConstants"
import useCurrentIndexPageConfig from "hooks/useCurrentIndexPageConfig"
import { useModal } from "hooks/useModal"
import { useTranslation } from "react-i18next"

const SortModal = () => {
    const { t } = useTranslation('transaction')
    const {
        Modal,
        hide,
    } = useModal(SORT_TRANSACTIONS_TOGGLE_NAME)

    const [pagesConfig, { setSort }] = useCurrentIndexPageConfig(TRANSACTIONS_BASE_PATH)
    const { q: { s } } = pagesConfig

    const handleSortLinkClick = (value) => {
        setSort(value)
        hide()
    }

    const sortData = [
        {
            label: t('index_page.sort_by.label'),
            value: 'label',
            Icon: SortByAlpha,
        },
        {
            label: t('index_page.sort_by.amount'),
            value: 'amount',
            Icon: '0-9',
            isStringIcon: true,
        },
        {
            label: t('index_page.sort_by.date'),
            // value: 'pending_first_execution_date',
            value: 'execution_date',
            Icon: SwapVert
        },
        {
            label: t('index_page.sort_by.client'),
            value: 'full_name',
            Icon: SortByAlpha
        },
    ]

    const renderSort = () => {
        return sortData.map((sort, index) => {
            return <div
                key={index}
                onClick={() => handleSortLinkClick(sort.value)}
            >
                <SortLink
                    {...sort}
                    currentValue={s}
                />
            </div>
        })
    }

    return (
        <Modal modalName={SORT_TRANSACTIONS_TOGGLE_NAME}>
            {renderSort()}
        </Modal>
    )
}

export default SortModal
