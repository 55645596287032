// Source of information to implement this
// https://github.com/alinemorelli/react-gtm
// =======================================
// https://www.learnbestcoding.com/post/22/reactjs-using-google-analytics-tag-manager
// https://www.plumfind.com/academy/how-to-implement-google-tag-manager-for-single-page-applications-such-as-react
// https://medium.com/finnovate-io/integrating-google-tag-manager-with-a-react-app-5a8584ee2251


import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const InitializeGoogleTagManager = () => {
    useEffect(() => {
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
        }

        TagManager.initialize(tagManagerArgs)
    })

    return null
}

export default InitializeGoogleTagManager
