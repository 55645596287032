import { useEffect, useRef, useState } from 'react'
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import './style.scss';
import { getIn, useField, useFormikContext } from 'formik';
import { FormControl, FormHelperText, Input, TextField } from '@mui/material';
import { NewTxPhoneInput } from 'components/transactions/PhoneInput/styled';
import usePreviousValue from 'hooks/usePreviousValue';
import { changeFormatSyntaxToNumberFormat, changeFromIso2SyntaxToNumberSyntax, changeFromNumberSyntaxToIso2Syntax, changeNumberSyntaxToFormatSyntax, checkPhoneNumberInputLength } from 'helper/phoneHelper';
import { WrapperAsterisk } from './styled';

const IntlPhoneInput = ({ Component = NewTxPhoneInput, variant, name, countryName, intlRef, appendasterisk, ..._props }) => {
    const [container, setContainer] = useState(null)
    const itiRef = useRef()

    // Phone number
    const [, meta, helper] = useField(name)
    const { value } = meta
    const { setValue } = helper
    const form = useFormikContext()

    // Phone iso2
    const [, metaIso2, helperIso2] = useField(countryName)
    const { value: valueIso2 } = metaIso2
    const { setValue: setValueIso2 } = helperIso2

    const numSyntax = changeFromIso2SyntaxToNumberSyntax(value, valueIso2, itiRef)
    const formatSyntax = changeNumberSyntaxToFormatSyntax(numSyntax)

    useEffect(() => {
        if (!container) return;

        // Fix : set 'la' because it is country that not in bridgeAPI compat
        // And the cause the error that we cannot fill phone number in field if we set variable 'iso2' value -
        // as same as first of item of preferredCountries (in this file is 'fr')
        // so we need to set it to some country that not available with bridgeAPI and-
        // we can change it back to the country that available
        const iso2 = valueIso2 ? valueIso2 : "la"

        itiRef.current = intlTelInput(container, {
            // any initialisation options go here
            separateDialCode: true,
            formatOnDisplay: false,
            initialCountry: iso2,
            preferredCountries: ["fr", "re"],
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js',
            customPlaceholder: function (_selectedCountryPlaceholder, _selectedCountryData) {
                return "06 01 02 03 04";
            },
        });

        // This is for set country from outside,
        // It use for set the selected country of PRO
        intlRef && (intlRef.current = itiRef.current)

        const handleCountryChange = () => {
            setValueIso2(itiRef.current.getSelectedCountryData().iso2)
        }
        container.addEventListener("countrychange", handleCountryChange);

        return () => {
            intlRef && (intlRef.current = undefined)
            container.removeEventListener("countrychange", handleCountryChange)
            itiRef.current.destroy()
        }

    }, [container])

    const previousIso2 = usePreviousValue(valueIso2)
    useEffect(() => {
        // Skip the process on the first time (No previous iso2 at beginning)
        if (!previousIso2) return

        const numberSyntax = changeFromIso2SyntaxToNumberSyntax(value, previousIso2) // Convert to the pattern '0123456789'
        const iso2Syntax = changeFromNumberSyntaxToIso2Syntax(numberSyntax, valueIso2) // Covert to '+XX123456789' (XX) stand for calling country code
        setValue(iso2Syntax)
        intlRef && (intlRef.current.setCountry(valueIso2))

    }, [valueIso2])

    const handleChange = (event) => {
        const isValid = checkPhoneNumberInputLength(event.target.value)
        if (isValid) return

        const iso2Syntax = changeFromNumberSyntaxToIso2Syntax(event.target.value, valueIso2)
        const numberSyntax = changeFormatSyntaxToNumberFormat(iso2Syntax)
        setValue(numberSyntax)
    }

    const isError = getIn(form.touched, name) && Boolean(getIn(form.errors, name))

    return (
        <FormControl
            fullWidth
        >
            {
                appendasterisk ? (
                    <WrapperAsterisk>
                        <Component
                            id="phone"
                            type="tel"
                            ref={setContainer}
                            error={isError}
                            onChange={handleChange}
                            value={formatSyntax}
                        />
                    </WrapperAsterisk>
                ) : (
                    <Component
                        id="phone"
                        type="tel"
                        ref={setContainer}
                        error={isError}
                        onChange={handleChange}
                        value={formatSyntax}
                    />
                )
            }

            {
                isError ? (
                    <FormHelperText
                        id="my-helper-text"
                        variant={variant}
                        error
                    >
                        {getIn(form.errors, name)}
                    </FormHelperText>
                ) : null
            }
        </FormControl>
    )
}

export default IntlPhoneInput
