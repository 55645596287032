import dayjs from "dayjs"
import { isEmpty } from "lodash"

const APP_DATE_FORMAT = 'DD/MM/YYYY'

const API_DATE_FORMAT = 'YYYY-MM-DD'

export const getDayjsObject = (date) => {
    if (isEmpty(date)) {
        return date
    }
    let dayjsObj = date
    if (typeof dayjsObj === 'string') {
        let rawFormat = dayjsObj.includes('-') ? API_DATE_FORMAT : APP_DATE_FORMAT
        dayjsObj = dayjs(dayjsObj, rawFormat)
    }
    return dayjsObj
}

export const formatDateSendToAPIHelper = (date) => {
    if (isEmpty(date)) {
        return date
    }
    return getDayjsObject(date).format(API_DATE_FORMAT)
}

/*
Convert 01/12/2022 to 2022-12-01
*/
export const reverseFormatDate = (date) => {
    if (isEmpty(date)) {
        return date
    }
    return dayjs(date, APP_DATE_FORMAT).format(API_DATE_FORMAT)
}

/*
Convert 2022-12-01 to 01/12/2022
*/
export const formatDate = (date) => {
    const dayjsObj = getDayjsObject(date)
    if (isEmpty(dayjsObj)) {
        return dayjsObj
    }
    return dayjsObj.format(APP_DATE_FORMAT)
}

export const formatDateTimeSendToAPI = (date) => date

export const passedSendMailTimeSchedule = (date) => {
    const _date = getDayjsObject(date)
    const currentDateAtStart = dayjs().startOf('day')
    return _date < currentDateAtStart
}
