import {
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/react'
import Settings from '@mui/icons-material/Settings'
import Dashboard from '@mui/icons-material/Dashboard'
import { HOME_TAB_NAME, SETTINGS_TAB_NAME } from 'constants/appConstants'
import HomePage from 'pages/application/HomePage';
import appPathHelper from 'helper/pathHelper/app'
import settingsPathHelper from 'helper/pathHelper/settings'
import transactionsPathHelper from 'helper/pathHelper/transactions'
import SettingsPage from 'pages/settings/SettingsPage'
import { Route, Redirect } from 'react-router-dom'
import EuroSymbol from "@mui/icons-material/EuroSymbol"

import './style.scss'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PrivateRoute from 'components/PrivateRoute';

const MainPageTabs = () => {

    const [refresh, setRefresh] = useState()

    const { t } = useTranslation('home')

    const { rootPath } = appPathHelper()
    const { newTransactionsPath } = transactionsPathHelper()
    const { indexSettingsPath } = settingsPathHelper()

    useEffect(() => {
        const ref = setTimeout(() => {
            // For some reason, custom IonTabBar did not render correctly
            // setState to re-render to temporary fix.
            // To reproduce the error, comment out 'setRefresh(true)' code
            // and try to go to another page and come back.
            setRefresh(true)
        }, 100);

        return () => clearTimeout(ref)
    }, [])

    return (
        <IonTabs>
            <IonRouterOutlet>
                {/* Tabs would be show on these routes */}
                <PrivateRoute exact path={`/:tab(${HOME_TAB_NAME})`}>
                    <HomePage />
                </PrivateRoute>
                <PrivateRoute exact path={`/:tab(${SETTINGS_TAB_NAME})`}>
                    <SettingsPage />
                </PrivateRoute>
                <Route exact path='/' render={() => <Redirect to={rootPath} />} />
            </IonRouterOutlet>

            <IonTabBar slot="bottom" refresh={refresh}>
                {/* Placeholder to fill the vacant space make IonTabBar fit with screen */}
                <IonTabButton
                    className='placeholder-tab-btn'
                    tab={HOME_TAB_NAME}
                    href={rootPath}
                >
                </IonTabButton>

                <IonTabButton
                    tab={HOME_TAB_NAME}
                    href={rootPath}
                >
                    <div className='icon-container'>
                        <Dashboard style={{ fontSize: '32px' }} />
                    </div>

                    <IonLabel>
                        {t('tab_names.activity')}
                    </IonLabel>
                </IonTabButton>

                <IonTabButton
                    tab="shadow-euro-sign-tab"
                    href={newTransactionsPath}
                >
                    {' '}
                </IonTabButton>

                <IonTabButton
                    tab="euro-sign-tab"
                    href={newTransactionsPath}
                    id="euro-sign-button-tab">

                    <div className='euro-sign-container'>
                        <div className='euro-sign'>
                            <EuroSymbol style={{ fontSize: '40px' }} />
                        </div>
                        <div className='new-text'>
                            {t('new_transaction')}
                        </div>
                    </div>
                </IonTabButton>

                <IonTabButton
                    tab={SETTINGS_TAB_NAME}
                    href={indexSettingsPath}
                >
                    <div className='icon-container'>
                        <Settings style={{ fontSize: '32px' }} />
                    </div>

                    <IonLabel>
                        {t('tab_names.settings')}
                    </IonLabel>
                </IonTabButton>

                {/* Placeholder to fill the vacant space make IonTabBar fit with screen */}
                <IonTabButton
                    className='placeholder-tab-btn'
                    tab={SETTINGS_TAB_NAME}
                    href={indexSettingsPath}
                >
                </IonTabButton>
            </IonTabBar>

        </IonTabs >
    )
}

export default MainPageTabs
