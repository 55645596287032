import {
    baseSplitApi,
    baseCreateResourceInvalidatesTags,
} from './_base'

export const userSubscriptionsSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getUserSubscription: builder.query({
            query: config => config,
            providesTags: baseCreateResourceInvalidatesTags
        }),
        updateUserSubscription: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
        updateEndUserSubscription: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
    }),
})

export const {
    useGetUserSubscriptionQuery,
    useUpdateUserSubscriptionMutation,
    useUpdateEndUserSubscriptionMutation,
} = userSubscriptionsSlice
