import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { selectApplicationState } from 'store/slices/application';

const usePrevLocation = () => {
    const currentLocation = useLocation()
    const { prevLocation } = useSelector(selectApplicationState);

    return useMemo(() => [prevLocation, currentLocation], [prevLocation, currentLocation])
}

export default usePrevLocation
