import { withFormik } from 'formik'
import Form from './UserSetupForm'
import { password } from './validation';
import { first_name, last_name } from 'forms/individualsForm/validation';
import {
    iban,
    bank_name,
    bank_city,
} from 'forms/bankAccountsForm/validation';
import * as Yup from 'yup'
import { BaseFormikConfig, id } from 'forms/_baseForm'

const UserFormValidationSchema = Yup
    .object()
    .shape({
        user: Yup.object().shape({
            password,
            individual_attributes: Yup.object().shape({
                first_name,
                last_name
            }),
        })
    })

const UserBankAccountFormValidationSchema = Yup
    .object()
    .shape({
        user: Yup.object().shape({
            bank_accounts_attributes: Yup.array()
                .of(Yup.object().shape({
                    iban,
                    bank_name,
                    bank_city,
                }))
        })
    })

const UserSubscriptionFormValidationSchema = Yup
    .object()
    .shape({
        user: Yup.object().shape({
            user_subscription_attributes: Yup.object().shape({
                plan_id: id,
            }),

        })
    })

export const UserSetupStepSchemas = [
    UserFormValidationSchema,
    UserBankAccountFormValidationSchema,
    UserSubscriptionFormValidationSchema,
]

export const maxUserSetupSteps = UserSetupStepSchemas.length

const UserFormikConfig = {
    ...BaseFormikConfig,
    mapPropsToValues: () => ({
        user: {
            password: '',
            individual_attributes: {
                first_name: '',
                last_name: ''
            },
            bank_accounts_attributes: [{
                iban: '',
                bank_name: '',
                bank_city: '',
            }],
            user_subscription_attributes: {
                plan_id: 1,
            },
            user_promotion_codes_attributes: [
                {
                    raw_promotion_code: ''
                }
            ]
        }
    }),
    validationSchema: ({ currentStep }) => {
        return UserSetupStepSchemas[currentStep - 1]
    },
}

export default withFormik(UserFormikConfig)(Form)
