import { TitleLabel } from "components/BaseLabels"
import { getIn, useFormikContext } from "formik"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const Container = styled(TitleLabel)`
margin-top: 11px !important;
font-size: 11px;
cursor: pointer;
`

const ResetPaymentMessageToDefaultField = ({ paymentMessageName }) => {
    const defaultPaymentMessageName = paymentMessageName.replace('payment_message', 'default_payment_message')
    const { values, setFieldValue } = useFormikContext()
    const { t } = useTranslation('user_preference')

    const handleReset = () => {
        const defaultMessage = getIn(values, defaultPaymentMessageName)
        setFieldValue(paymentMessageName, defaultMessage)
    }

    return (
        <Container
            className="ion-text-end"
            onClick={handleReset}
        >
            {t('reset_payment_message')}
        </Container>
    )
}

export default ResetPaymentMessageToDefaultField
