import styled from "styled-components";

export const UserClientPhoneInput = styled.input`
width: 100%;
font-size: 16px;
background: var(--ion-background-color);
height: 30px;
border-top : 0;
border-left : 0;
border-right : 0;
border-width: thin;
border-color: ${({ error }) => error ? 'var(--ion-color-text-phone-error);' : 'rgba(0,0,0,0.87);'}
outline: 0;
&:focus-visible {
    border-width: 2px;
    border-color: #1976d2;
}
`
