import { USERS_BASE_PATH } from "constants/routeConstants";

// Please visit ./_base.js for more information.

const usersPathHelper = () => {
  return {
    // @Used for: internal
    preUserRegistrationsPath: `/${USERS_BASE_PATH}/pre_sign_up`,
    // @Used for: internal
    newUserRegistrationsPath: `/${USERS_BASE_PATH}/sign_up`,
    // @Used for: internal
    emailUserRegistrationsPath: `/${USERS_BASE_PATH}/sign_up_email`,
    // @Used for: internal
    afterUserRegistrationsPath: `/${USERS_BASE_PATH}/after_sign_up`,
    // @Used for: external
    /* @Description: Since we use the Devise-invitable gem to implement the registration feature
        so endpoint looks like this but we would stick with the pathname for readable sake. */
    createUserRegistrationsPath: `/${USERS_BASE_PATH}/invitation`,
    // @Used for: internal
    // @Example /setup?invitation_token=abc-token
    // @Description: This link would be called from the link in email
    setupUserRegistrationsPath: `/${USERS_BASE_PATH}/setup`,
    // @Used for: internal
    afterSetupUserRegistrationsPath: `/${USERS_BASE_PATH}/after_setup`,
    // @Used for: external
    updateSetupUserRegistrationsPath: `/${USERS_BASE_PATH}/invitation`,

    // @Used for: internal
    newUserSessionsPath: `/${USERS_BASE_PATH}/sign_in`,
    // @Used for: external
    createUserSessionsPath: `/${USERS_BASE_PATH}/sign_in`,

    // @Used for: external
    deleteUserSessionsPath: `/${USERS_BASE_PATH}/sign_out`,

    // @Used for: external
    refreshTokenUsersPath: `/${USERS_BASE_PATH}/refresh_token`,

    // @Used for: internal
    forgotUserPasswordPath: `/${USERS_BASE_PATH}/password/forgot_password`,
    // @Used for: external
    // @Description: POST -> Sent reset password instuction
    createUserPasswordPath: `/${USERS_BASE_PATH}/password`,
    // @Used for: internal
    sendForgotUserPasswordPath: `/${USERS_BASE_PATH}/password/forgot_password/send`,
    // @Used for: internal
    // @Example /password/edit?reset_password_token=abc-token
    // @Description: This link would be called from the link in email
    resetUserPasswordPath: `/${USERS_BASE_PATH}/password/edit`,
    // @Used for: external
    // @Description: POST -> Reset user password
    updateUserPasswordPath: `/${USERS_BASE_PATH}/password`,
  };
};

export default usersPathHelper;
