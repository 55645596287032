
import { IonButton } from '@ionic/react'
import styled from 'styled-components';

export const Button = styled(IonButton)`
height: 100% !important;
`

export const Title = styled.div`
font-weight: 600;
font-size: 10px;
color: var(--ion-color-text-primary);
`
