import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from './BankAccountForm'
import { BaseFormikConfig, id } from 'forms/_baseForm'
import {
   iban,
   bank_account_name,
   bank_name,
} from './validation';

const FormValidationSchema = Yup
   .object()
   .shape({
      bank_account: Yup.object().shape({
         id,
         iban,
         bank_account_name,
         bank_name,
      })
   })

const FormikConfig = {
   ...BaseFormikConfig,
   mapPropsToValues: ({ data }) => ({
      bank_account: {
         id: data?.id ?? null,
         iban: data?.iban ?? '',
         bank_account_name: data?.bank_account_name ?? '',
         bank_country: data?.bank_country ?? '',
         bank_city: data?.bank_city ?? '',
         bank_name: data?.bank_name ?? '',
      }
   }),
   validationSchema: FormValidationSchema,
}

export default withFormik(FormikConfig)(Form)
