import { IonCol, IonRow } from "@ionic/react"

const MainFieldAndSubButtonContainer = ({ MainField, SubButton }) => {
    return (
        <div>
            <IonRow>
                <IonCol
                    size="10.5"
                    className="ion-align-self-center"
                >
                    {MainField && <MainField />}
                </IonCol>
                <IonCol
                    size="1.5"
                    className="ion-align-self-center"
                >
                    {SubButton && <SubButton />}
                </IonCol>
            </IonRow>
        </div>

    )
}

export default MainFieldAndSubButtonContainer
