import { changeFromIso2SyntaxToNumberSyntax } from 'helper/phoneHelper'
import * as Yup from 'yup'

export const last_name = Yup.string().required("Le nom de famille doit être rempli.")

export const first_name = Yup.string().required("Le prénom doit être rempli.")

export const email = Yup
    .string()
    .email('L’e-mail doit être un e-mail valide.')

export const phone = Yup
    .string()
    .test('phone', "Le numéro de téléphone doit être renseigné.", validatePhone)

export function validatePhone(value, context) {
    const phone_iso2 = context.from[1].value.user_client.phone_iso2
    const number = changeFromIso2SyntaxToNumberSyntax(value, phone_iso2)

    const isAllNumber = /^\d+$/.test(number)
    if (number.length === 10 && isAllNumber) return true

    return false
}
