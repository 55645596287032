import {
    baseCreateResourceInvalidatesTags,
    baseSplitApi,
} from './_base'

const userSettingsSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getUserPreferences: builder.query({
            query: config => config,
            providesTags: baseCreateResourceInvalidatesTags
        }),
        updateUserPreferences: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
        getNotificationUserPreferences: builder.query({
            query: config => config,
            providesTags: baseCreateResourceInvalidatesTags
        }),
        updateNotificationUserPreferences: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
        singlePaymentsPopupHidden: builder.query({
            query: config => config,
            providesTags: baseCreateResourceInvalidatesTags
        }),
        updateSinglePaymentsPopupHidden: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
        multiplePaymentsPopupHidden: builder.query({
            query: config => config,
            providesTags: baseCreateResourceInvalidatesTags
        }),
        updateMultiplePaymentsPopupHidden: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
    }),
})

export const {
    useGetUserPreferencesQuery,
    useUpdateUserPreferencesMutation,
    useGetNotificationUserPreferencesQuery,
    useUpdateNotificationUserPreferencesMutation,
    useSinglePaymentsPopupHiddenQuery,
    useUpdateSinglePaymentsPopupHiddenMutation,
    useMultiplePaymentsPopupHiddenQuery,
    useUpdateMultiplePaymentsPopupHiddenMutation,

} = userSettingsSlice
