import {
    getLocalDataHelper,
    resetLocalDataHelper,
    setLocalDataHelper,
} from 'helper/localStorageHelper'
import { useDispatch } from 'react-redux'
import { resetCredentials, setCredentials } from 'store/slices/auth'
import { AUTH_SLICE_NAME } from 'constants/sliceNameConstants'

const useCredentials = () => {
    const dispatch = useDispatch()

    const handleSetCredentials = (user) => {
        dispatch(setCredentials(user))
    }

    const handleSetCredentialsAll = async (user) => {
        await setLocalDataHelper(AUTH_SLICE_NAME, user);
        dispatch(setCredentials(user))
    }

    const handleResetCredentialsAll = async () => {
        await resetLocalDataHelper();
        dispatch(resetCredentials())
    }

    const handleGetLocalCredentials = async () => {
        return await getLocalDataHelper(AUTH_SLICE_NAME);
    }

    return {
        setCredentials: handleSetCredentials,
        setCredentialsAll: handleSetCredentialsAll,
        resetCredentialsAll: handleResetCredentialsAll,
        getLocalCredentials: handleGetLocalCredentials,
    }
}

export default useCredentials
