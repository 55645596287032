import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { baseSplitApi } from './services/_base'
import applicationSlice from './slices/application'
import authSlice from './slices/auth'
import indexPagesConfig from './slices/indexPagesConfig'
import deviceSlice from './slices/device'
import toggleSlice from './slices/toggle'
import oraatqsSlice from './slices/oneRequestAtATimeQueries'

export const store = configureStore({
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseSplitApi.middleware),
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [baseSplitApi.reducerPath]: baseSplitApi.reducer,
    [applicationSlice.name]: applicationSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [indexPagesConfig.name]: indexPagesConfig.reducer,
    [deviceSlice.name]: deviceSlice.reducer,
    [toggleSlice.name]: toggleSlice.reducer,
    [oraatqsSlice.name]: oraatqsSlice.reducer,
  },
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
