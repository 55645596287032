import styled from "styled-components";
import { Container as C } from 'components/ContentList/styled'

export const Container = styled(C)`
margin-top: 0;
margin-bottom: 0;
`

export const Title = styled(Container)`
color: var(--ion-color-text-primary);
font-weight: var(--ion-font-weight-medium);
border-bottom: 2px solid black;
margin-bottom: 10px;
text-align: left;
`

export const HomeWrapperContent = styled.div`
padding-top: 25px;
height: 100%;
flex-grow: 1;
display: flex;
flex-direction: column;
`

export const ImageWrapper = styled.div`
display: flex;
align-items: center;
flex: 1;
`

export const ImageContainer = styled.div`
`
