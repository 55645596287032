import { UserSignInForm } from "forms/usersForm";
import usersPathHelper from "helper/pathHelper/users";
import { useSignInUserMutation } from "store/services/users";
import { useHistory, useLocation } from "react-router-dom";
import useRedirectIfAlreadyAuth from "hooks/useRedirectIfAlreadyAuth";
import appPathHelper from "helper/pathHelper/app";
import PageLayout from "layout/PageLayout";
import PrimaryButton from "components/PrimaryButton";
import {
  ContaninerWithIcon,
  ForgotPasswordContainer,
  InscriptionSide,
  RegistrationContainer,
  SignInContainer,
} from "./styled.js";
import PrimaryLink from "components/PrimaryLink";
import PageContentWithAppLogo from "layout/PageContentWithAppLogo";
// import PageFooter from 'layout/PageFooter';
import inscriptionImage from "assets/images/Inscription_icon.png";
import useSubmitButtonRef from "hooks/useSubmitButtonRef";
import RequestErrorMessages from "components/RequestErrorMessages";
import {
  ContainerWithLabels,
  DetailContainer,
  DetailLabel,
  NSDetailLabelTwo,
  NSTitleLabelDesktop,
  NSTitleLabelMobile,
} from "components/BaseLabels";
import PageContentSmaller from "layout/PageContentSmaller/index.js";
import { IonImg, isPlatform } from "@ionic/react";
import useCredentials from "hooks/useCredentials.js";
import usePerPlatformTranslation from "hooks/usePerPlatformTranslation.js";

const SignInPage = () => {
  useRedirectIfAlreadyAuth();

  const { t } = usePerPlatformTranslation("user");
  const { rootPath } = appPathHelper();
  const { createUserSessionsPath, forgotUserPasswordPath } = usersPathHelper();
  const [createUserSession, { isLoading, error }] = useSignInUserMutation();
  const [submitButtonRef, handleClick] = useSubmitButtonRef();
  const history = useHistory();
  const location = useLocation();
  const { setCredentialsAll } = useCredentials();

  const handleSubmit = async (data) => {
    try {
      const response = await createUserSession({
        url: createUserSessionsPath,
        body: data,
        headers: {
          Accept: "application/json",
        },
      }).unwrap();
      const authResponse = response.data;
      await setCredentialsAll(authResponse);
      const redirectPath = location.state?.from?.pathname ?? rootPath;
      history.replace(redirectPath);
    } catch (error) {
      console.error("SignInPage#handleSubmit", error);
    }
  };

  let RenderSignInContetnt;
  const { newUserRegistrationsPath } = usersPathHelper();
  if (!isPlatform("capacitor")) {
    RenderSignInContetnt = (
      <RegistrationContainer>
        <PrimaryLink
          to={{ pathname: newUserRegistrationsPath }}
          target="_blank"
        >
          <ContaninerWithIcon>
            <IonImg src={inscriptionImage} style={{ height: "56px" }} />
            <InscriptionSide>{t("sign_in_page.registration")}</InscriptionSide>
          </ContaninerWithIcon>
        </PrimaryLink>
      </RegistrationContainer>
    );
  }

  return (
    <PageLayout>
      <PageContentSmaller withAreaFading={false}>
        <PageContentWithAppLogo
          body={
            <>
              <RequestErrorMessages error={error} />

              <div className="text-margin">
                <ContainerWithLabels>
                  <NSTitleLabelDesktop>
                    {t("sign_in_page.welcome")}
                  </NSTitleLabelDesktop>

                  <NSTitleLabelMobile>
                    {t("sign_in_page.welcome")}
                  </NSTitleLabelMobile>
                </ContainerWithLabels>

                <DetailContainer>
                  <NSDetailLabelTwo>
                    {t("sign_in_page.enter_data_to_login")}
                  </NSDetailLabelTwo>
                </DetailContainer>
              </div>

              <UserSignInForm
                submitButtonRef={submitButtonRef}
                onSubmit={handleSubmit}
              />

              <ForgotPasswordContainer className="first-form-control ion-text-end">
                <PrimaryLink to={forgotUserPasswordPath}>
                  <DetailLabel>{t("sign_in_page.forgot_password")}</DetailLabel>
                </PrimaryLink>
              </ForgotPasswordContainer>

              {RenderSignInContetnt}
              <SignInContainer>
                <PrimaryButton onClick={handleClick} disabled={isLoading}>
                  {t("sign_in_page.connect")}
                </PrimaryButton>
              </SignInContainer>
            </>
          }
        />
      </PageContentSmaller>

      {/* <PageFooter>
                
            </PageFooter> */}
    </PageLayout>
  );
};

export default SignInPage;
