import { formatNumberToCurrency } from 'helper/numberHelper'

import { Container } from './styled'


const TransactionAmount = ({ status, amount, bold }) => {
    return (
        <Container status={status} bold={bold} >
            {formatNumberToCurrency(amount)}
        </Container>
    )
}

export default TransactionAmount
