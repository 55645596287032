import styled from "styled-components";
import TransactionLabel from "components/transactions/BaseLabel";

export const StatusIconContainer = styled.div`
margin-top: 50px;
`

export const HiddenTransactionLabel = styled(TransactionLabel)`
visibility: hidden;
`
