import HighlightOff from '@mui/icons-material/HighlightOff'
import { useTranslation } from 'react-i18next';
import useAlertModalLike from './useAlertModalLike'

const useErrorModal = ({ errors }) => {
    const { t } = useTranslation('application');
    const [present, dismiss] = useAlertModalLike({
        almostFullWidthEnabled: true,
        children: <div className='ion-text-center'>
            <div>
                <HighlightOff style={{ fontSize: '40px', color: "var(--ion-color-danger)" }} />
            </div>
            {
                errors ? <div className='ion-margin-top'>
                    {
                        errors instanceof Array ?
                            errors.map((message, index) => (
                                <div key={index}>
                                    {message}
                                </div>
                            )) : <div>
                                {t('something_went_wrong')}
                            </div>
                    }
                </div> : null
            }
        </div>
    })

    return [present, dismiss]
}

export default useErrorModal