import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { setPrevLocation } from 'store/slices/application';
import { useDispatch } from 'react-redux';

const SetupPrevLocation = () => {
    const prevRef = useRef()
    const dispatch = useDispatch()
    const location = useLocation()

    const handleSetPrevPathname = useCallback((prevPathname) => {
        dispatch(setPrevLocation(prevPathname))
    }, [dispatch])

    useEffect(() => {
        if (prevRef.current) {
            handleSetPrevPathname(prevRef.current)
        }
        prevRef.current = location
    }, [location, handleSetPrevPathname])

    return null
}

export default SetupPrevLocation
