import { useTranslation } from 'react-i18next'
import QueryButton from 'components/QueryButton'
import Sort from '@mui/icons-material/Sort'

const SortButton = (props) => {
    const { t } = useTranslation('application')

    return (
        <QueryButton
            {...props}
            renderIcon={() => <Sort style={{ fontSize: '32px', color: 'var(--ion-color-text-primary)' }} />}
            title={t('sort')}
        />
    )
}

export default SortButton
