import { styledHideIfDesktop, styledHideIfMobile } from "helper/applicationHelper";
import styled from "styled-components";

// const flexBasisByPageNameObject = {
//     clientPayment: '30%',
//     afterSetup: '45%',
//     setup: '20%',
// }

// const getFlexbasisFromPageName = ({ pageName }) => flexBasisByPageNameObject[pageName] ?? '50%'

export const Container = styled.div`
height: 100%;
`

export const AppLogoContainer = styled.div`
${styledHideIfMobile}
`

export const AppLogoContainerMobile = styled.div`
${styledHideIfDesktop}
`

export const HeaderContainer = styled.div`
padding-top: min(calc(7.05vh + 2.35vw), 84px);
align-items: center;
`

export const BodyContainer = styled.div`
`
