import { useHistory } from 'react-router-dom';
import PrimaryLink from 'components/PrimaryLink';

const PrimaryBackLink = ({ children }) => {
    const history = useHistory();

    const hadleBackClick = (e) => {
        e.preventDefault();
        history.goBack()
    }
    return (
        <PrimaryLink to="#" onClick={hadleBackClick}>
            {children}
        </PrimaryLink>
    )
};

export default PrimaryBackLink;
