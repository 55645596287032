import { withFormik } from 'formik'
import Form from './UserResetPasswordForm'

import * as Yup from 'yup'
import { BaseFormikConfig } from 'forms/_baseForm'
import { password } from './validation';

const UserFormValidationSchema = Yup
   .object()
   .shape({
      user: Yup.object().shape({
         password,
         password_confirmation: password
      })
   })

const UserFormikConfig = {
   ...BaseFormikConfig,
   mapPropsToValues: () => ({
      user: {
         password: '',
         password_confirmation: ''
      }
   }),
   validationSchema: UserFormValidationSchema,
}

export default withFormik(UserFormikConfig)(Form)
