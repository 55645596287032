import { IonToggle } from "@ionic/react"
import styled from "styled-components";

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`
//justify-content: space-between;
export const LabelContainer = styled.div`
text-align: left;

font-size: 14px;

color: var(--ion-color-text-primary);

flex-basis: 60%;
`
export const ToggleContainer = styled.div``

const ToggleSize = '21px;'

export const Toggle = styled(IonToggle)`
--background-checked: var(--ion-color-primary-greeny-blue);
width: 45px;

--handle-height: ${ToggleSize}
--handle-width: ${ToggleSize}

--handle-spacing: 5px;
`
