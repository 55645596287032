import TransactionAmount from 'components/transactions/AmountLabel'
import TransactionPaymentLink from 'components/transactions/PaymentLinkLabel'
import TransactionPaymentLinkQr from 'components/transactions/PaymentLinkQr'
import transactionsPathHelper from 'helper/pathHelper/transactions'
import appPathHelper from 'helper/pathHelper/app'
import PageContent from 'layout/PageContent'
import PageHeader from 'layout/PageHeader'
import {
    StatusIconContainer,
} from './styled'
import Cancel from '@mui/icons-material/CancelOutlined'
import TransactionLabel from 'components/transactions/BaseLabel'
import { useTranslation } from 'react-i18next'
import PrimaryButton from 'components/PrimaryButton'
import clientPaymentPathHelper from 'helper/pathHelper/clientPayment'
import TransactionPageFooter from 'layout/TransactionPageFooter'
import { TextButton } from '../Pending/styled'
import useCustomIonAlert from 'hooks/useCustomIonAlert'
import { useReCreateMutation } from 'store/services/transactions'
import { useHistory } from 'react-router'

const Failed = ({ data }) => {
    const { amount, status, uuid, id } = data.data
    const { reCreateTransactionsPath, afterCreateTransactionsPath } = transactionsPathHelper(id)
    const { rootPath } = appPathHelper()
    const { showClientAcceptPaymentUri } = clientPaymentPathHelper(uuid)
    const { t } = useTranslation(['transaction', 'application'])

    const history = useHistory()
    const [alertPresent] = useCustomIonAlert()
    const [reCreate] = useReCreateMutation()
    const handleReCreate = async () => {
        try {
            await reCreate({
                url: reCreateTransactionsPath, id
            }).unwrap()

            history.replace(afterCreateTransactionsPath)
        } catch (error) {
            console.error('TransactionShowPage#handleReCreate', error)
        }
    }

    return (
        <>
            <PageHeader
                withBackButton
            />

            <PageContent>
                <TransactionAmount status={status} amount={amount} bold />

                <TransactionPaymentLink status={status} link={showClientAcceptPaymentUri} />

                <TransactionPaymentLinkQr status={status} link={showClientAcceptPaymentUri} />

                <div className='ion-margin-top'>
                    <TransactionLabel bold>
                        {t('status_text.failed')}
                    </TransactionLabel>
                    <TransactionLabel>
                        {t('show_page.failed_message')}
                    </TransactionLabel>
                </div>

                <StatusIconContainer>
                    <Cancel style={{ fontSize: 32 }} />
                </StatusIconContainer>
            </PageContent>

            <TransactionPageFooter>
                <div className='ion-margin-bottom'>

                    <TextButton
                        onClick={() => {
                            alertPresent({
                                header: t('show_page.recreate'),
                                message: t('show_page.recreate_confirmation'),
                                buttons: [t('cancel'), { text: t('submit'), handler: handleReCreate }],
                            })
                        }}
                    >
                        {t('show_page.recreate')}
                    </TextButton>
                </div>

                <PrimaryButton
                    isInvert
                    routerLink={rootPath}
                >
                    {t('close')}
                </PrimaryButton>
            </TransactionPageFooter>
        </>
    )
}

export default Failed
