import PropTypes from 'prop-types'
import useCurrentToggle from '../useCurrentToggle'
import { ModalContainer } from './styled.js'

export const useCurrentModal = (stateName) => {

    const {
        value,
        setFalse,
        setTrue,
        toggle,
    } = useCurrentToggle(stateName)

    return {
        isOpen: value,
        show: setTrue,
        hide: setFalse,
        toggle,
    }
}

const Modal = ({
    children,
    modalName,
    className,
    overlayClassName,
    ...rest
}) => {
    const {
        isOpen,
        hide,
    } = useCurrentModal(modalName)
    return (
        <ModalContainer
            isOpen={isOpen}
            onDidDismiss={hide}
            {...rest}
        >
            {children}
        </ModalContainer>
    )
}

Modal.propTypes = {
    modalName: PropTypes.string.isRequired
}

export const useModal = (modalName) => {
    if (!modalName) {
        throw new Error('To use useModal, please provide a modalName argument')
    }
    const modalState = useCurrentModal(modalName)
    return {
        Modal,
        ...modalState,
    };
};
