import { FieldArray, Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import BaseField from 'components/fields/BaseField';
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import { Fragment, useRef } from 'react';
import DateField from 'components/inputs/DateField';
import { decorateEmailString } from 'helper/stringHelper';
import { UserClientPhoneInput } from 'components/userClients/PhoneInput/styled';
import IntlPhoneInput from 'components/inputs/IntlPhoneField';

const Index = ({ submitButtonRef, values }) => {

    const { t } = useTranslation(['user', 'individual', 'contact_datum'])
    const { setFieldValue } = useFormikContext();

    const intlRef = useRef() // Just create it to make the IntlPhoneField work

    return (
        <Form className='first-form-control first-form-control--second-input-margin'>
            <BaseField
                name='user_setting.individual_attributes.first_name'
                placeholder={t('first_name')}
            />

            <BaseField
                name='user_setting.individual_attributes.last_name'
                placeholder={t('last_name')}
            />

            <BaseField
                disabled
                name='user_setting.email'
                placeholder={t('email')}
                onChange={event => setFieldValue('user_setting.email', decorateEmailString(event.target.value))}
            />

            <IntlPhoneInput
                Component={UserClientPhoneInput}
                name='user_setting.individual_attributes.phone'
                countryName='user_setting.individual_attributes.phone_iso2'
                placeholder={t('phone')}
                intlRef={intlRef}
                variant="standard"
            />

            <FieldArray name='user_setting.contact_data_attributes'>
                {
                    ({ name }) => {
                        return values.user_setting.contact_data_attributes.map((_, index) => {
                            return (
                                <Fragment key={index}>
                                    <BaseField
                                        name={`${name}.${index}.address`}
                                        placeholder={t('address')}
                                    />

                                    <BaseField
                                        name={`${name}.${index}.postal_code`}
                                        placeholder={t('zip_code')}
                                    />

                                    <BaseField
                                        name={`${name}.${index}.city`}
                                        placeholder={t('city')}
                                    />
                                </Fragment>
                            )
                        })
                    }
                }
            </FieldArray>

            <BaseField
                name='user_setting.individual_attributes.type_of_profession'
                placeholder={t('type_of_profession')}
            />

            <DateField
                name='user_setting.individual_attributes.birth_date'
                placeholder={t('birth_date')}
            />

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
