import { Filesystem, Directory } from '@capacitor/filesystem';
import useDevice from "hooks/useDevice"
import { useAuth } from "hooks/useAuth"
import { useState } from "react"
import { isPlatform } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import useLoadingAndSuccessToast from 'hooks/useLoadingAndSuccessToast';

export const useFetch = () => {
    const { token } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const { deviceUUID } = useDevice()

    const [error, setError] = useState(null)

    const handleSetError = (error) => {
        setError({ data: error })
    }

    const trigger = (url, defaultOptions) => {
        const options = defaultOptions ?? {}

        const headers = {
            'Authorization': `Bearer ${token}`,
        }
        if (deviceUUID) {
            headers['JWT_AUD'] = deviceUUID
        }

        return fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers,
            ...options,
        });
    }

    return [
        trigger,
        {
            setIsLoading,
            setError: handleSetError,
            isLoading,
            error
        }
    ]
}

export const useFetchPDF = () => {
    const { t } = useTranslation('application')
    const [showLoading, showSuccess, dismiss] = useLoadingAndSuccessToast()
    const [trigger, helpers] = useFetch()
    const {
        setIsLoading,
        setError,
    } = helpers

    const handleShowSuccess = (message) => {
        showSuccess(message || t('download_successfully'))
    }

    const fetchPDFTrigger = async (url,
        {
            fetchOptions,
            fileOptions,
            toastOptions,
        }) => {
        showLoading(toastOptions?.loadingText)
        setIsLoading(true)
        const response = await trigger(url, fetchOptions)
        if (response.ok) {
            const myBlob = await response.blob()
            const { filename } = fileOptions
            if (isPlatform('capacitor')) {
                const reader = new FileReader();

                reader.readAsDataURL(myBlob);

                reader.addEventListener("load", async () => {
                    await Filesystem.writeFile({
                        path: `${filename}.pdf`,
                        data: reader.result,
                        directory: Directory.Documents,
                    });
                    handleShowSuccess(toastOptions?.successText)
                }, false);

            } else {
                const fileURL = URL.createObjectURL(myBlob)
                const link = document.createElement('a')
                link.href = fileURL
                link.download = filename
                document.body.append(link)
                link.click()
                link.remove()
                URL.revokeObjectURL(fileURL)
                handleShowSuccess(toastOptions?.successText)
            }
        } else {
            const error = await response.json();
            setError(error)
        }
        setIsLoading(false)
    }

    return [fetchPDFTrigger, helpers]
}
