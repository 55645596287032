import { isPlatform } from "@ionic/core"
import { styledHideIfDesktop, styledHideIfMobile } from "helper/applicationHelper"
import styled from "styled-components"

const getPreLine = () => {
    if (!isPlatform('mobile')) {
        return `white-space: pre-line;`
    }
}

export const getBaseTextStyle = ({ color }) => (`
font-size: 16px;
color: ${color ?? 'var(--ion-color-text-primary)'};
`)

export const DetailLabel = styled.div`
${getBaseTextStyle}
`

export const TitleLabel = styled(DetailLabel)`
font-weight: var(--ion-font-weight-medium);
font-size: 14px;
`

export const HeaderLabel = styled(TitleLabel)`
font-weight: var(--ion-font-weight-bold);
font-size: 14px;
`

export const NSDetailLabel = styled.div`
${getBaseTextStyle}
font-weight: var(--ion-font-weight-medium);
`

export const NSDetailLabelTwo = styled.div`
line-height: 26px;
font-size: 18px;
${getPreLine}
`

export const DetailContainer = styled.div`
margin-top: min(calc(2.24vh + 2.24vw), 40px);
`

export const DetailContainerTwo = styled.div`
margin-top: min(calc(6.72vh + 2.24vw), 80px);
`

export const DetailContainerThree = styled(DetailContainer)`
line-height: 32px;
`

// margin-top: 40px;

// export const NSDetailLabelThree = styled(NSDetailLabelTwo)`
// margin-top: 60px;
// `

export const NSTitleLabel = styled(NSDetailLabel)`
font-weight: var(--ion-font-weight-bold);
`

export const NSTitleLabelDesktop = styled(NSTitleLabel)`
${styledHideIfMobile}
font-size: 36px;
`

export const NSTitleLabelMobile = styled(NSDetailLabel)`
${styledHideIfDesktop}
font-size: 28px;
`

export const ContainerWithLabels = styled.div`
margin-top: min(calc(3.36vh + 3.36vw), 60px) !important;
`