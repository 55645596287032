import { ContainerWithLabels, NSTitleLabelDesktop, NSTitleLabelMobile } from 'components/BaseLabels'
import SubscriptionSelectField from 'components/subscriptions/SubscriptionSelectField'
import { useTranslation } from 'react-i18next'
import { SubscriptionContainer } from '../styled';

const Index = () => {
    const { t } = useTranslation('user');

    return (
        <>
            <ContainerWithLabels>
                <NSTitleLabelDesktop>
                    {t('setup_sign_up_page.choose_your_plan')}
                </NSTitleLabelDesktop>

                <NSTitleLabelMobile>
                    {t('setup_sign_up_page.choose_your_plan')}
                </NSTitleLabelMobile>

                <SubscriptionContainer>
                    <SubscriptionSelectField
                        name="user.user_subscription_attributes.plan_id"
                        isCreation
                    />
                </SubscriptionContainer>
            </ContainerWithLabels>
        </>

    )
}

export default Index
