// https://github.com/react-ga/react-ga

import { useEffect } from 'react';
import ReactGA from 'react-ga';

const InitializeGoogleAnalyticsV3 = () => {
    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC_V3_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return null
}

export default InitializeGoogleAnalyticsV3
