import styled from "styled-components";
import { styled as mStyled } from '@mui/material/styles';
import BaseField from "components/fields/BaseField";
import { TextsmsRounded, WhatsApp, Email } from '@mui/icons-material';

export const SendTypeWrapper = styled.div`
margin-top: 30px;
display: flex;
flex-wrap: wrap;
align-content: center;
justify-content: center;
`

export const SendTypeContainer = styled.div`
border: 1px solid ${({ active }) => active ? 'var(--ion-color-primary-greeny-blue);' : 'var(--ion-color-text-primary);'};
border-radius: 50%;
width: 62px;
height: 62px;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
margin-left: 15px;
margin-right: 15px;
${({ active }) => active && 'background: var(--ion-color-primary-greeny-blue);'}
`

const iconsize = '37px !important'
const getSendTypeColor = ({ selected }) => {
    return selected ? 'color: white !important;' : 'color: var(--ion-color-text-primary)'
}

export const SendTypeSmsIcon = styled(TextsmsRounded)`
font-size: ${iconsize};
${getSendTypeColor}
`

export const SendTypeEmailIcon = styled(Email)`
font-size: ${iconsize};
${getSendTypeColor}
`

export const SendTypeWhatasppIcon = styled(WhatsApp)`
font-size: ${iconsize};
${getSendTypeColor}
`
export const SendTypeFieldContainer = styled.div`
margin-top: 15px;
margin-left: 60px;
margin-right: 60px;
display: flex;
flex-wrap: wrap;
align-content: center;
justify-content: center;
gap: 30px;
`

export const SendTypeEmailField = mStyled(BaseField)({
    "& .MuiInputBase-root": {
        "::after": {
            borderBottom: '2px solid var(--ion-color-primary-greeny-blue)'
        },
        "& input": {
            textAlign: 'center',
            fontSize: 20,
            color: 'var(--ion-color-text-primary)'
        }
    },
    "& .MuiFormHelperText-root": {
        textAlign: 'center'
    }
})

export const MultiTransactionContainer = styled.div`
margin-top: 30px;
margin-bottom: 30px;
`

export const MultiTransactionTitleContainer = styled.div`
margin-top: 15px;
font-weight: bold;
color: var(--ion-color-text-primary);
font-size: 15px;
`

export const MultiTransactionDetailContainer = styled.div`
color: var(--ion-color-text-primary);
font-size: 14px;
`

export const MultiTransactionBlankSpace = styled.div`
height: 15px
`

export const TextSpan = styled.span`
font-weight: bold;
font-size: 12px;
`
