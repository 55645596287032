import {
    IonApp,
    setupIonicReact
} from '@ionic/react';

import AppRoute from './AppRoute'

import { store } from './store'
import { Provider } from 'react-redux'
import RollbarProvider from './RollbarProvider';
import './i18n'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/style.scss';
import './theme/variables.scss';

import 'react-circular-progressbar/dist/styles.css';

/* swiper.js */
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

import InitializedLayout from 'InitializedLayout';
import MUIProvider from 'MUIProvider';
import loadAuthFromStorageAndRefresh from './loadAuthFromStorageAndRefresh'

loadAuthFromStorageAndRefresh()

setupIonicReact({ mode: 'ios' });

const App = () => (
    <RollbarProvider>
        <Provider store={store}>
            <MUIProvider>
                <IonApp>
                    <InitializedLayout>
                        <AppRoute />
                    </InitializedLayout>
                </IonApp>
            </MUIProvider>
        </Provider>
    </RollbarProvider>
);

export default App;
