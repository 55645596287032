import styled from "styled-components";

export const ContentWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

export const ContentTextWrapper = styled.div`
margin-left: 54px;
margin-right: 54px;
`

export const ContentTitle = styled.div`
font-size: 16px;
font-weight: bold;
margin-bottom: 40px;
`

export const Detail = styled.div`
font-size: 12px;
margin-bottom: 16px;
`

export const TextBold = styled.span`
font-weight: bold;
`
