import clientPaymentPathHelper from "helper/pathHelper/clientPayment"
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import useClientAcceptPaymentLink from "./useClientAcceptPaymentLink"

/*
Clients should not be able to skip the payment steps.
Redirect to the first step if we should.
*/
const useRedirectToInitPayment = () => {
    const uuid = getURLSearchParamsHelper('uuid')
    const { timestamp } = useClientAcceptPaymentLink()

    const {
        showClientAcceptPaymentPath,
    } = clientPaymentPathHelper(uuid)

    const history = useHistory()

    useEffect(() => {
        // The process has not started yet. Redirect to the first step.
        if (!timestamp) {
            history.replace(showClientAcceptPaymentPath)
        }
    }, [
        history,
        showClientAcceptPaymentPath,
        timestamp,
    ])
}

export default useRedirectToInitPayment
