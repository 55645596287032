import { TitleLabel } from 'components/BaseLabels'
import { IonLabel } from "@ionic/react";
import styled from "styled-components";

export const LeftContainer = styled(IonLabel)`
margin-inline: 0 10px;
`

export const RightContainer = styled(IonLabel)`
margin-inline-start: 10px;
margin-inline-end: 0;
`

export const NameContainer = styled(TitleLabel)`
display: flex;
align-items: center;
gap: 8px;
`
