import * as Yup from 'yup'

export const min_password_length = 6
export const min_password_length_message = "Le mot de passe doit être au moins de ${min} caractères."
export const max_password_length = "Le mot de passe doit comporter au maximum ${max} caractères."
export const max_password_length_message = 72

export const base_email = Yup
    .string()
    .email('L’e-mail doit être un e-mail valide.')
export const base_phone = Yup
    .string()
    .email('Le téléphone doit être renseigné.')

export const email = base_email.required('L’e-mail doit être rempli.')
export const phone = base_phone.required('Le téléphone doit être renseigné.')

export const base_password = Yup
    .string()
    .min(min_password_length, min_password_length_message)
    .max(max_password_length_message, max_password_length_message)

export const password = base_password.required('Le mot de passe doit être rempli.')

export const terms_and_conditions_accepted = Yup.boolean()
    .oneOf([true], "Veuillez accepter les conditions d'utilisation.")
