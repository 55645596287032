import { Form } from "formik";
import { useTranslation } from "react-i18next";
import {
  AcceptTermsLabel,
  CGUUnderline,
  ContainerCode,
  EnterTheCode,
  PhoneContainerTwo,
  ButtonSendCode,
  PrimaryButton,
} from "./styled";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import VerificationInput from "react-verification-input";
import { useEffect, useState } from "react";

const Index = ({ codeErrors, sendCode, checkCode, isLoading }) => {
  const { t: userT } = useTranslation("user");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isCodeError, setIsCodeError] = useState(false);

    useEffect(() => {
        setIsCodeError(true);
        setCode("");
        setTimeout(() => setIsCodeError(false), 2000);
    }, [codeErrors]);

  useEffect(() => {
    if (code.length === 4) {
      checkCode({ code: code });
    }
  }, [code]);

  return (
    <Form className="first-form-control">
      <PhoneContainerTwo>
        <PhoneInput
          // country="FR"
          placeholder="+33 6 12 34 56 78"
          onChange={setPhone}
          defaultCountry="FR"
        />
      </PhoneContainerTwo>

      <ContainerCode>
          <ButtonSendCode>
            {!isLoading ?
              (
                  <PrimaryButton
                    onClick={() => sendCode({ phone: phone })}
                  >
                    {userT("sign_up_page.sendCode")}
                  </PrimaryButton>
              ) :
              (
                  userT("sign_up_page.code_sent")
              )}
          </ButtonSendCode>
      </ContainerCode>

      <AcceptTermsLabel>
        {userT("sign_up_page.acceptationCGU")}
      </AcceptTermsLabel>
      <CGUUnderline>
        <a href={"#"}>{userT("sign_up_page.CGU")}</a>
      </CGUUnderline>
      <EnterTheCode>{userT("sign_up_page.code")}</EnterTheCode>

      <ContainerCode>
        {!isCodeError ?
          (
            <VerificationInput
              length={4}
              validChars={"0-9"}
              classNames={{
                inputCode: "inputCode",
              }}
              value={code}
              onChange={(value) => setCode(value) }
            />
          )
          :
          (
            userT("sign_up_page.code_invalid")
          )
        }
      </ContainerCode>
    </Form>
  );
};

export default Index;
