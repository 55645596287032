import PropTypes from 'prop-types'
import { Container } from './styled.js'

const ContentListItem = ({
    children,
    ...rest
}) => {

    return (
        <Container
            {...rest}
            detail={false}
            lines="none"
        >
            {children}
        </Container>
    );
};

ContentListItem.propTypes = {
    children: PropTypes.node,
    withBorder: PropTypes.bool
}
export default ContentListItem;
