
import { IonButton } from '@ionic/react';
import styled from 'styled-components';

export const Container = styled(IonButton)`
align-self: center;
--border-radius: var(--ion-border-radius-primary);

--color: ${({ isInvert }) => isInvert ? 'var(--ion-color-primary-greeny-blue);' : 'var(--ion-color-text-secondary);'}
--background: ${({ isInvert }) => isInvert ? 'var(--ion-background-color);' : 'var(--ion-color-primary-greeny-blue);'}
--background-activated: ${({ isInvert }) => isInvert ? 'var(--ion-background-white-hover);' : 'var(--ion-color-secondary-greeny-blue);'}
--background-hover: ${({ isInvert }) => isInvert ? 'var(--ion-background-white-hover);' : 'var(--ion-color-secondary-greeny-blue);'}

--background-hover-opacity: 0.5;

width: 232px;
height: 48px;

${({ toUpperText }) => toUpperText && 'text-transform: uppercase;'}

`
