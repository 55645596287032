import PropTypes from 'prop-types'
import BaseField from '../BaseField';
import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { forwardRef, useEffect, useState } from 'react';
import fetchJsonp from 'fetch-jsonp'
import { IMaskInput } from 'react-imask';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { InputAdornment } from '@mui/material';
import useAfterFirstMountedEffect from 'hooks/useAfterFirstMountedEffect';
import { isValidIBAN } from 'ibantools'
import usePreviousValue from 'hooks/usePreviousValue';
import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';

// Format based on: https://wise.com/gb/iban/france
export const IBANIMaskOpions = {
    mask: '{FR}00 0000 0000 00** **** **** *00',
    prepare: function (str) {
        return str.toUpperCase();
    },
    lazy: false // If added this, the Autofill bug from google keyboard will be Prevented : https://dynames.plan.io/issues/1564
}

const IBANTextMask = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            {...IBANIMaskOpions}
            unmask={true}
            inputRef={ref}
            onAccept={(value) => {
                onChange(value)
            }}
        />
    );
});

const IbanField = ({
    name,
    autoHideKeyboard,
    ...rest
}) => {
    const {
        setFieldValue,
        errors,
        values,
        isSubmitting,
    } = useFormikContext();

    const { t } = useTranslation('bank_account');

    const [isSubmitted, setIsSubmitted] = useState(false)
    const prevIsSubmitting = usePreviousValue(isSubmitting)

    const [errorData, setErrorData] = useState({
        isError: false,
        helperText: undefined
    })

    const value = getIn(values, name)
    const error = getIn(errors, name)

    const emptyFieldName = name.replace(/iban$/, '');
    const bankCityFieldName = `${emptyFieldName}bank_city`
    const bankCountryFieldName = `${emptyFieldName}bank_country`
    const bankNameFieldName = `${emptyFieldName}bank_name`

    useEffect(() => {
        if (!autoHideKeyboard) {
            return
        }
        if (isPlatform('capacitor')) {
            if (!error) {
                Keyboard.hide()
            }
        }
    }, [error])

    useEffect(() => {
        // Due to isSubmitting value would be 'true' for a short moment(validating)
        // and go back to be 'false' after that(validated).
        // so we have to use the previous value of it.
        if (prevIsSubmitting) {
            setIsSubmitted(true)
        }
    }, [isSubmitting])

    useEffect(() => {
        const baseCondition = (isSubmitted || value.length === 27)
        setErrorData({
            isError: baseCondition && Boolean(error),
            helperText: baseCondition && error,
        })
    }, [
        isSubmitted,
        value,
        error,
    ])

    useAfterFirstMountedEffect(() => {
        let active = true
        const setBankInfoFromIban = () => {
            if (isValidIBAN(value)) {
                fetchJsonp(`https://api.ibanapi.com/v1/validate/${value}?api_key=${process.env.REACT_APP_IBANAPI_API_KEY}`)
                    .then(function (response) {
                        return response.json()
                    }).then(function (json) {
                        if (!active) return
                        const country_name = getIn(json, 'data.country_name', '')
                        const bank_name = getIn(json, 'data.bank.bank_name', '')
                        const city = getIn(json, 'data.bank.city', '')

                        setFieldValue(bankCountryFieldName, country_name)
                        setFieldValue(bankCityFieldName, city)
                        setFieldValue(bankNameFieldName, bank_name)
                    }).catch(function (error) {
                        console.error('api.ibanapi.com errors: ', error);
                    })
            }
        }

        setBankInfoFromIban()

        return () => {
            active = false
        }
    }, [value])

    const handleChange = (newValue) => {
        setFieldValue(name, newValue)
    }

    return <BaseField
        name={name}
        error={errorData.isError}
        helperText={errorData.helperText}
        placeholder={t('iban')}
        value={value}
        onChange={handleChange}
        InputProps={{
            inputComponent: IBANTextMask,
            endAdornment: value && !error && <InputAdornment position="end">
                <CheckCircle style={{ color: 'var(--ion-color-primary-greeny-blue)', fontSize: '16px' }} />
            </InputAdornment>
        }}
        {...rest}
    />;

};

IbanField.propTypes = {
    autoHideKeyboard: PropTypes.bool
}

export default IbanField;

/*
let resultFromAPI = {
    "result": 200,
    "message": "Valid IBAN Number",
    "validations": [
        { "result": 200, "message": "Valid IBAN length" },
        { "result": 200, "message": "Valid IBAN Checksum" },
        { "result": 200, "message": "Valid IBAN Structure" },
        { "result": 200, "message": "Valid Account Number checksum" }
    ],
    "expremental": 0,
    "data": {
        "country_code": "FR",
        "iso_alpha3": "FRA",
        "country_name": "France",
        "currency_code": "EUR",
        "sepa_member": "Yes",
        "sepa": {
            "sepa_credit_transfer": "Yes",
            "sepa_direct_debit": "Yes",
            "sepa_sdd_core": "Yes",
            "sepa_b2b": "Yes",
            "sepa_card_clearing": "No"
        },
        "bban": "30003038780005098501369",
        "bank_account": "00050985013",
        "bank": {
            "bank_name": "SOCIETE GENERALE",
            "phone": "",
            "address": "33 R TREBOIS",
            "bic": "SOGEFRPP",
            "city": "LEVALLOIS PERRET",
            "state": "",
            "zip": "92300"
        }
    }
}
*/
