
import BaseField from 'components/fields/BaseField'
import { FieldArray, useFormikContext } from 'formik'
import { Fragment } from 'react'
import PromoCodeTextField from './styled'
import { useTranslation } from 'react-i18next'

const Index = () => {

    const { t } = useTranslation('user_promotion_code')
    const {
        values
    } = useFormikContext()

    return (
        <FieldArray name='user.user_promotion_codes_attributes'>
            {
                ({ name }) => {
                    return values.user.user_promotion_codes_attributes.map((_, index) => {
                        return (
                            <Fragment key={index}>
                                <BaseField
                                    Component={PromoCodeTextField}
                                    name={`${name}.${index}.raw_promotion_code`}
                                    placeholder={t('placeholder')}
                                />
                            </Fragment>
                        )
                    })
                }
            }
        </FieldArray>
    )
}

export default Index
