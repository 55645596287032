export const isAnyStateIsLoading = (states) => states.some((state) => state.isLoading)

export const findAnyErrorState = (states) => {
    let result
    for (const state of states) {
        if (state.error) {
            result = state.error
            break;
        }
    }

    return result
}
