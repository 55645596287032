import { PhoneNumberUtil } from 'google-libphonenumber';
import { getCountryCallingCode } from 'libphonenumber-js'

const phoneUtil = PhoneNumberUtil.getInstance()

export function nationPhoneFormat(phone, iso2) {
    try {
        const number = phoneUtil.parseAndKeepRawInput(phone, iso2);
        const { values_: { 1: dialCode, 2: phoneNumber } } = number

        const firstNumber = phoneNumber.toString().substring(0, 1)
        const remainNumber = phoneNumber.toString().substring(1)
        const groupRemainNumber = remainNumber.match(/..?/g)

        const formattedNumber = `+${dialCode} ${firstNumber} ${groupRemainNumber.join(' ')}`
        return formattedNumber

    } catch (error) {
        // console.error(error);
        return ''
    }
}

export function changeFromIso2SyntaxToNumberSyntax(phone, iso2) {
    if (!phone || !iso2) return ''

    const selectedDialcCode = `+${getCountryCallingCode(iso2.toUpperCase())}`
    let numberSyntax = phone
    if (phone.includes(selectedDialcCode)) {
        numberSyntax = phone.replace(selectedDialcCode, '0');
    }

    return numberSyntax
}

export function changeFromNumberSyntaxToIso2Syntax(phone, iso2) {
    if (!phone || !iso2) return ''

    const selectedDialcCode = `+${getCountryCallingCode(iso2.toUpperCase())}`
    const cutFirstCharPhone = phone.substring(1)
    const iso2Syntax = `${selectedDialcCode}${cutFirstCharPhone}`

    return iso2Syntax
}

/**
 * Change from '0123456789' to '01 23 45 67 89'
 * @param {*} phone '0123456789'
 * @returns '01 23 45 67 89'
 */
export function changeNumberSyntaxToFormatSyntax(phone) {
    if (!phone) return ''
    const groupNumber = phone.match(/..?/g)
    return groupNumber.join(' ')
}

/**
 * Change from '01 23 45 67 89' to '0123456789'
 * @param {*} phone '01 23 45 67 89'
 * @returns '0123456789'
 */
export function changeFormatSyntaxToNumberFormat(phone) {
    if (!phone) return ''
    return phone.replaceAll(' ', '')
}

/**
 * Check phone number (+XXY YY YY YY YY) pattern X = (1-3 length), Y = 9 length
 * @param {*} phone 
 */
export function  checkPhoneNumberInputLength(phone) {
    const phoneInputSplit = phone.split(' ')
    return (phoneInputSplit.length === 5 && phoneInputSplit[4].length === 3)
}
