
import {
    IonButton,
} from '@ionic/react';
import Logout from '@mui/icons-material/Logout'
import usersPathHelper from 'helper/pathHelper/users';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSignOutUserMutation } from 'store/services/users';
import { baseSplitApi } from 'store/services/_base';
import useCredentials from 'hooks/useCredentials';
import { resetPagesConfigState } from 'store/slices/indexPagesConfig';
import useDevice from 'hooks/useDevice'

const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        deleteUserSessionsPath,
        newUserSessionsPath
    } = usersPathHelper()
    const [signOutUser, { isLoading }] = useSignOutUserMutation()
    const { resetCredentialsAll } = useCredentials()
    const { deviceToken } = useDevice()

    const handleSignOut = async (e) => {
        e.preventDefault()
        try {
            let url = deleteUserSessionsPath
            if (deviceToken) {
                url = `${deleteUserSessionsPath}?device_token=${deviceToken}`
            }
            await signOutUser({ url }).unwrap();
            await resetCredentialsAll();
            dispatch(resetPagesConfigState());
            dispatch(baseSplitApi.util.resetApiState());
            history.replace(newUserSessionsPath);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <IonButton
            onClick={handleSignOut}
            disabled={isLoading}
            expand="full"
        >
            <Logout style={{
                fontSize: '24px',
                color: 'var(--ion-color-text-primary)',
            }}
            />
        </IonButton>
    )
}

export default Index
