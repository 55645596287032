import { useTranslation } from 'react-i18next'
import { isPlatform } from '@ionic/react';
import { useCallback } from 'react';

const getkeyPrefixFromPlatform = () => {
    if (isPlatform('ios')) {
        return 'ios'
    } else if (isPlatform('android')) {
        return 'android'
    }
    return undefined
}

const keyPrefix = getkeyPrefixFromPlatform()
const keyPrefixWithChain = `${keyPrefix}.`

const removekeyPrefixWithChainIfAny = (string) => {
    if (string.startsWith(keyPrefixWithChain)) {
        return string.replace(keyPrefixWithChain, '')
    }
    return string
}

/*
your_translationo_file.json

"your_page": {
    "hello": "Hello I'm default translation"
},
"ios": {
    "your_page": {
        "hello": "Hello, you will see this message only on the iOS device"
    },
}
"android": {
    "your_page": {
        "hello": "Hello, you will see this message only on the Android device"
    },
}
*/
const usePerPlatformTranslation = (nameSpace) => {
    const defaultTranslationResponse = useTranslation(nameSpace);
    const modTranslationResponse = useTranslation(nameSpace, { keyPrefix });
    const defaultT = defaultTranslationResponse.t
    const modT = modTranslationResponse.t

    const handleTranslate = useCallback((key) => {
        let transalationResult = modT(key)
        // Chain translation case 
        // Eg. sign_in_page.enter_data_to_login
        // the result will be ios.sign_in_page.enter_data_to_login when translation missing.
        // Remove 'ios.' or 'android.' from the string
        transalationResult = removekeyPrefixWithChainIfAny(transalationResult)

        // If transalationResult remain the same as a given key
        // it may be missing translation.
        // return default translation instead.
        if (transalationResult === key) {
            return defaultT(key)
        }

        return transalationResult
    }, [
        defaultT,
        modT,
    ])

    return {
        t: handleTranslate
    }
}

export default usePerPlatformTranslation
