import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectApplicationState,
    setIsReady,
    setIsSideMenuVisible,
} from 'store/slices/application'

const useApplication = () => {
    const appState = useSelector(selectApplicationState);
    const dispatch = useDispatch();

    const handleSetAppReady = useCallback((isReady) => {
        dispatch(setIsReady(isReady))
    }, [dispatch])

    const handleSetIsSideMenuVisible = useCallback((isSideMenuVisible) => {
        dispatch(setIsSideMenuVisible({ isSideMenuVisible }))
    }, [dispatch])

    return useMemo(() => ({
        ...appState,
        setIsReady: handleSetAppReady,
        setIsSideMenuVisible: handleSetIsSideMenuVisible,
    }), [
        appState,
        handleSetAppReady,
        handleSetIsSideMenuVisible,
    ])
}

export default useApplication
