import { withFormik } from 'formik'
import Form from './UserSignInForm'

import * as Yup from 'yup'
import { BaseFormikConfig } from 'forms/_baseForm'
import { email } from './validation';

const UserFormValidationSchema = Yup
   .object()
   .shape({
      user: Yup.object().shape({
         email,
         password: Yup.string().required('Le mot de passe doit être rempli.'),
      })
   })

const UserFormikConfig = {
   ...BaseFormikConfig,
   mapPropsToValues: () => ({
      user: {
         email: '',
         password: ''
      }
   }),
   validationSchema: UserFormValidationSchema,
}

export default withFormik(UserFormikConfig)(Form)
