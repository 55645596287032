import PropTypes from 'prop-types'
import {
    IonHeader,
    IonIcon,
} from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons';
import {
    ToolbarWrapper,
    ToolbarContainer,
    ButtonsContainer,
    CustomBackButtonContainer,
    HomeButtonContainer,
    ButtonContainerForDesktop,
    EndButtonsContainer,
    HomeBtnContForDesktWithBackBtn,
} from './styled.js'
import { useHistory } from 'react-router-dom';
import AppLogo from 'components/AppLogo/index.js';
import { useRef } from 'react';
import PageTitle from './PageTitle/index.js';
import PageSideTitle from 'layout/PageSideTitle/index.js';

const PageHeader = ({
    children,
    title,
    isSideTitle,
    withBackButton,
    withHomeIcon,
    backTo,
    onBack,
    action,
    backButtonProps,
    backButtonIcon = arrowBackOutline,
}) => {
    const toolbarRef = useRef()
    const history = useHistory()

    const handleBack = () => {
        onBack && onBack()
        history.replace(backTo)
    }

    return (
        <IonHeader
            className='ion-no-border'
            translucent
            style={{ marginTop: 'env(safe-area-inset-top)' }}
        >
            <ToolbarWrapper ref={toolbarRef}>
                {
                    withBackButton && (
                        <ButtonsContainer slot='start'>
                            {
                                backTo
                                    ?
                                    <CustomBackButtonContainer
                                        {...backButtonProps}
                                        onClick={handleBack}
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={backButtonIcon}
                                        />
                                    </CustomBackButtonContainer>
                                    :
                                    <CustomBackButtonContainer
                                        {...backButtonProps}
                                        onClick={() => history.goBack()}
                                    >
                                        <IonIcon
                                            slot="icon-only"
                                            icon={backButtonIcon}
                                        />
                                    </CustomBackButtonContainer>
                            }
                        </ButtonsContainer>
                    )
                }

                {/* show on mobile only - start */}
                {
                    withHomeIcon && (
                        <HomeButtonContainer slot='start'>
                            {
                                <AppLogo size="32px" />
                            }
                        </HomeButtonContainer>
                    )
                }
                {/* show on mobile only - end */}

                {
                    title && (
                        isSideTitle ?
                            <PageSideTitle>
                                {title}
                            </PageSideTitle>
                            :
                            <PageTitle
                                toolbarRef={toolbarRef}
                                title={title}
                            />

                    )
                }

                {/* show on mobile only - start */}
                {
                    action && (
                        <EndButtonsContainer slot='end'>
                            {action}
                        </EndButtonsContainer>
                    )
                }
                {/* show on mobile only - end */}

                {/* show on desktop only - start */}
                {
                    action
                        ?
                        <>
                            {
                                withBackButton
                                    ?
                                    <HomeBtnContForDesktWithBackBtn slot='start'>
                                        <AppLogo size="32px" />
                                    </HomeBtnContForDesktWithBackBtn>
                                    :
                                    <ButtonContainerForDesktop slot='start'>
                                        <AppLogo size="32px" />
                                    </ButtonContainerForDesktop>
                            }
                            <ButtonContainerForDesktop slot='end'>
                                {action}
                            </ButtonContainerForDesktop>

                        </>
                        :
                        <ButtonContainerForDesktop slot='end'>
                            <AppLogo size="32px" />
                        </ButtonContainerForDesktop>
                }
                {/* show on desktop only - end */}

            </ToolbarWrapper>

            {
                children && (
                    <ToolbarContainer>
                        {children}
                    </ToolbarContainer>
                )
            }
        </IonHeader>
    )
}

PageHeader.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    isSideTitle: PropTypes.bool,
    withBackButton: PropTypes.bool,
    withHomeIcon: PropTypes.bool,
    backTo: PropTypes.string,
    onBack: PropTypes.func,
    action: PropTypes.node,
    styleOptions: PropTypes.object,
    backButtonProps: PropTypes.object,
    backButtonIcon: PropTypes.node,
}

export default PageHeader
