import * as Yup from 'yup'

export const payment_message = Yup.string()

export const redirect_to_pending_on_open = Yup.boolean()

export const email_notification = Yup.boolean()

export const app_notification = Yup.boolean()

export const consultation_label = Yup.string()

export const consultation_place = Yup.string()
