import { useTranslation } from 'react-i18next'
import BaseField from '../BaseField'

const BankCountryField = (props) => {
    const { t } = useTranslation('bank_account')
    return (
        <BaseField
            placeholder={t('bank_country')}
            disabled
            {...props}
        />
    )
}

export default BankCountryField
