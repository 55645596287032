import { Form } from 'formik'
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import { useTranslation } from 'react-i18next';
import useAfterFirstMountedEffect from 'hooks/useAfterFirstMountedEffect';
import CheckBoxField from 'components/inputs/CheckBoxField';
import { DetailText } from './styled'

const Index = ({
    submitButtonRef,
    values,
    submitForm,
    disabled,
}) => {
    const { t } = useTranslation('user_preference')
    const { t: npT } = useTranslation('notification_preference')

    useAfterFirstMountedEffect(() => {
        submitForm()
    }, [values])

    return (
        <Form className='first-form-control first-form-control--second-input-margin'>

            <DetailText>
                {npT('index_page.detail')}
            </DetailText>

            <CheckBoxField
                disabled={disabled}
                name="user_preference.email_notification"
                label={t('email_notification')}
            />

            <CheckBoxField
                disabled={disabled}
                name="user_preference.app_notification"
                label={t('app_notification')}
            />

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
