import styled from "styled-components";
import { styled as mStyled } from '@mui/material/styles';
import { IconButton } from "@mui/material";

export const MultiTransactionContainer = styled.div`
margin-top: 30px;
margin-bottom: 30px;
`

export const MultiTransactionTitleContainer = styled.div`
margin-top: 15px;
font-weight: bold;
color: var(--ion-color-text-primary);
font-size: 15px;
`

export const MultiTransactionDetailContainer = styled.div`
color: var(--ion-color-text-primary);
font-size: 14px;
`

export const MultiTransactionBlankSpace = styled.div`
height: 15px
`

export const TextSpan = styled.span`
font-weight: bold;
font-size: 12px;
`

export const CssIconButton = mStyled(IconButton)({
    padding: '0 8px',

})

export const CycleDateAmountContainer = styled.div`
margin-top: 40px;
margin-bottom: 30px;
`

export const CheckboxFieldContainer = styled.div`
display: flex;
margin-left: 10px;
`
