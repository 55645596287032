export const PARTIAL_LIST_TAG_ID = 'PARTIAL-LIST'
export const USER_TAG_TYPE = 'User'
export const BANK_ACCOUNT_TAG_TYPE = 'BankAccount'
export const USER_SETTINGS_TAG_TYPE = 'UserSetting'
export const TRANSACTION_TAG_TYPE = 'Transaction'
export const TRANSACTION_LATEST_TAG_TYPE = 'TransactionLatest'
export const TRANSACTION_LATEST_FAILED_FOR_TAG_TYPE = 'TransactionLatestFailedFor'
export const CLIENT_TRANSACTION_TAG_TYPE = 'ClientTransaction'
export const USER_CLIENTS_TAG_TYPE = 'UserClient'
export const USER_INVITATIONS_TAG_TYPE = 'UserInvitation'
export const USER_PREFERENCES_TAG_TYPE = 'UserPreference'
export const SUBSCRIPTIONS_TAG_TYPE = 'UserSubscription'
export const USER_PROMOTION_CODE_LATEST_TAG_TYPE = 'UserPromotionCodeLatest'
