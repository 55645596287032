import SettingMenuItem from './SettingMenuItem';
import ManageAccounts from '@mui/icons-material/ManageAccounts'
import AccountBalance from '@mui/icons-material/AccountBalance'
import Payments from '@mui/icons-material/Payments'
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings'
import Notifications from '@mui/icons-material/Notifications'
// import Group from '@mui/icons-material/Group'
import Groups from '@mui/icons-material/Groups'
import Sell from '@mui/icons-material/Sell'
import bankAccountsPathHelper from 'helper/pathHelper/bankAccounts';
import transactionInfoPathHelper from 'helper/pathHelper/transactionInfo';
import userSettingsPathHelper from 'helper/pathHelper/userSettings';
import { useTranslation } from 'react-i18next';
import userClientsPathHelper from 'helper/pathHelper/userClients';
import subscriptionsPathHelper from 'helper/pathHelper/subscriptions';
import userInvitationsPathHelper from 'helper/pathHelper/userInvitations';
import userPreferencesPathHelper from 'helper/pathHelper/userPreferences';
import { isPlatform } from '@ionic/react';

const SettingMenuItems = () => {
    const { t } = useTranslation('setting');

    const { indexUserSettingsPath } = userSettingsPathHelper()
    const { indexTransactionInfoPath } = transactionInfoPathHelper()
    const { indexBankAccountsPath } = bankAccountsPathHelper()
    const { indexUserClientsPath } = userClientsPathHelper()
    const { showSubscriptionsPath } = subscriptionsPathHelper()
    const { indexUserInvitationsPath } = userInvitationsPathHelper()
    const { showNotificationUserPreferencesPath } = userPreferencesPathHelper()

    const menuList = [
        {
            icon: ManageAccounts,
            title: t('personal_info.title'),
            detail: t('personal_info.detail'),
            to: indexUserSettingsPath,
            shouldShow: true,
        },
        {
            icon: AccountBalance,
            title: t('banking_info.title'),
            detail: t('banking_info.detail'),
            to: indexBankAccountsPath,
            shouldShow: true,
        },
        {
            icon: Payments,
            title: t('transaction_info.title'),
            detail: t('transaction_info.detail'),
            to: indexTransactionInfoPath,
            shouldShow: true,
        },
        {
            icon: AdminPanelSettings,
            title: t('sponsorship.title'),
            detail: t('sponsorship.detail'),
            to: indexUserInvitationsPath,
            shouldShow: true,
        },
        {
            icon: Notifications,
            title: t('notification_preferences.title'),
            detail: t('notification_preferences.detail'),
            to: showNotificationUserPreferencesPath,
            shouldShow: true,
        },
        // {
        //     icon: Group,
        //     title: t('employee_access.title'),
        //     detail: t('employee_access.detail'),
        //     to: '#',
        // },
        {
            icon: Sell,
            title: t('subscription.title'),
            detail: t('subscription.detail'),
            to: showSubscriptionsPath,
            shouldShow: !isPlatform('capacitor'),
        },
        {
            icon: Groups,
            title: t('client_information.title'),
            detail: t('client_information.detail'),
            to: indexUserClientsPath,
            shouldShow: true,
        },
    ]

    return menuList.map((menu, index) => menu.shouldShow ? <SettingMenuItem key={index} menu={menu} /> : null)
}

export default SettingMenuItems
