import { IonButton, IonToolbar } from "@ionic/react";
import PageContent from "layout/PageContent";
import styled from "styled-components";

export const ToolbarWrapper = styled(IonToolbar)`
--min-height: var(--ion-tool-bar-min-height);
--background: transparent;
`

export const TpBgButton = styled(IonButton)`
--color: var(--ion-color-text-secondary);
`

export const TransparentPageContent = styled(PageContent)`
--background: transparent;
`
