import PropTypes from 'prop-types'
import DefaultTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const inputStyles = {
    fontSize: '16px',

    '::placeholder': {
        color: 'var(--ion-color-text-base)',
        opacity: 1 /* Firefox */
    },
    ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
        color: 'var(--ion-color-text-base)'
    },
    '::-ms-input-placeholder': { /* Microsoft Edge */
        color: 'var(--ion-color-text-base)'
    },
    "&.Mui-disabled": {
        textFillColor: 'var(--ion-color-text-base)',
    },
}

export const CssTextField = styled(DefaultTextField)({
    "& .MuiInput-underline.Mui-disabled": {
        '&:before': {
            borderBottomStyle: 'solid',
            borderBottomColor: 'var(--ion-color-secondary)',
        },
        '& input': {
            cursor: 'not-allowed',
        }
    },
    '& input': inputStyles,
    '& textarea': inputStyles,
    '& p.Mui-error': {
        fontSize: '14px'
    },
    '& label.MuiInputLabel-root': {
        fontSize: '16px'
    }
});

const TextField = ({
    value = '',
    multiline = false,
    ...rest
}) => {
    return (
        <CssTextField
            autoComplete="off"
            fullWidth
            variant="standard"
            value={value}
            multiline={multiline}
            {...rest}
        />
    )
}

TextField.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    multiline: PropTypes.bool
}

export default TextField
