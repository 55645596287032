import ContentListItem from "components/ContentListItem";
import { Wrapper } from "components/ContentList/styled";
import { Container } from "../styled";
import {
  ProgressContainer,
  ProgressCircularWrapper,
  ProgressCircularContainer,
  TextWrapper,
  TitileContainer,
  DetailContainer,
} from "./styled";
import { useTranslation } from "react-i18next";
import { useGetCompletionUserSettingQuery } from "store/services/userSettings";
import userSettingsPathHelper from "helper/pathHelper/userSettings";
import Spinner from "components/Spinner";
import { formatNumber } from "helper/numberHelper";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const CompletionInfoPercentage = () => {
  const { getCompletionInfoPercentagePath, indexUserSettingsPath } =
    userSettingsPathHelper();
  const { data, isFetching, isSuccess } = useGetCompletionUserSettingQuery({
    url: getCompletionInfoPercentagePath,
  });
  const { t } = useTranslation("home");
  let RenderContent = null;
  if (isSuccess) {
    const rawNumber = data.data.personal_info_progression;
    const number = formatNumber(rawNumber);
    if (rawNumber !== 100) {
      RenderContent = (
        <Wrapper>
          <Container isSecondMarginInline>
            <ContentListItem
              lines="none"
              withBorder
              className="ion-no-padding"
              routerLink={indexUserSettingsPath}
            >
              <ProgressContainer>
                <ProgressCircularWrapper>
                  <ProgressCircularContainer>
                    <CircularProgressbar
                      strokeWidth={15}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        pathColor: "var(--ion-color-primary-greeny-blue)",
                        trailColor: "#32AC9169",
                      })}
                      value={rawNumber}
                    />
                  </ProgressCircularContainer>
                </ProgressCircularWrapper>

                <TextWrapper>
                  <DetailContainer>
                    {t("completion_info_detail", { number })}
                  </DetailContainer>

                  <TitileContainer>{t("complete_your_info")}</TitileContainer>
                </TextWrapper>
              </ProgressContainer>
            </ContentListItem>
          </Container>
        </Wrapper>
      );
    }
  } else if (isFetching) {
    RenderContent = (
      <Wrapper>
        <Container isSecondMarginInline>
          <Spinner />
        </Container>
      </Wrapper>
    );
  }

  return RenderContent;
};

export default CompletionInfoPercentage;
