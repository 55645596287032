import RequestErrorMessages from "components/RequestErrorMessages"
import PageContent from "layout/PageContent"
import PageHeader from "layout/PageHeader"
import PageFooter from "layout/PageFooter"
import PrimaryButton from "components/PrimaryButton"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import { ContentContainer } from "./styled"
import ContentListItem from 'components/ContentListItem'
import { GridContainer } from 'layout/PageContent/styled'
import { DetailLabel, TitleLabel } from 'components/BaseLabels'
import { IonCol, IonRow } from "@ionic/react"
import onErrorRedirect from 'helper/onErrorRedirect'
import userInvitationsPathHelper from "helper/pathHelper/userInvitations"
import { useGetUserInvitationsQuery } from "store/services/userInvitations"
import ContentList from "components/ContentList"
import ContentTextCenter from "components/ContentTextCenter"

const SponsorshipItem = ({ sponsorship }) => {
    const {
        full_name,
        email
    } = sponsorship

    return <ContentListItem
        withBorder
    >
        <GridContainer>
            <IonRow>
                <IonCol>
                    <TitleLabel className='ion-sm-margin-top'>
                        {full_name}
                    </TitleLabel>
                </IonCol>
            </IonRow>

            <IonRow>
                <IonCol>
                    <DetailLabel className='ion-sm-margin-top'>
                        {email || '\u00A0'}
                    </DetailLabel>
                </IonCol>
            </IonRow>
        </GridContainer>
    </ContentListItem >
}

const SponsorshipIndexPage = () => {

    const { t } = useTranslation(['sponsorship', 'application'])

    const { indexUserInvitationsPath, newUserInvitationsPath } = userInvitationsPathHelper()
    const {
        data,
        isFetching,
        error,
        isSuccess
    } = useGetUserInvitationsQuery({ url: indexUserInvitationsPath })


    let RenderContent
    if (isSuccess) {
        if (data?.data?.length) {
            RenderContent = <ContentContainer>
                <RequestErrorMessages error={error} />

                <ContentList>
                    {
                        data.data.map(sponsorship => <SponsorshipItem
                            sponsorship={sponsorship}
                            key={sponsorship.id} />
                        )
                    }
                </ContentList>
            </ContentContainer>
        } else {
            RenderContent = <ContentTextCenter>
                {t('no_data')}
            </ContentTextCenter>
        }
    } else {
        onErrorRedirect(error)
    }

    return (
        <PageLayout>
            <PageHeader
                title={t('index_page.title')}
                withBackButton
            />

            <PageContent loading={isFetching}>
                {RenderContent}
            </PageContent>

            <PageFooter>
                <PrimaryButton routerLink={newUserInvitationsPath}>
                    {t('index_page.new_sponsorship')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
}

export default SponsorshipIndexPage
