import CloseIcon from '@mui/icons-material/Close';
import TextField from 'components/transactions/NewTransactionTextField'
import BaseField from 'components/fields/BaseField';
import { decorateEmailString } from 'helper/stringHelper';
import IntlPhoneInput from 'components/inputs/IntlPhoneField';
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react';
import MainFieldAndSubButtonContainer from 'components/MainFieldAndSubButtonContainer';
import { CssIconButton } from './styled';
import { useFormikContext } from 'formik';
import userSettingsPathHelper from 'helper/pathHelper/userSettings';
import { useGetUserSettingQuery } from 'store/services/userSettings';

const UserClientPath = () => {
    const { t } = useTranslation(['transaction', 'application', 'user_client'])
    const { values, setFieldValue } = useFormikContext();
    const intlRef = useRef()

    // Initial phone_iso2 incase of create user client
    const { indexUserSettingsPath } = userSettingsPathHelper()
    const { data } = useGetUserSettingQuery({ url: indexUserSettingsPath })

    useEffect(() => {
        if (data && intlRef.current) {
            const individual = data.data.individual
            const phone_iso2 = individual.phone_iso2
            intlRef.current.setCountry(phone_iso2);
        }
    }, [data, intlRef.current])

    return (
        <>
            <MainFieldAndSubButtonContainer
                SubButton={() => (
                    <CssIconButton
                        onClick={() => setFieldValue('submit_new_client_form', false)}
                    >
                        <CloseIcon style={{ color: 'var(--ion-color-primary-contrast)' }} />
                    </CssIconButton>
                )}
            />

            <BaseField
                Component={TextField}
                name='user_client.last_name'
                placeholder={t('last_name')}
                appendasterisk='true'
            />

            <BaseField
                Component={TextField}
                name='user_client.first_name'
                placeholder={t('first_name')}
                appendasterisk='true'
            />

            <IntlPhoneInput
                name='user_client.phone'
                countryName='user_client.phone_iso2'
                placeholder={t('new_page.phone')}
                intlRef={intlRef}
                appendasterisk='true'
            />

            <BaseField
                Component={TextField}
                name='user_client.email'
                placeholder={t('new_page.email')}
                onChange={event => setFieldValue('user_client.email', decorateEmailString(event.target.value))}
            />
        </>
    )
}

export default UserClientPath
