import {
    useEffect,
    useState,
    Fragment,
    useRef,
} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useLazyGetClientBanksQuery } from 'store/services/clientPayments'
import { getIn, useFormikContext } from 'formik';
import clientPaymentPathHelper from 'helper/pathHelper/clientPayment';
import { getURLSearchParamsHelper } from 'helper/URLSearchParamsHelper';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

const hideKeyboard = (el, value) => {
    if (value === null) return // Clear text case
    el.blur()
}

const SelectBridgeapiBankNoField = ({
    name,
    ...rest
}) => {
    const autoCompleteTextRef = useRef()
    const childBankAutoCompleteTextRef = useRef()
    const {
        // values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
    } = useFormikContext()
    const [trigger, {
        data,
        isLoading,
        isUninitialized,
    }] = useLazyGetClientBanksQuery()

    const [open, setOpen] = useState(false);
    const uuid = getURLSearchParamsHelper('uuid')
    const { getClientSelectBankPath } = clientPaymentPathHelper(uuid)

    useEffect(() => {
        if (!open) {
            return undefined;
        }
        if (isUninitialized) {
            trigger({ url: getClientSelectBankPath })
        }
    }, [open]);

    const { t } = useTranslation(['application', 'transaction'])

    const [inputValue, setInputValue] = useState("");
    const [childBanks, setChildBanks] = useState([]);

    useEffect(() => {
        if (isSubmitting && childBanks.length) {
            setFieldTouched(name)
        }
    }, [isSubmitting])

    const handleRenderOption = (renderOptionProps, option) => {
        return <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...renderOptionProps}
        >
            <img
                loading="lazy"
                width="40"
                src={option.logo_url}
                alt=""
            />
            {option.name}
        </Box>
    }

    const handleRenderInput = (defaultProps) => {
        return <TextField
            {...defaultProps}
            variant='standard'
            InputProps={{
                ...defaultProps.InputProps,
                endAdornment: (
                    <Fragment>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {defaultProps.InputProps.endAdornment}
                    </Fragment>
                ),
            }}
            error={getIn(touched, name) && !childBanks.length && Boolean(getIn(errors, name))}
            helperText={getIn(touched, name) && !childBanks.length && getIn(errors, name)}
            label={t('bridgeapi_bank_no')}
            inputRef={autoCompleteTextRef}
            {...rest}
        />
    }

    const renderChildInput = (defaultProps) => {
        return <TextField
            {...defaultProps}
            variant='standard'
            error={getIn(touched, name) && Boolean(getIn(errors, name))}
            helperText={getIn(touched, name) && getIn(errors, name)}
            label={t('child_bridgeapi_bank_no')}
            inputRef={childBankAutoCompleteTextRef}
        />
    }

    const handleChange = (_, value) => {
        if (!value?.child_banks) {
            setFieldTouched(name)
        }
        setFieldValue(name, value?.bridgeapi_no)
        setChildBanks(value?.child_banks || [])
        hideKeyboard(autoCompleteTextRef.current, value)
    }

    const handleChildChange = (_, value) => {
        setFieldValue(name, value?.bridgeapi_no)
        hideKeyboard(childBankAutoCompleteTextRef.current, value)
    }

    return (
        <>
            <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                loading={isLoading}
                loadingText={t('loading')}
                noOptionsText={t('no_options')}
                autoHighlight
                options={data?.data || []}
                getOptionLabel={(option) => option.name}
                onChange={handleChange}
                renderOption={handleRenderOption}
                renderInput={handleRenderInput}
                inputValue={inputValue}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                }}
            />

            {
                childBanks.length
                    ?
                    <div className='ion-margin-top'>
                        <Autocomplete
                            autoHighlight
                            options={childBanks}
                            getOptionLabel={(option) => option.name}
                            onChange={handleChildChange}
                            renderOption={handleRenderOption}
                            renderInput={renderChildInput}
                        />
                    </div>
                    :
                    null
            }
        </>
    );
}

export default SelectBridgeapiBankNoField
