import { useField } from "formik"
import {
    Container,
    LabelContainer,
    ToggleContainer,
    Toggle
} from './styled'

const ToggleField = ({
    name,
    label
}) => {
    const [, meta, helper] = useField(name)

    const { value } = meta

    const { setValue } = helper

    const handleChange = (event) => {
        setValue(event.target.checked)
    }

    return (
        <Container>
            <LabelContainer>
                {label}
            </LabelContainer>
            <ToggleContainer>
                <Toggle
                    checked={value}
                    onIonChange={handleChange}
                />
            </ToggleContainer>
        </Container>
    )
}
export default ToggleField
