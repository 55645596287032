import {
    baseSplitApi,
    baseGetResourcesProvidesTags,
    baseResourceRequestTags,
    baseCreateResourceInvalidatesTags,
    baseDeleteResourceInvalidatesTags
} from './_base'

export const bankAccountsSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getBankAccounts: builder.query({
            query: config => config,
            providesTags: baseGetResourcesProvidesTags
        }),
        getBankAccount: builder.query({
            query: config => config,
            keepUnusedDataFor: 0,
            providesTags: baseResourceRequestTags
        }),
        createBankAccount: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
        updateBankAccount: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseResourceRequestTags
        }),
        updatePrimaryBankAccount: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseResourceRequestTags
        }),
        deleteBankAccount: builder.mutation({
            query: config => ({
                ...config,
                method: 'DELETE',
            }),
            invalidatesTags: baseDeleteResourceInvalidatesTags
        }),
    }),
})

export const {
    useGetBankAccountsQuery,
    useGetBankAccountQuery,
    useCreateBankAccountMutation,
    useUpdateBankAccountMutation,
    useUpdatePrimaryBankAccountMutation,
    useDeleteBankAccountMutation,
} = bankAccountsSlice
