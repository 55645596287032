import {
  IonImg,
} from '@ionic/react';
import PrimaryLink from 'components/PrimaryLink';
import transactionsPathHelper from 'helper/pathHelper/transactions';
import MainPageFooter from 'layout/MainPageFooter';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import CompletionInfoPercentage from './CompletionInfoPercentage';
import LatestPendingTransactions from './LatestPendingTransactions';
import LatestOtherTransactions from './LatestOtherTransactions';
import Spinner from 'components/Spinner';
import { useGetLatestTransactionsQuery } from 'store/services/transactions';
import phoneImage from "assets/images/home-placeholder.png"
import { DetailLabel, TitleLabel } from 'components/BaseLabels';
import NotificationButton from 'components/NotificationButton';
import {
  HomeWrapperContent,
  ImageWrapper,
  ImageContainer,
} from './styled';
import PageContent from 'layout/PageContent';

const Home = () => {
  const { t } = useTranslation('home')
  const {
    indexTransactionsPath,
    showLatestTransactionsPath
  } = transactionsPathHelper()
  const {
    data,
    isFetching,
    isSuccess,
  } = useGetLatestTransactionsQuery({
    url: showLatestTransactionsPath
  })

  let RenderContent
  let RenderLinkToList
  if (isSuccess) {
    const pendingTransactions = data.data.pending
    const otherTransactions = data.data.other
    if (pendingTransactions.length || otherTransactions.length) {
      RenderContent = <div>
        <LatestPendingTransactions transactions={pendingTransactions} />
        <LatestOtherTransactions transactions={otherTransactions} />
      </div>
      RenderLinkToList = <PrimaryLink to={indexTransactionsPath}>
        {t('see_all_transactions')}
      </PrimaryLink>

    } else {
      RenderContent = <ImageWrapper>
        <ImageContainer>
          <div className='text-margin'>
            <TitleLabel>
              {t('greeting')}
            </TitleLabel>
            <DetailLabel>
              {t('greeting_detail')}
            </DetailLabel>
          </div>
          <IonImg src={phoneImage} />
        </ImageContainer>
      </ImageWrapper>
    }
  } else if (isFetching) {
    RenderContent = <div>
      <Spinner />
    </div>
  }

  return (
    <PageLayout>
      <PageHeader
        withHomeIcon
        action={<NotificationButton />}
      />
      <PageContent>
        <HomeWrapperContent>
          <CompletionInfoPercentage />

          {RenderContent}
        </HomeWrapperContent>
      </PageContent>

      <MainPageFooter>
        {RenderLinkToList}
      </MainPageFooter>
    </PageLayout>
  );
};

export default Home;
