import TransactionAmount from 'components/transactions/AmountLabel'
import TransactionPaymentLink from 'components/transactions/PaymentLinkLabel'
import TransactionPaymentLinkQr from 'components/transactions/PaymentLinkQr'
import transactionsPathHelper from 'helper/pathHelper/transactions'
import appPathHelper from 'helper/pathHelper/app'
import PageContent from 'layout/PageContent'
import PageHeader from 'layout/PageHeader'
import {
    HiddenTransactionLabel,
    StatusIconContainer,
} from './styled'
import { useTranslation } from 'react-i18next'
import PrimaryButton from 'components/PrimaryButton'
import PrimaryLink from 'components/PrimaryLink'
import clientPaymentPathHelper from 'helper/pathHelper/clientPayment'
import CheckCircle from "@mui/icons-material/CheckCircle"
import TransactionPageFooter from 'layout/TransactionPageFooter'
import { useHistory } from 'react-router'

const Success = ({ data }) => {
    const { amount, status, uuid, id } = data.data
    const { showTransactionsPath } = transactionsPathHelper(id)
    const { rootPath } = appPathHelper()
    const { showClientAcceptPaymentUri } = clientPaymentPathHelper(uuid)

    const { t } = useTranslation(['transaction', 'application'])
    const history = useHistory()

    return (
        <>
            <PageHeader
                withBackButton
            />

            <PageContent>
                <TransactionAmount status={status} amount={amount} bold />

                <TransactionPaymentLink status={status} link={showClientAcceptPaymentUri} />

                <TransactionPaymentLinkQr status={status} link={showClientAcceptPaymentUri} />

                <div className='ion-margin-top'>
                    {/* For spare space to make UI ratio like Pending page */}
                    <HiddenTransactionLabel bold>
                        {'hidden'}
                    </HiddenTransactionLabel>
                    <HiddenTransactionLabel>
                        {'hidden'}
                    </HiddenTransactionLabel>
                </div>

                <StatusIconContainer>
                    <CheckCircle style={{ fontSize: 32, color: 'var(--ion-color-primary-greeny-blue)' }} />
                </StatusIconContainer>
            </PageContent>

            <TransactionPageFooter>
                <div className='ion-margin-bottom'>
                    <PrimaryLink
                        to='#'
                        onClick={() => history.replace(showTransactionsPath)}
                        style={{
                            fontSize: "13px",
                            fontWeight: 'bold'
                        }}
                    >
                        {t('after_create_page.show_more')}
                    </PrimaryLink>
                </div>

                <PrimaryButton
                    routerLink={rootPath}
                >
                    {t('close')}
                </PrimaryButton>
            </TransactionPageFooter>
        </>
    )
}

export default Success
