import styled from "styled-components";
// import validateImage from "assets/images/validate_screen.png"

export const FormContainer = styled.div`
margin-top: 60px;
`

export const DetailContainer = styled.div`
margin-top: 40px;
`

export const AcceptTermContinue = styled.div`
margin-top: min(calc(2.24vh + 2.24vw), 40px);
`

export const MobileEmailContainer = styled.div`
margin-top: 84px;
`