import styled from "styled-components";

export const SpinnerContainer = styled.div`
margin-top: 50px;
`
export const TextButton = styled.div`
color: var(--ion-color-text-primary);
font-size: 13px;
font-weight: bold;
`
