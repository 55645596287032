import { UserSignUpPhoneForm } from "forms/usersForm";
import usersPathHelper from "helper/pathHelper/users";
import { useHistory } from "react-router-dom";
import useRedirectIfAlreadyAuth from "hooks/useRedirectIfAlreadyAuth";
import PageLayout from "layout/PageLayout";
import { useTranslation } from "react-i18next";
import PageContentWithAppLogo from "layout/PageContentWithAppLogo";
import useSubmitButtonRef from "hooks/useSubmitButtonRef";
import RequestErrorMessages from "components/RequestErrorMessages";
import {
  ContainerWithLabels,
  DetailContainer,
  NSDetailLabelTwo,
  NSTitleLabelDesktop,
  NSTitleLabelMobile,
} from "components/BaseLabels/index.js";
import PageContentSmaller from "layout/PageContentSmaller/index.js";
import { IonGrid, IonRow, IonCol, IonImg, isPlatform } from "@ionic/react";
import validateImage from "assets/images/validate_screen.png";
import { MobileEmailContainer } from "./styled";
import {useEffect, useState} from "react";
import enrollmentsPathHelper from "../../../helper/pathHelper/enrollments";
import {
  usePreEnrollmentMutation,
  useSetPhoneEnrollmentMutation,
  useVerifyPhoneEnrollmentMutation,
} from "../../../store/services/enrollments";

const SignUpPage = () => {
  useRedirectIfAlreadyAuth();

  const [enrollement, setEnrollment] = useState(null);
  const [e, setE] = useState(null);
  const { preEnrollmentPath } = enrollmentsPathHelper();
  const [preEnrollment] = usePreEnrollmentMutation();
  const [setPhoneEnrollment] = useSetPhoneEnrollmentMutation();
  const [verifyPhoneEnrollment, { error }] =
    useVerifyPhoneEnrollmentMutation();
  const [submitButtonRef] = useSubmitButtonRef();
  const history = useHistory();
  const { t: userT } = useTranslation("user");
  const [isLoading, setIsLoading] = useState(false);
  const [codeErrors, setCodeErrors] = useState(false);

  const getEnrollment = async () => {
    if (enrollement) return enrollement;
    const e = await preEnrollment({
      url: preEnrollmentPath,
    });
    if(e?.data?.data) {
      setEnrollment(e.data.data);
    }
    return e;
  }

  const sendCode = async (data) => {
    let e = await getEnrollment();
    setIsLoading(true);
    const { setPhoneEnrollmentPath } = enrollmentsPathHelper(e.data.data.id);
    e = await setPhoneEnrollment({
      url: setPhoneEnrollmentPath,
      body: { enrollment: { mobile: data.phone } },
    });
    if (e?.data?.data !== null) {
      setEnrollment(e?.data?.data);
    } else {
      alert("Une erreur s'est produite ! Veuillez réessayer");
    }
    setTimeout(() => setIsLoading(false), 3000);

  };

  const checkCode = async (data) => {
    if (enrollement) {
      const { verifyPhoneEnrollmentPath } = enrollmentsPathHelper(
        enrollement.id
      );
      const e = await verifyPhoneEnrollment({
        url: verifyPhoneEnrollmentPath,
        body: { enrollment: { code: data.code } },
      });
      if (e?.data?.data?.mobile_verified === true) {
        const { emailUserRegistrationsPath } = usersPathHelper();
        setEnrollment(e);
        history.push(emailUserRegistrationsPath, {
          state: { enrollment: e.data.data },
        });
        // push("/users/sign-up/email", { enrollment: e.data.data });
      } else {
        setCodeErrors((previousValue) => previousValue + 1)
      }
    } else {
      alert("Une erreur s'est produite ! Veuillez réessayer");
    }
  };

  let RenderImageContent;
  if (!isPlatform("mobile")) {
    RenderImageContent = (
      <IonCol sizeSm="6">
        <MobileEmailContainer>
          <IonImg src={validateImage} style={{ height: "720px" }} />
        </MobileEmailContainer>
      </IonCol>
    );
  }

  return (
    <PageLayout>
      <PageContentSmaller
        contentProps={{
          sizeLg: "9",
        }}
        withAreaFading={false}
      >
        <IonGrid>
          <IonRow>
            <IonCol sizeSm={!isPlatform("mobile") ? "6" : "12"}>
              <PageContentWithAppLogo
                body={
                  <>
                    <RequestErrorMessages error={error} />
                    <div className="text-margin">
                      <ContainerWithLabels>
                        <NSTitleLabelDesktop>
                          {userT("sign_up_page.phone")}
                        </NSTitleLabelDesktop>

                        <NSTitleLabelMobile>
                          {userT("sign_up_page.phone")}
                        </NSTitleLabelMobile>
                      </ContainerWithLabels>

                      <DetailContainer>
                        <NSDetailLabelTwo>
                          {userT(
                            "sign_up_page.enter_your_phone_and_you_will_receive_message_with_code"
                          )}
                        </NSDetailLabelTwo>
                      </DetailContainer>
                    </div>

                    <UserSignUpPhoneForm
                      sendCode={sendCode}
                      submitButtonRef={submitButtonRef}
                      //onSubmit={checkCode}
                      checkCode={checkCode}
                      isLoading={isLoading}
                      codeErrors={codeErrors}
                    />
                    {/*<AcceptTermContinue>
                                            <PrimaryButton
                                                onClick={handleClick}
                                                disabled={isLoading}
                                            >
                                                {appT('continue')}
                                            </PrimaryButton>
                                        </AcceptTermContinue>*/}

                    {/*<TwoDots></TwoDots>*/}
                  </>
                }
              />
            </IonCol>
            {RenderImageContent}
          </IonRow>
        </IonGrid>
      </PageContentSmaller>
    </PageLayout>
  );
};

export default SignUpPage;
