import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox' } };

const CustomCheckBox = ({
    styleProps,
    ...rest
}) => {
    const sx = {
        ...styleProps,
        color: "var(--ion-color-text-primary)",
        '&.Mui-checked': {
            color: "var(--ion-color-primary-greeny-blue)",
        },
    }

    return (
        <Checkbox
            {...label}
            {...rest}
            sx={sx}
        />
    );
}
export default CustomCheckBox
