import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectORAATQAcceptPaymentLinkState } from 'store/slices/oneRequestAtATimeQueries'

const useClientAcceptPaymentLink = () => {
    const clientPaymentState = useSelector(selectORAATQAcceptPaymentLinkState)

    return useMemo(() => clientPaymentState, [clientPaymentState])
}

export default useClientAcceptPaymentLink
