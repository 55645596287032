import { getIn } from 'formik'
import { styled } from '@mui/material/styles';
import { CssTextField } from '../TextField'
import { LabelContainer } from './styled'
import BaseField from 'components/fields/BaseField';

const CssTextAreaField = styled(CssTextField)({
    '&': {
        marginTop: '0 !important;',
    },
    '& .MuiOutlinedInput-root': {
        border: '1px solid black',
        borderRadius: '0',
        padding: '5px',
        boxShadow: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'unset'
    }
})

const TextAreaField = ({
    label,
    name,
    ...rest
}) => {

    const renderTextAreaField = ({ field, form }) => {
        return <CssTextAreaField
            {...field}
            onChange={form.handleChange}
            value={getIn(form.values, name)}
            error={getIn(form.touched, name) && Boolean(getIn(form.errors, name))}
            helperText={getIn(form.touched, name) && getIn(form.errors, name)}
            autoComplete="off"
            fullWidth
            multiline
            rows={4}
            name={name}
            {...rest}


        />
    }

    return (
        <>
            {
                label && <LabelContainer>
                    {label}
                </LabelContainer>
            }
            <BaseField
                render={renderTextAreaField}
            />

        </>
    )
}

export default TextAreaField
