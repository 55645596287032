import {
    DEBUGS_BASE_PATH
} from 'constants/routeConstants'

// Please visit ./_base.js for more information.

const debugsPathHelper = (id) => {
    return {
        indexDebugsPath: `/${DEBUGS_BASE_PATH}`
    }
}

export default debugsPathHelper
