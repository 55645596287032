import { IonLabel } from '@ionic/react'
import { isSortActive } from 'helper/sortHelper'
import { Container, IconWrapper } from './styled.js'

const SortLink = ({
    label,
    value,
    currentValue,
    Icon,
    isStringIcon,
}) => {
    const active = isSortActive(currentValue, value)
    return (
        <Container
            active={active}
            lines='none'
        >
            <IonLabel>
                {label}
            </IonLabel>

            <IconWrapper
                slot="start"
                active={active}
            >
                {
                    (isStringIcon)
                        ?
                        Icon
                        :
                        <Icon />
                }
            </IconWrapper>
        </Container>
    )
}

export default SortLink
