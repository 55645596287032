import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    resetPage,
    selectCurrentPageConfig,
    setPage,
    setSearch,
    setSort
} from "store/slices/indexPagesConfig"

const useCurrentIndexPageConfig = (stateKey) => {
    const pagesConfig = useSelector(selectCurrentPageConfig(stateKey))

    const dispatch = useDispatch()

    const handleSetPage = useCallback(() => {
        dispatch(setPage({ stateKey }))
    }, [dispatch, stateKey])

    const handleResetPage = useCallback(() => {
        dispatch(resetPage({ stateKey }))
    }, [dispatch, stateKey])

    const handleSetSearch = useCallback((key, value) => {
        dispatch(setSearch({ stateKey, key, value }))
    }, [dispatch, stateKey])

    const handleSetSort = useCallback((value) => {
        dispatch(setSort({ stateKey, value }))
    }, [dispatch, stateKey])

    return useMemo(() => {
        return [
            pagesConfig,
            {
                setPage: handleSetPage,
                resetPage: handleResetPage,
                setSearch: handleSetSearch,
                setSort: handleSetSort,
            }
        ]
    }, [
        pagesConfig,
        handleSetPage,
        handleResetPage,
        handleSetSearch,
        handleSetSort,
    ])
}

export default useCurrentIndexPageConfig
