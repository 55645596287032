import { IonButton } from '@ionic/react';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import PrimaryButton from 'components/PrimaryButton';
import PrimaryLink from 'components/PrimaryLink';
import RequestErrorMessages from 'components/RequestErrorMessages';
import { BankAccountForm } from 'forms/bankAccountsForm';
import { styledGetPrimaryResourceColor } from 'helper/applicationHelper';
import { findAnyErrorState, isAnyStateIsLoading } from 'helper/multipleStatesChecker';
import onErrorRedirect from 'helper/onErrorRedirect';
import bankAccountsPathHelper from 'helper/pathHelper/bankAccounts';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import PageContent from 'layout/PageContent';
import PageFooter from 'layout/PageFooter';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import {
    useUpdateBankAccountMutation,
    useGetBankAccountQuery,
    useDeleteBankAccountMutation,
    useUpdatePrimaryBankAccountMutation
} from 'store/services/bankAccounts';

const BankAccountShowPage = () => {
    const { id } = useParams()
    const history = useHistory()
    const {
        showBankAccountsPath,
        indexBankAccountsPath,
        deleteBankAccountsPath,
        updateBankAccountsPath,
        updatePrimaryBankAccountsPath
    } = bankAccountsPathHelper(id)
    const queryState = useGetBankAccountQuery({ url: showBankAccountsPath })
    const {
        data,
        isSuccess,
        isFetching,
    } = queryState

    const [updateBankAccount, mutateState] = useUpdateBankAccountMutation()
    const [deleteBankAccount, deleteState] = useDeleteBankAccountMutation()
    const [updatePrimaryBankAccount, updateState] = useUpdatePrimaryBankAccountMutation()

    const states = [updateState, mutateState, queryState, deleteState]
    const isLoading = isAnyStateIsLoading(states)
    const error = findAnyErrorState(states)

    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const { t } = useTranslation(['bank_account', 'application'])

    const handleSubmit = async (data) => {
        try {
            await updateBankAccount({
                url: updateBankAccountsPath,
                body: data
            }).unwrap()
        } catch (error) {
            console.error('BankAccountShowPage#handleSubmit', error);
        }
    }

    const handleDelete = async () => {
        try {
            await deleteBankAccount({ url: deleteBankAccountsPath }).unwrap()
            history.replace(indexBankAccountsPath)
        } catch (error) {
            console.error('BankAccountShowPage#handleDelete', error);
        }
    }

    const handlePrimaryClick = async () => {
        if (data.data.primary) {
            return
        }
        try {
            await updatePrimaryBankAccount({ url: updatePrimaryBankAccountsPath }).unwrap()
        } catch (error) {
            console.log('BankAccountItem#handlePrimaryClick', error);
        }
    }

    let RenderDeleteButton
    let RenderContent
    if (isSuccess) {
        RenderContent = <BankAccountForm
            data={data.data}
            submitButtonRef={submitButtonRef}
            onSubmit={handleSubmit}
        />
        if (data.data.can_delete) {
            RenderDeleteButton = <div className='ion-margin-bottom'>
                <PrimaryLink
                    to='#'
                    disabled={isLoading}
                    onClick={handleDelete}
                >
                    {t('show_page.delete')}
                </PrimaryLink>
            </div>
        }
    }

    if (onErrorRedirect(error)) return onErrorRedirect(error)

    return (
        <PageLayout>
            <PageHeader
                title={t('show_page.title')}
                withBackButton
                backTo={indexBankAccountsPath}
                action={
                    isSuccess && <IonButton
                        onClick={handlePrimaryClick}
                    >
                        <FavoriteBorder style={{ fontSize: '32px', color: styledGetPrimaryResourceColor({ primary: data.data.primary }) }} />
                    </IonButton>
                }
            />

            <PageContent loading={isFetching}>
                <RequestErrorMessages error={error} />

                {RenderContent}
            </PageContent>

            <PageFooter>
                {RenderDeleteButton}

                <PrimaryButton
                    onClick={handleClick}
                    disabled={isLoading}
                >
                    {t('save')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    );
};

export default BankAccountShowPage;
