import styled from "styled-components";

export const Container = styled.div`
color: #a94442;
background-color: #f2dede;
border-color: #ebccd1;
padding: 15px;
margin-bottom: 10px;
border: 1px solid transparent;
border-radius: 4px;
font-size: 14px;
text-align: left;

ul {
    margin-bottom: 0;
    margin-top: 0;
}
`
