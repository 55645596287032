import HiddenSubmitButton from 'components/HiddenSubmitButton';
import AcceptTermOfServiceField from 'components/inputs/AcceptTermOfServiceField';
import { SelectBridgeapiBankNoField } from 'components/fields/transactions';
import {
    FormContainer,
    BodyContainer,
    FooterContainer
} from './styled'

const Index = ({
    submitButtonRef,
    data,
}) => {

    return (
        <FormContainer className='first-form-control first-form-control--first-input-margin'>
            <BodyContainer>
                <SelectBridgeapiBankNoField
                    name='transaction.bridgeapi_bank_no'
                />
            </BodyContainer>

            <FooterContainer>
                {
                    !data.terms_and_conditions_accepted
                    &&
                    <div>
                        <AcceptTermOfServiceField
                            name='transaction.terms_and_conditions_accepted'
                            tosURI='https://cashlee.eu/cgu-particuliers'
                        />
                    </div>
                }
            </FooterContainer>


            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </FormContainer>
    )
}

export default Index
