import inflection from 'inflection'

/*
Path helper can be use for most of auto generated paths by Rails.
Example.
index path: /posts
show path: /posts/1
edit path: /posts/1/edit
update path /posts/2
delete path: /posts/1

@Used for: internal -> In ionic app
@Used for: external -> Rails API
*/
const basePathHelper = (path, id) => {
    const camelized = inflection.camelize(path)

    const generatedPaths = {}
    // @Used for: both
    generatedPaths[`index${camelized}Path`] = `/${path}`
    // @Used for: internal
    generatedPaths[`new${camelized}Path`] = `/${path}/new`
    // @Used for: external
    generatedPaths[`create${camelized}Path`] = `/${path}`
    // @Used for: internal
    if (id) generatedPaths[`show${camelized}Path`] = `/${path}/${id}`
    // @Used for: both
    if (id) generatedPaths[`showPdf${camelized}Uri`] = `${process.env.REACT_APP_BASE_API}/${path}/${id}/pdf`
    // @Used for: internal
    if (id) generatedPaths[`edit${camelized}Path`] = `/${path}/${id}/edit`
    // @Used for: external
    if (id) generatedPaths[`update${camelized}Path`] = `/${path}/${id}`
    // @Used for: external
    if (id) generatedPaths[`delete${camelized}Path`] = `/${path}/${id}`

    return generatedPaths
}

export default basePathHelper
