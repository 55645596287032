import { useRef } from "react"

const useSubmitButtonRef = () => {
    const submitButtonRef = useRef()

    const handleSubmitButtonRef = () => submitButtonRef.current.click()

    return [submitButtonRef, handleSubmitButtonRef]
}

export default useSubmitButtonRef
