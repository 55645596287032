import {
    IonGrid,
    IonRow,
} from '@ionic/react';
import { Container as C } from '../styled'
import styled from "styled-components";

export const Container = styled(C)`
margin-inline: 0;
margin-bottom: 15px;
`

export const CardContainer = styled(IonGrid)`
padding: 6px 10px;
border: 1px solid var(--ion-color-text-secondary);
border-radius: 9px;
background-color: var(--ion-color-text-secondary);
text-align: left;
gap: 10px;
cursor: pointer;
`

export const CardUpperContainer = styled(IonRow)`
margin-bottom: 7px;
`

export const CardDownerContainer = styled(IonRow)`
gap: 10px;
`

export const Title = styled.div`
color: var(--ion-color-text-primary);
font-size: 14px;
font-weight: 500;
`

export const Detail = styled.div`
color: var(--ion-color-text-primary);
font-size: 12px
`

export const Amount = styled(Title)`
text-align: rigth;
`
