import { createSlice } from '@reduxjs/toolkit'
import { PAGESCONFIG_SLICE_NAME } from 'constants/sliceNameConstants'
import { toAcsValue, toDescValue } from 'helper/sortHelper'
import { transactionsAddMatchers } from './transactions'
import { baseInitialState } from './_base'
import { TRANSACTIONS_BASE_PATH } from "constants/routeConstants"

const resetStateToRefetch = (state) => {
    state.page = 1
    state.shouldRefetch = true
    state.timestamp = Date.now()
}

const indexPagesConfigSlice = createSlice({
    name: PAGESCONFIG_SLICE_NAME,
    initialState: baseInitialState,
    reducers: {
        setPage(state, { payload }) {
            const { stateKey } = payload
            state[stateKey].page++
        },
        resetPage(state, { payload }) {
            const { stateKey } = payload
            resetStateToRefetch(state[stateKey])
        },
        setSearch(state, { payload }) {
            const { stateKey, key, value } = payload
            resetStateToRefetch(state[stateKey])
            state[stateKey].q[key] = value

        },
        setSort(state, { payload }) {
            const { stateKey, value } = payload
            const currentValue = state[stateKey].q.s
            const [rawValue, dir] = currentValue.split(' ')
            const isSameSort = rawValue === value
            let changedValue
            if (isSameSort) {
                // Change asc to desc and vice versa
                if (dir) {
                    changedValue = (dir === 'asc') ? toDescValue(value) : toAcsValue(value)
                } else {
                    // No value, sort as ascendance by default.
                    changedValue = toAcsValue(value)
                }
            } else {
                // No value, sort as ascendance by default.
                changedValue = toAcsValue(value)
            }
            state[stateKey].q.s = changedValue
            resetStateToRefetch(state[stateKey])
        },
        resetPagesConfigState(state) {
            const pageNames = [
                TRANSACTIONS_BASE_PATH,
            ]
            pageNames.forEach((pageName) => {
                resetStateToRefetch(state[pageName])
                state[pageName].ids = []
                state[pageName].entities = []
            })
        },
    },
    extraReducers: (builder) => {
        transactionsAddMatchers(builder)
    },
})

export const {
    setPage,
    resetPage,
    setSearch,
    setSort,
    resetPagesConfigState,
} = indexPagesConfigSlice.actions

export const selectCurrentPageConfig = (stateName) => (state) => {
    if (!baseInitialState[stateName]) {
        throw new Error('Inorder to use this feature you have to add MODULE_BASE_PATH to initialState located at pagesConfig.js first')
    }
    return state[PAGESCONFIG_SLICE_NAME][stateName]
}

export default indexPagesConfigSlice
