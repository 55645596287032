import {
    Wrapper,
    Container,
} from './styled.js'

const PageFooter = ({
    fading = true,
    children,
    wrapperProps,
    containerProps,
}) => {
    return (
        <Wrapper
            className='ion-no-border'
            fading={fading}
            {...wrapperProps}
        >
            <Container {...containerProps}>
                {children}
            </Container>
        </Wrapper>
    )
};

export default PageFooter;
