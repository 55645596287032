import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react'
import styled from "styled-components";

export const Wrapper = styled(IonContent)`
text-align: center;
`
export const GridContainer = styled(IonGrid)`
  padding-left: 18px;
  padding-right: 14px;
  padding-top: 11px;
  padding-bottom: 11px;
`

export const Container = styled(IonGrid)`
height: 100%;
`

export const Row = styled(IonRow)`
height: 100%;
`

export const Col = styled(IonCol)``
