import {
    baseSplitApi,
} from './_base'

export const userClientsSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        createUserDevice: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
        }),
    }),
})

export const {
    useCreateUserDeviceMutation,
} = userClientsSlice
