import {
    useCallback,
    useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentModal,
    setTrue,
    setFalse,
    toggle
} from "store/slices/toggle";

const useCurrentToggle = (modalName) => {
    if (!modalName) {
        throw new Error('To use useCurrentModal, please provide a modalName argument')
    }
    const { value } = useSelector(selectCurrentModal(modalName))
    const dispatch = useDispatch()

    const handleSetTrue = useCallback(() => {
        dispatch(setTrue({ stateName: modalName }))
    }, [dispatch, modalName])

    const handleSetFalse = useCallback(() => {
        dispatch(setFalse({ stateName: modalName }))
    }, [dispatch, modalName])

    const handleToggle = useCallback(() => {
        dispatch(toggle({ stateName: modalName }))
    }, [dispatch, modalName])

    return useMemo(() => ({
        value,
        setTrue: handleSetTrue,
        setFalse: handleSetFalse,
        toggle: handleToggle,
    }), [
        value,
        handleSetTrue,
        handleSetFalse,
        handleToggle,
    ])
}

export default useCurrentToggle
