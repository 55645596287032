import styled from "styled-components";

export const CycleDateAmountWrapper = styled.div`
height: 100%;
padding-bottom: 20px;
display: flex;
flex-direction: column;
justify-content: space-between;
`

export const DueDateContainer = styled.div`
font-weight: bold;
color: var(--ion-color-text-secondary);
margin-bottom: 16px;
font-size: 14px;
`

export const SpanAmountWidthRef = styled.span`
font-weight: bold;
font-size: 40px;
opacity: 0;
position: absolute;
`
