// Base path name should be the same as actual path in the rails API app.
export const ENROLLMENTS_BASE_PATH = 'enrollments'
export const USERS_BASE_PATH = 'users'
export const TRANSACTIONS_BASE_PATH = 'transactions'
export const USER_SETTINGS_BASE_PATH = 'user_settings'
export const USER_PROMOTION_CODES_BASE_PATH = 'user_promotion_codes'
export const BANK_ACCOUNTS_BASE_PATH = 'bank_accounts'
export const BRIDGEAPI_BANKS_BASE_PATH = 'bridgeapi_banks'
export const USER_CLIENTS_BASE_PATH = 'user_clients'
export const USER_PREFERENCES_BASE_PATH = 'user_preferences'
export const USER_DEVICES_BASE_PATH = 'user_devices'
export const SUBSCRIPTIONS_BASE_PATH = 'user_subscriptions'
export const USER_INVITATIONS_BASE_PATH = 'user_invitations'
export const DEBUGS_BASE_PATH = 'debugs'

// Internal use
export const TRANSACTION_INFO_BASE_PATH = 'transaction_info'
export const CLIENT_PAYMENT_BASE_PATH = 'pay'
