import styled from '@emotion/styled';
import {
    IonButton,
    IonMenu,
} from '@ionic/react';
import { HeaderLabel as H } from 'components/BaseLabels';

export const Wrapper = styled(IonMenu)`
margin-left: 38px;
`
export const HeaderLabel = styled(H)`
margin-bottom: 15px;
`

export const NewButton = styled(IonButton)`
align-self: center;

--border-radius: var(--ion-ntb-border-radius);
--box-shadow: var(--ion-ntb-box-shadow);

--background: var(--ion-color-primary-greeny-blue);
--background-activated: var(--ion-color-secondary-greeny-blue);
--background-hover: var(--ion-color-secondary-greeny-blue);

--background-hover-opacity: 0.5;

width: 146px;
height: 65px;
`

export const NewTextContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

export const NewText = styled.div`
font-size: 10px;
font-weight: var(--ion-font-weight-semi-bold);
`
