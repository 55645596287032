import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

const MAX_AMOUNT = 15000

const AmountFieldMaskOptions = {
    mask: Number,

    // other options are optional with defaults below
    scale: 2,  // digits after point, 0 for integers
    // signed: false,  // disallow negative
    // thousandsSeparator: '',  // any single char
    // padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
    // normalizeZeros: true,  // appends or removes zeros at ends
    radix: '.',  // fractional delimiter
    mapToRadix: ['.'],  // symbols to process as radix

    min: 0,
    max: 999999 // Actually it was limit to 15000, but we will force value later following MAX_AMOUNT
}

const AmountFieldMask = forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            {...AmountFieldMaskOptions}
            lazy={false}
            unmask={true}
            overwrite
            inputRef={ref}
            onAccept={(value, mask) => {
                if (parseInt(mask._unmaskedValue) > MAX_AMOUNT) {
                    onChange(MAX_AMOUNT.toString(), MAX_AMOUNT.toString())
                } else {
                    onChange(mask._unmaskedValue, mask._value)
                }
            }}
            step={0.01}
        />
    );
});

export default AmountFieldMask
