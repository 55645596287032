import { Link } from 'react-router-dom'
import styled from 'styled-components'

const styledGetColor = ({ color }) => `color: ${color ?? 'var(--ion-color-text-primary)'};`

const styledGetFontSize = ({ fontSize = "16px" }) => `font-size: ${fontSize}px;`

export const Container = styled(Link)`
${styledGetColor}
${styledGetFontSize}
text-decoration: none;
font-weight: 500;
`
