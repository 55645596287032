import { Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import BaseField from "components/fields/BaseField";
import HiddenSubmitButton from "components/HiddenSubmitButton";
import { decorateEmailString } from "helper/stringHelper";
import {
    EmailContainerTwo,
    ContainerCode,
    ButtonSendCode,
    PrimaryButton,
    EnterTheCode,
} from "./styled";
import VerificationInput from "react-verification-input";
import { useEffect, useState } from "react";

const Index = ({ submitButtonRef, checkCode, sendCode, codeErrors, isLoading }) => {
    const { t } = useTranslation("user");
    const { t: userT } = useTranslation("user");
    const { setFieldValue, values } = useFormikContext();
    const [code, setCode] = useState("");
    const [isCodeError, setIsCodeError] = useState(false);

    useEffect(() => {
        setIsCodeError(true);
        setCode("");
        setTimeout(() => setIsCodeError(false), 2000);
    }, [codeErrors]);

    useEffect(() => {
        if (code.length === 4) {
            checkCode({ code: code });
        }
    }, [code]);

    return (
        <Form className="first-form-control">
            <EmailContainerTwo>
                <BaseField
                    name="user.email"
                    label={t("email")}
                    onChange={(event) =>
                        setFieldValue("user.email", decorateEmailString(event.target.value))
                    }
                />
            </EmailContainerTwo>

            <HiddenSubmitButton ref={submitButtonRef} />
            <ContainerCode>
                <ButtonSendCode>
                    {!isLoading ?
                        (
                            <PrimaryButton
                                onClick={() => sendCode({ email: values.user.email })}
                            >
                                {userT("sign_up_page.sendCode")}
                            </PrimaryButton>
                        ) :
                        (
                            userT("sign_up_page.code_sent")
                        )}
                </ButtonSendCode>
            </ContainerCode>

            <EnterTheCode>{userT("sign_up_page.code")}</EnterTheCode>
            <ContainerCode>
                {!isCodeError ?
                    (
                        <VerificationInput
                            length={4}
                            validChars={"0-9"}
                            classNames={{
                                inputCode: "inputCode",
                            }}
                            onChange={(value) => setCode(value)}
                        />
                    )
                    :
                    (
                        userT("sign_up_page.code_invalid")
                    )
                }
            </ContainerCode>
        </Form>
    );
};

export default Index;
