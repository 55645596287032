import styled from 'styled-components';

export const Wrapper = styled.div`
font-size: 12px;
color: var(--ion-color-text-primary);
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
