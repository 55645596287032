import { withFormik } from 'formik'
import Form from './UserSignUpForm'
import { terms_and_conditions_accepted } from './validation'
import * as Yup from 'yup'
import { BaseFormikConfig } from 'forms/_baseForm'
import { email } from './validation'

const UserFormValidationSchema = Yup
   .object()
   .shape({
      user: Yup.object().shape({
         email,
         terms_and_conditions_accepted,
      })
   })

const UserFormikConfig = {
   ...BaseFormikConfig,
   mapPropsToValues: () => ({
      user: {
         email: '',
         terms_and_conditions_accepted: true
      }
   }),
   validationSchema: UserFormValidationSchema,
}


export default withFormik(UserFormikConfig)(Form)
