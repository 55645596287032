import dayjs from "dayjs"
import transactionsPathHelper from "helper/pathHelper/transactions"
import { useParams } from "react-router"
import { useDuplicateQuery } from "store/services/transactions"
import TransactionNewPage from "../TransactionNewPage"
import Spinner from 'components/FullScreenSpinner'

const compareDateIsPassed = (data) => {
    const execution_date = dayjs(data.transactions[0].execution_date).get('date')
    const current_date = dayjs().get('date')

    return current_date > execution_date
}

const generateTransactionFormData = (data) => {
    let transaction_form

    if (data) {
        let total_amount = 0
        let date_amounts = []
        const datePassed = compareDateIsPassed(data)

        for (let i = 0; i < data.transactions.length; i++) {
            const _transaction = data.transactions[i]

            // Calculate date
            let execution_date
            if (datePassed) {
                execution_date = dayjs().add((i * 2), 'week')
            } else {
                execution_date = dayjs(_transaction.execution_date).add(1, 'month')
            }

            // Add to list
            date_amounts.push({
                execution_date: execution_date,
                amount: _transaction.amount
            })

            // Calculate total amount
            total_amount = total_amount + parseFloat(_transaction.amount)
        }

        transaction_form = {
            full_name: data.transactions[0].full_name,
            user_client_id: data.user_client?.id,
            phone: data.user_client?.phone,
            email: data.user_client?.email,
            total_amount: total_amount.toString(),
            date_amounts: date_amounts
        }

        return transaction_form
    }
}

const TransactionDuplicatePage = () => {
    const { id } = useParams()
    const { duplicateTransactionsPath } = transactionsPathHelper(id)
    const result = useDuplicateQuery({
        url: duplicateTransactionsPath,
        id
    })
    const isFetching = result.isFetching

    const data = result?.data?.data
    const transaction_form = generateTransactionFormData(data)

    return (
        isFetching ?
            (
                <Spinner />
            ) : (
                <TransactionNewPage
                    duplicateData={transaction_form}
                />
            )
    )

}

export default TransactionDuplicatePage
