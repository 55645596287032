import styled from "styled-components";

export const NewTxPhoneInput = styled.input`
width: 100%;
font-size: 14px;
background: var(--ion-background-color);
border-radius: 15px;
height: 30px;
border-color: ${({ error }) => error ? 'var(--ion-color-text-phone-error);' : 'var(--ion-color-primary-greeny-blue)};'}
outline: 0;
&:focus-visible {
    border-color: var(--ion-color-primary-greeny-blue);
}
`
