import { UserSignUpForm } from "forms/usersForm";
import { useLocation } from "react-router-dom";
import useRedirectIfAlreadyAuth from "hooks/useRedirectIfAlreadyAuth";
import PageLayout from "layout/PageLayout";
import { useTranslation } from "react-i18next";
import PageContentWithAppLogo from "layout/PageContentWithAppLogo";
import useSubmitButtonRef from "hooks/useSubmitButtonRef";
import RequestErrorMessages from "components/RequestErrorMessages";
import {
  ContainerWithLabels,
  DetailContainer,
  NSDetailLabelTwo,
  NSTitleLabelDesktop,
  NSTitleLabelMobile,
} from "components/BaseLabels/index.js";
import PageContentSmaller from "layout/PageContentSmaller/index.js";
import { IonGrid, IonRow, IonCol, IonImg, isPlatform } from "@ionic/react";
import validateImage from "assets/images/validate_screen.png";
import { MobileEmailContainer } from "./styled";
import enrollmentsPathHelper from "helper/pathHelper/enrollments";
import {
  useSetEmailEnrollmentMutation,
  useVerifyEmailEnrollmentMutation,
} from "store/services/enrollments";
import {
  useSetUserInvitationMutation,
} from "store/services/userInvitations";
import { userApiInvitationsPathHelper } from "helper/pathHelper/userInvitations";
import {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import usersPathHelper from "helper/pathHelper/users";

const SignUpEmailPage = () => {
  const history = useHistory();
  useRedirectIfAlreadyAuth();
  //   get the params from the url
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.state?.enrollment?.id) {
      const { newUserRegistrationsPath } = usersPathHelper();
      history.push(newUserRegistrationsPath);
    }
  }, [state]);

  //   const [createUserRegistration, { isLoading, error }] =
  //     useSignUpUserMutation();
  const [submitButtonRef] = useSubmitButtonRef();
  const { t: userT } = useTranslation("user");
  const [setEmailEnrollment] = useSetEmailEnrollmentMutation();
  const [verifyEmailEnrollment, { error }] =
    useVerifyEmailEnrollmentMutation();
  const [setUserInvitation] = useSetUserInvitationMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [codeErrors, setCodeErrors] = useState(false);

  const sendCode = async (data) => {
    if (state.state.enrollment.id) {
      setIsLoading(true);
      const { setEmailEnrollmentPath } = enrollmentsPathHelper(
        state.state.enrollment.id
      );
      const e = await setEmailEnrollment({
        url: setEmailEnrollmentPath,
        body: { enrollment: { email: data.email } },
      });
      if (e?.error?.status === 422 && e?.error?.data?.[0] === "Email n'est pas disponible") {
        alert(userT("sign_up_page.email_unavailable"));
      }
      setTimeout(() => setIsLoading(false), 3000);
   } else {
        alert("Une erreur s'est produite ! Veuillez réessayer");
    }
  };

  const checkCode = async (data) => {
    if (state.state.enrollment.id) {
      const { verifyEmailEnrollmentPath } = enrollmentsPathHelper(
        state.state.enrollment.id
      );
      const e = await verifyEmailEnrollment({
        url: verifyEmailEnrollmentPath,
        body: { enrollment: { code: data.code } },
      });
      if (
        e?.data?.data?.mobile_verified === true &&
        e?.data?.data?.email_verified === true
      ) {
        const { createInvitationPath } = userApiInvitationsPathHelper();
        const response = await setUserInvitation({
          url: createInvitationPath,
          body: {
            user: {
              email: e.data.data.email,
              skip_invitation: true,
              terms_and_conditions_accepted: true,
              individual_attributes: {
                phone: e.data.data.mobile,
                phone_iso2: "fr"
              },
            },
          },
        });
        if (response.data.data.invitation_token) {
           window.location.replace(`/users/setup?invitation_token=${response.data.data.invitation_token}`);
        }
      } else if (e?.error?.status === '404' && e?.error?.data?.[0] === "Email n'est pas disponible") {
        alert(userT("sign_up_page.email_unavailable"));
      } else {
        setCodeErrors((previousValue) => previousValue + 1)
      }
    } else {
      alert(userT("sign_up_page.error"));
    }
  };

  let RenderImageContent;
  if (!isPlatform("mobile")) {
    RenderImageContent = (
      <IonCol sizeSm="6">
        <MobileEmailContainer>
          <IonImg src={validateImage} style={{ height: "720px" }} />
        </MobileEmailContainer>
      </IonCol>
    );
  }

  return (
    <PageLayout>
      <PageContentSmaller
        contentProps={{
          sizeLg: "9",
        }}
        withAreaFading={false}
      >
        <IonGrid>
          <IonRow>
            <IonCol sizeSm={!isPlatform("mobile") ? "6" : "12"}>
              <PageContentWithAppLogo
                body={
                  <>
                    <RequestErrorMessages error={error} />
                    <div className="text-margin">
                      <ContainerWithLabels>
                        <NSTitleLabelDesktop>
                          {userT("sign_up_page.address")}
                        </NSTitleLabelDesktop>

                        <NSTitleLabelMobile>
                          {userT("sign_up_page.address")}
                        </NSTitleLabelMobile>
                      </ContainerWithLabels>

                      <DetailContainer>
                        <NSDetailLabelTwo>
                          {userT(
                            "sign_up_page.enter_your_email_and_you_will_receive_message"
                          )}
                        </NSDetailLabelTwo>
                      </DetailContainer>
                    </div>

                    <UserSignUpForm
                      submitButtonRef={submitButtonRef}
                      sendCode={sendCode}
                      checkCode={checkCode}
                      isLoading={isLoading}
                      codeErrors={codeErrors}
                    />
                    {/* <AcceptTermContinue>
                      <PrimaryButton onClick={handleClick} disabled={isLoading}>
                        {appT("continue")}
                      </PrimaryButton>
                    </AcceptTermContinue> */}

                    {/* <TwoDots></TwoDots> */}
                  </>
                }
              />
            </IonCol>
            {RenderImageContent}
          </IonRow>
        </IonGrid>
      </PageContentSmaller>
    </PageLayout>
  );
};

export default SignUpEmailPage;
