import { SplitPane } from './styled'

const Index = ({
    children,
    ...rest
}) => {
    return <SplitPane {...rest}>
        {children}
    </SplitPane>
}

export default Index
