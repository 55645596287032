import {
    baseCreateResourceInvalidatesTags,
    baseResourceRequestTags,
    baseSplitApi,
} from './_base'

const userSettingsSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getUserSetting: builder.query({
            query: config => config,
            providesTags: baseResourceRequestTags
        }),
        getCompletionUserSetting: builder.query({
            query: config => config,
            providesTags: baseResourceRequestTags
        }),
        updateUserSetting: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
        updateRequestInfoUserSetting: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
        }),
    }),
})

export const {
    useGetUserSettingQuery,
    useGetCompletionUserSettingQuery,
    useUpdateUserSettingMutation,
    useUpdateRequestInfoUserSettingMutation,

} = userSettingsSlice
