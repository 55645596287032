import { PushNotifications } from '@capacitor/push-notifications';
import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { useCreateUserDeviceMutation } from 'store/services/userDevices';
import userDevicesPathHelper from 'helper/pathHelper/userDevices';
import useDevice from '../hooks/useDevice';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks/useModal';
import { PUSH_NOTIFICATION_TOGGLE_NAME } from 'constants/modalNameContants';
import PrimaryButton from 'components/PrimaryButton';
import {
    IconContainer,
    ContentContainer,
} from './styled.js'
import { DetailLabel, TitleLabel } from 'components/BaseLabels';
import Cancel from '@mui/icons-material/CancelOutlined'
import CheckCircle from "@mui/icons-material/CheckCircle"
const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

const PushNotification = () => {
    const { t } = useTranslation('appication')
    const {
        setDeviceToken,
        deviceToken,
    } = useDevice();

    const [notificationData, setNotificationData] = useState({
        // // Debug
        // body: "Le paiement de Anonyme CLIENTE de 4 € du 20/05/2022 avec la référence Test TETT 20 05 2022 3 a été validé.",
        // title: "Paiement Validé !",
        // status: "success", // success || failed
    })

    const { user } = useAuth();

    const [trigger,] = useCreateUserDeviceMutation();

    const { createUserDevicesPath } = userDevicesPathHelper();

    const {
        Modal,
        isOpen,
        show,
        hide,
    } = useModal(PUSH_NOTIFICATION_TOGGLE_NAME);

    useEffect(() => {
        // // Debug
        // show()
        // return hide

        // Do nothing if this devise is not support PushNotifications feature.
        if (!isPushNotificationsAvailable) return

        const register = () => {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();

            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration', (token) => {
                setDeviceToken(token.value)
            });

            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError', (error) => {
                throw new Error('Error on registration!' + JSON.stringify(error));
            });

            // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived', async (receivedData) => {
                /*
                {
                    body: "Le paiement de Anonyme CLIENTE de 4 € du 20/05/2022 avec la référence Test TETT 20 05 2022 3 a été validé."
                    data: {
                        status: 'success',
                        appearance: 'modal'
                    }
                    id: "0:1653040469121583%4e86559d4e86559d"
                    title: "Paiement Validé !"
                }
                */
                const { data: { status, appearance } } = receivedData
                if (appearance === 'modal') {
                    const { body, title } = receivedData
                    setNotificationData({
                        title,
                        body,
                        status,
                    })

                    // Show modal only if the modal is close.
                    if (!isOpen) {
                        show()
                    }
                }
            });

            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed', (receivedData) => {
                // Redirect the user to some path
                console.log('tapping on a notification', receivedData);
            });
        }

        const checkPermissions = async () => {
            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
                throw new Error('User denied permissions!');
            }

            register();
        }

        checkPermissions()
    }, [])

    useEffect(() => {
        if (deviceToken && user) {
            trigger({
                url: createUserDevicesPath,
                body: {
                    device_token: deviceToken,
                }
            })
        }
    }, [deviceToken, user])

    const {
        status,
        body,
        title,
    } = notificationData

    let RenderIcon
    if (status) {
        if (status === 'success') {
            RenderIcon = <CheckCircle style={{ fontSize: 40, color: 'var(--ion-color-primary-greeny-blue)' }} />
        } else if (status === 'failed') {
            RenderIcon = <Cancel style={{ fontSize: 40, color: 'var(--ion-color-danger)' }} />
        }
    }

    return (
        <Modal modalName={PUSH_NOTIFICATION_TOGGLE_NAME}>
            <div className='ion-text-center'>
                {
                    RenderIcon
                    &&
                    <IconContainer>
                        {RenderIcon}
                    </IconContainer>
                }

                <ContentContainer>
                    {
                        title
                        &&
                        <TitleLabel>
                            {title}
                        </TitleLabel>
                    }
                    {
                        body
                        &&
                        <DetailLabel>
                            {body}
                        </DetailLabel>
                    }
                </ContentContainer>

                <PrimaryButton onClick={hide}>
                    {t('ok')}
                </PrimaryButton>
            </div>
        </Modal>
    )
}

export default PushNotification
