import { ENROLLMENTS_BASE_PATH } from 'constants/routeConstants'

// Please visit ./_base.js for more information.

const enrollmentsPathHelper = (enrollment_id) => {
    return {
        // @Used for: internal
        preEnrollmentPath: `/${ENROLLMENTS_BASE_PATH}`,
        // @Used for: internal
        setEmailEnrollmentPath: `/${ENROLLMENTS_BASE_PATH}/${enrollment_id}/email_verification`,
        // @Used for: internal
        verifyEmailEnrollmentPath: `/${ENROLLMENTS_BASE_PATH}/${enrollment_id}/verify_email`,
        // @Used for: internal
        setPhoneEnrollmentPath: `/${ENROLLMENTS_BASE_PATH}/${enrollment_id}/mobile_verification`,
        // @Used for: internal
        verifyPhoneEnrollmentPath: `/${ENROLLMENTS_BASE_PATH}/${enrollment_id}/verify_mobile`,
    }
}

export default enrollmentsPathHelper;
