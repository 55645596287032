import { useTranslation } from 'react-i18next'
import BaseField from '../BaseField'

const BankCityField = (props) => {
    const { t } = useTranslation('bank_account')
    return (
        <BaseField
            placeholder={t('bank_city')}
            disabled
            {...props}
        />
    )
}

export default BankCityField
