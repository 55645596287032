import styled from 'styled-components';
import { IonCard, IonList } from '@ionic/react';

export const Wrapper = styled(IonList)``

const getContainerMarginInline = ({
    isFirstMarginInline,
    isSecondMarginInline,
}) => {
    if (isFirstMarginInline) {
        return '28px'
    }
    if (isSecondMarginInline) {
        return 'var(--ion-margin-home-page)'
    }
    return '28px'
}

export const Container = styled(IonCard)`
--background: none;
border-radius: 0;
box-shadow: unset;
margin-inline: ${getContainerMarginInline};
`
