
import { NSDetailLabel, NSTitleLabel } from "components/BaseLabels/index.js";
import PrimaryLink from "components/PrimaryLink";
import usersPathHelper from "helper/pathHelper/users";
import useRedirectIfAlreadyAuth from "hooks/useRedirectIfAlreadyAuth";
import PageContentSmaller from "layout/PageContentSmaller/index.js";
import PageContentWithAppLogo from "layout/PageContentWithAppLogo";
import PageLayout from "layout/PageLayout";
import { useTranslation } from "react-i18next";
import { CloseContainer } from './styled.js'

const SendForgotPasswordPage = () => {
  useRedirectIfAlreadyAuth()
  const { t: userT } = useTranslation('user')
  const { t: appT } = useTranslation('application')

  const { newUserSessionsPath } = usersPathHelper()

  return (
    <PageLayout>
      <PageContentSmaller>
        <PageContentWithAppLogo
          body={
            <>
              <div className="text-margin">
                <NSTitleLabel>
                  {userT('send_forgot_password_page.request_send')}
                </NSTitleLabel>

                <NSDetailLabel>
                  {userT('send_forgot_password_page.follow_instruction')}
                </NSDetailLabel>
              </div>

              <CloseContainer>
                <PrimaryLink to={newUserSessionsPath}>
                  {appT('close')}
                </PrimaryLink>
              </CloseContainer>
            </>
          }
        />
      </PageContentSmaller>
    </PageLayout>
  );
}

export default SendForgotPasswordPage;
