import { IonImg, IonItem, IonLabel } from '@ionic/react';
import Upload from '@mui/icons-material/Upload'
import { TitleLabel } from 'components/BaseLabels';
import IconWrapper from 'components/icons/IconWrapper';
import { useField } from 'formik';
import { useRef } from 'react';
// For more information: https://react-dropzone.js.org/#section-components
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  padding: 20px 0;
  border: none;
  background-color: transansparent;
  outline: none;
`;

const ImagePickerField = ({
    label,
    name,
}) => {
    const [,
        { value },
        { setValue }
    ] = useField(name)

    const objectUrlRef = useRef()

    const handleOnDropAccepted = (files) => {
        const file = files[0]
        // Revoke the data uris to avoid memory leaks if any.
        if (objectUrlRef.current) {
            URL.revokeObjectURL(objectUrlRef.current)
        }

        objectUrlRef.current = URL.createObjectURL(file)
        Object.assign(file, {
            preview: objectUrlRef.current,
        })
        setValue(file)
    }

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept: 'image/jpeg,image/png',
        onDropAccepted: handleOnDropAccepted
    });

    let imageUrl
    if (value?.preview) {
        imageUrl = value.preview
    } else if (value?.url) {
        imageUrl = value.url
    }

    return (
        <div className="container">
            {
                imageUrl && <IonImg class='ion-margin-top' src={imageUrl} alt="" />
            }

            <Container {...getRootProps()}>
                <input {...getInputProps()} />
                <IonItem lines='none'>
                    <IconWrapper marginInline='0' slot='start'>
                        <Upload style={{ fontSize: '32px', color: 'var(--ion-color-text-primary)' }} />

                    </IconWrapper>

                    <IonLabel>
                        <TitleLabel>
                            {label}
                        </TitleLabel>
                    </IonLabel>
                </IonItem>
            </Container>
        </div >
    );
}

export default ImagePickerField
