import { USER_PROMOTION_CODE_LATEST_TAG_TYPE } from 'constants/apiConstants'
import {
    baseSplitApi,
} from './_base'

export const userPromotionCodesSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getLatestUserPromotionCode: builder.query({
            query: config => config,
            keepUnusedDataFor: 0,
            providesTags: () => [USER_PROMOTION_CODE_LATEST_TAG_TYPE]
        }),
    }),
})

export const {
    useGetLatestUserPromotionCodeQuery
} = userPromotionCodesSlice
