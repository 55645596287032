import { DetailLabel, TitleLabel } from 'components/BaseLabels';
import styled from "styled-components"

export const ProgressContainer = styled.div`
padding: 17px;
display: flex;
gap: 15px;
`
export const ProgressCircularWrapper = styled.div`
padding: 10px 0;
`

const circularDimension = "65px"
export const ProgressCircularContainer = styled.div`
width: ${circularDimension};
height: ${circularDimension};
`

export const TextWrapper = styled.div`
display: flex;
flex-direction: column;
`

export const TitileContainer = styled(TitleLabel)`
flex: 1;
display: flex;
align-items: flex-end;
margin-left: 10px;
`

export const DetailContainer = styled(DetailLabel)`
display: flex;
justify-content: center;
`
