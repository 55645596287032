import {
    SUBSCRIPTIONS_BASE_PATH
} from 'constants/routeConstants'

// Please visit ./_base.js for more information.

const subscriptionsPathHelper = () => {
    return {
        showSubscriptionsPath: `/${SUBSCRIPTIONS_BASE_PATH}`,
        updateSubscriptionsPath: `/${SUBSCRIPTIONS_BASE_PATH}`,
        updateEndSubscriptionsPath: `/${SUBSCRIPTIONS_BASE_PATH}/update_end_subscription_date`,
    }
}

export default subscriptionsPathHelper
