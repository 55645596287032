import {
    TRANSACTION_LATEST_TAG_TYPE,
    TRANSACTION_LATEST_FAILED_FOR_TAG_TYPE,
    USER_CLIENTS_TAG_TYPE
} from 'constants/apiConstants'
import {
    baseCreateResourceInvalidatesTags,
    baseDeleteResourceInvalidatesTags,
    baseGetResourcesProvidesTags,
    baseResourceRequestTags,
    baseSplitApi
} from './_base'
import { createConsumer } from "@rails/actioncable"
import { selectCableURLWithToken } from 'store/slices/auth'

const transactionsApiSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getTransactions: builder.query({
            query: config => config,
            providesTags: baseGetResourcesProvidesTags
        }),
        getLatestPendingTransactions: builder.query({
            query: config => config,
            keepUnusedDataFor: 0,
            providesTags: baseGetResourcesProvidesTags
        }),
        getLatestSuccessTransactions: builder.query({
            query: config => config,
            keepUnusedDataFor: 0,
            providesTags: baseGetResourcesProvidesTags
        }),
        getLatestTransactions: builder.query({
            query: config => config,
            providesTags: () => [TRANSACTION_LATEST_TAG_TYPE],
            async onCacheEntryAdded(
                _arg,
                {
                    dispatch,
                    cacheDataLoaded,
                    cacheEntryRemoved,
                    getState,
                }
            ) {
                const cableURL = selectCableURLWithToken(getState())
                const actionCableRef = createConsumer(cableURL)
                try {
                    await cacheDataLoaded

                    actionCableRef.subscriptions.create(
                        {
                            channel: `${TRANSACTION_LATEST_TAG_TYPE}Channel`,
                        },
                        {
                            received: () => {
                                dispatch(baseSplitApi.util.invalidateTags([TRANSACTION_LATEST_TAG_TYPE]))
                            }
                        }
                    )
                } catch {
                }
                await cacheEntryRemoved
                actionCableRef.disconnect()
            },
        }),
        getLatestFailedForTransaction: builder.query({
            query: config => config,
            providesTags: () => [TRANSACTION_LATEST_FAILED_FOR_TAG_TYPE],
            async onCacheEntryAdded(
                _arg,
                {
                    cacheDataLoaded,
                    cacheEntryRemoved,
                    updateCachedData,
                    getState,
                }
            ) {
                const cableURL = selectCableURLWithToken(getState())
                const actionCableRef = createConsumer(cableURL)

                try {
                    await cacheDataLoaded

                    actionCableRef.subscriptions.create(
                        {
                            channel: `${TRANSACTION_LATEST_FAILED_FOR_TAG_TYPE}Channel`,
                        },
                        {
                            received: (transaction) => {
                                updateCachedData(() => transaction)
                            }
                        }
                    )
                } catch {
                }
                await cacheEntryRemoved
                actionCableRef.disconnect()
            },
        }),
        getTransaction: builder.query({
            query: config => config,
            providesTags: baseResourceRequestTags,
            async onCacheEntryAdded(
                arg,
                {
                    updateCachedData,
                    cacheDataLoaded,
                    cacheEntryRemoved,
                    getState,
                }
            ) {
                const cableURL = selectCableURLWithToken(getState())
                // create a websocket connection when the cache subscription starts
                const actionCableRef = createConsumer(cableURL)

                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded

                    actionCableRef.subscriptions.create(
                        {
                            channel: 'TransactionChannel',
                            id: arg.id,
                        },
                        {
                            received: transaction => {
                                updateCachedData(() => {
                                    // Since we know that received data will be exactly the same as 
                                    // current endpoint data so we can return it to the immer.
                                    // For more info: https://immerjs.github.io/immer/return Look at "adduser-3" case
                                    /*
                                    Example.
                                    current_data = {
                                        data: {
                                            id: 1
                                            name: "My name is Current",
                                            age: 12,
                                        },
                                        type: "User"
                                    }

                                    received_data = {
                                        data: {
                                            id: 1
                                            name: "My name is Received",
                                            age: 13,
                                        },
                                        type: "User"
                                    }
                                    */
                                    return transaction
                                })
                            }
                        }
                    )
                } catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                // cacheEntryRemoved will resolve when the cache subscription is no longer active
                await cacheEntryRemoved
                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                actionCableRef.disconnect()
            },
        }),
        createTransaction: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
            invalidatesTags: (result, _error, _arg) => {
                const res = baseCreateResourceInvalidatesTags(result, _error, _arg)
                if (res.length) res.push(USER_CLIENTS_TAG_TYPE)
                return res
            }
        }),
        editTransaction: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseResourceRequestTags
        }),
        deleteTransaction: builder.mutation({
            query: config => ({
                ...config,
                method: 'DELETE',
            }),
            invalidatesTags: baseDeleteResourceInvalidatesTags
        }),
        sendPaymentLink: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseGetResourcesProvidesTags
        }),
        reCreate: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseGetResourcesProvidesTags
        }),
        duplicate: builder.query({
            query: config => config,
            providesTags: baseResourceRequestTags
        }),
        inThisMonth: builder.query({
            query: config => config,
            providesTags: baseResourceRequestTags
        }),
    }),
})

export const {
    useLazyGetTransactionsQuery,
    useGetLatestTransactionsQuery,
    useGetLatestSuccessTransactionsQuery,
    useGetLatestPendingTransactionsQuery,
    useLazyGetLatestFailedForTransactionQuery,
    useGetTransactionQuery,
    useCreateTransactionMutation,
    useEditTransactionMutation,
    useDeleteTransactionMutation,
    useSendPaymentLinkMutation,
    useReCreateMutation,
    useDuplicateQuery,
    useInThisMonthQuery,
} = transactionsApiSlice
