import PrimaryBackLink from 'components/PrimaryBackLink'
import PrimaryButton from 'components/PrimaryButton'
import RequestErrorMessages from 'components/RequestErrorMessages'
import { TransactionInfoForm } from 'forms/transactionInfoForm'
import {
    isAnyStateIsLoading,
    findAnyErrorState,
} from 'helper/multipleStatesChecker'
import onErrorRedirect from 'helper/onErrorRedirect'
import userPreferencesPathHelper from 'helper/pathHelper/userPreferences'
import useSubmitButtonRef from 'hooks/useSubmitButtonRef'
import PageContent from 'layout/PageContent'
import PageFooter from 'layout/PageFooter'
import PageHeader from 'layout/PageHeader'
import PageLayout from 'layout/PageLayout'
import { useTranslation } from 'react-i18next'
import {
    useGetUserPreferencesQuery,
    useUpdateUserPreferencesMutation,
} from 'store/services/userPreferences'
import { serialize } from 'object-to-formdata';
import { useHistory } from 'react-router-dom'
import appPathHelper from 'helper/pathHelper/app'

const TransactionInfoIndexPage = () => {
    const history = useHistory()
    const { t } = useTranslation(['transaction_info', 'application'])
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const {
        showUserPreferencesPath,
        updateUserPreferencesPath,
    } = userPreferencesPathHelper()
    const { rootPath } = appPathHelper()

    const getState = useGetUserPreferencesQuery({ url: showUserPreferencesPath })
    const {
        data,
        isFetching,
        isSuccess,
    } = getState

    const [updateUserPreferecnes, updateState] = useUpdateUserPreferencesMutation()

    const states = [getState, updateState]
    const isLoading = isAnyStateIsLoading(states)
    const error = findAnyErrorState(states)

    const handleSubmit = async (data) => {
        const formData = serialize(data)
        try {
            await updateUserPreferecnes({
                url: updateUserPreferencesPath,
                body: formData
            }).unwrap()
            history.replace(rootPath)
        } catch (error) {
            console.error('TransactionInfoIndexPage#handleSubmit', error)
        }
    }

    let RenderContent
    if (isSuccess) {
        RenderContent = <TransactionInfoForm
            data={data.data}
            submitButtonRef={submitButtonRef}
            onSubmit={handleSubmit}
        />
    }

    if (onErrorRedirect(error)) return onErrorRedirect(error)

    return (
        <PageLayout>
            <PageHeader
                title={t('index_page.title')}
                withBackButton
            />

            <PageContent loading={isFetching}>

                <RequestErrorMessages error={error} />

                {RenderContent}

            </PageContent>

            <PageFooter>
                <div className='ion-margin-bottom'>
                    <PrimaryBackLink>
                        {t('cancel')}
                    </PrimaryBackLink>
                </div>

                <PrimaryButton
                    onClick={handleClick}
                    disabled={isLoading}
                >
                    {t('back_up')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
}

export default TransactionInfoIndexPage
