import styled from "styled-components";
import { IonItem, IonNote } from '@ionic/react'

const sActiveColor = ({ active }) => active && 'color: var(--ion-color-primary-greeny-blue);'

export const Container = styled(IonItem)`
color: var(--ion-color-text-primary);
${sActiveColor}
`

export const IconWrapper = styled(IonNote)`
min-width: 30px;
color: var(--ion-color-text-primary);
${sActiveColor}
`
