import styled from "styled-components";

export const ResendEmailContainer = styled.div`
margin-top: min(calc(2.24vh + 2.24vw), 40px);
font-size: 18px;
`

export const MobileEmailContainer = styled.div`
margin-top: 84px;
`
