import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const useLoadingAndSuccessToast = () => {
    const { t } = useTranslation('application')
    const [present, dismiss] = useIonToast()

    const showLoading = (message) => {
        let contentMessage = message ?? t('loading')
        present(contentMessage, 3000)
    }

    const showSuccess = async (message) => {
        await dismiss()
        let contentMessage = message ?? t('success')
        present(contentMessage, 3000)
    }

    return [showLoading, showSuccess, dismiss]
}

export default useLoadingAndSuccessToast
