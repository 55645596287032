import { useTranslation } from 'react-i18next'
import QueryButton from 'components/QueryButton'
import FilterList from '@mui/icons-material/FilterList'

const FilterButton = (props) => {
    const { t } = useTranslation('application')
    return (
        <QueryButton
            renderIcon={() => <FilterList style={{ fontSize: '32px', color: 'var(--ion-color-text-primary)' }} />}
            title={t('filter')}
            {...props}
        />
    )
}

export default FilterButton
