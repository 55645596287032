import { getBaseTextStyle } from "components/BaseLabels";
import styled from "styled-components";

export const DetailContainer = styled.div`
border: 5px solid var(--ion-color-primary-greeny-blue);
border-radius: var(--ion-border-radius-secondary);
`

export const DetailLabel = styled.span`
${getBaseTextStyle}
`
