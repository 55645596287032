import TransactionShowPage from "../TransactionShowPage";
import Success from "./Success";

const TransactionAfterCreatePage = () => (
    <TransactionShowPage
        SuccessComponent={Success}
    />
)

export default TransactionAfterCreatePage
