import {
    TRANSACTIONS_BASE_PATH
} from 'constants/routeConstants'
import basePathHelper from './_base'

// Please visit ./_base.js for more information.

const transactionsPathHelper = (id) => {
    return {
        ...basePathHelper(TRANSACTIONS_BASE_PATH, id),
        // @Used for: internal
        duplicateTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/${id}/duplicate`,
        showLatestTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/latest`,
        showLatestPendingTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/latest_pending`,
        showLatestSuccessTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/latest_success`,
        showLatestFailedForTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/latest_failed_for`,
        sendPaymentLinkTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/${id}/send_payment_link`,
        afterCreateTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/${id}/after_create`,
        reCreateTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/${id}/recreate`,
        inThisMonthTransactionsPath: `/${TRANSACTIONS_BASE_PATH}/in_this_month`,
    }
}

export default transactionsPathHelper
