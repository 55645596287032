import * as Yup from 'yup'

export const last_name = Yup.string().required("Le nom de famille doit être rempli.")

export const first_name = Yup.string().required("Le prénom doit être rempli.")

export const email = Yup
    .string()
    .email('L’e-mail doit être un e-mail valide.')
    .required('L’e-mail doit être rempli.')

export const terms_and_conditions_accepted = Yup.boolean()
