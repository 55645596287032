import { USER_PREFERENCES_BASE_PATH } from "constants/routeConstants"

const userPreferencesPathHelper = () => {
    return {
        showUserPreferencesPath: `/${USER_PREFERENCES_BASE_PATH}`,
        updateUserPreferencesPath: `/${USER_PREFERENCES_BASE_PATH}`,
        showNotificationUserPreferencesPath: `/${USER_PREFERENCES_BASE_PATH}/notification`,
        updateNotificationUserPreferencesPath: `/${USER_PREFERENCES_BASE_PATH}/update_notification`,
        singlePaymentPopupHiddenPath: `/${USER_PREFERENCES_BASE_PATH}/single_payment_popup_hidden`,
        updateSinglePaymentPopupHiddenPath: `/${USER_PREFERENCES_BASE_PATH}/update_single_payment_popup_hidden`,
        multiplePaymentsPopupHiddenPath: `/${USER_PREFERENCES_BASE_PATH}/multiple_payments_popup_hidden`,
        updateMultiplePaymentsPopupHiddenPath: `/${USER_PREFERENCES_BASE_PATH}/update_multiple_payments_popup_hidden`,
    }
}

export default userPreferencesPathHelper
