import dayjs from "dayjs"
import { useField } from "formik"
import { formatDate } from "helper/dateHelper"
import { useTranslation } from "react-i18next"
import { Container, Label, Wrapper } from "./styled"
import useCustomIonAlert from 'hooks/useCustomIonAlert'
import PromotionField from '../PromotionField'
import './style.scss'

export const tempSubscriptionSelectField = () => {
    return [
        {
            price: 10,
            flat_rate_transaction: 50,
            additional_transaction_price: 0.25,
            skip_monthly_price: false
        },
        {
            price: 20,
            flat_rate_transaction: 125,
            additional_transaction_price: 0.20,
            skip_monthly_price: false
        },
        {
            price: 1,
            is_percentage: true,
        },
        {
            price: 2,
            is_percentage: true,
        }
    ]
}

const SubscriptionItem = ({
    subscription,
    active,
    title,
    priceDetail,
    ...rest
}) => {
    const { t } = useTranslation('subscription')
    const flatRate = `${subscription.flat_rate_transaction} ${t('subscribe_page.receipts_included')}`
    const additionalTransactionPrice = `${subscription.additional_transaction_price.toFixed(2)}€ h.t. ${t('subscribe_page.per_additional_collection')}`

    return (
        <Wrapper
            {...rest}
            lines="none"
        >
            <Container active={active} >
                <Label
                    active={active}
                    fontSize={13}
                    bold
                >
                    {title}
                </Label>
                <Label
                    active={active}
                    fontSize={12}
                >
                    {priceDetail}
                </Label>
                <Label
                    active={active}
                    fontSize={12}
                >
                    {flatRate}
                </Label>
                <Label
                    active={active}
                    fontSize={12}
                >
                    {additionalTransactionPrice}
                </Label>
            </Container>
        </Wrapper >
    )
}

const SubscriptionPercentageItem = ({
    subscription,
    active,
    title,
    prePriceDetail,
    priceDetail,
    ...rest
}) => {
    const { t } = useTranslation('subscription')

    return (
        <Wrapper
            {...rest}
            lines="none"
        >
            <Container active={active} >
                <Label
                    active={active}
                    fontSize={13}
                    bold
                >
                    {title}
                </Label>

                {
                    prePriceDetail && (
                        <Label
                            active={active}
                            fontSize={12}
                        >
                            {prePriceDetail}
                        </Label>
                    )
                }

                <Label
                    active={active}
                    fontSize={12}
                >
                    {priceDetail}
                </Label>
            </Container>
        </Wrapper >
    )
}

const SubscriptionSelectField = ({
    isCreation,
    subscribed_at,
    name,
}) => {
    const { t } = useTranslation(['application', 'subscription'])

    const [, { value }, helper] = useField(name)

    const menuList = tempSubscriptionSelectField()
    const firstMenu = menuList[0]
    const secondMenu = menuList[1]
    const thirdMenu = menuList[2]
    const forthMenu = menuList[3]

    const toPricePerMounth = (price) => `${price} € h.t./mois`

    const [alertPresent] = useCustomIonAlert()
    const handleClick = (_value) => {
        const today = dayjs()
        const validDateToChange = today.startOf('month').add(4, 'day')
        if (isCreation) {
            helper.setValue(_value)
        } else if (today.isBefore(validDateToChange)) {
            helper.setValue(_value)
        } else if (value !== _value) {
            alertPresent({
                message: t('subscribe_page.alert_description_change_subscription_plan'),
                buttons: [t('close')],
                cssClass: 'subscription-custom-ion-alert'
            })
        }
    }

    return (
        <>
            <SubscriptionItem
                title={t('subscribe_page.discovery')}
                priceDetail={toPricePerMounth(firstMenu.price)}
                onClick={() => {
                    handleClick(1)
                }}
                subscription={firstMenu}
                active={value === 1}
            />

            <SubscriptionItem
                title={t('subscribe_page.classic')}
                priceDetail={toPricePerMounth(secondMenu.price)}
                onClick={() => {
                    handleClick(2)
                }}
                subscription={secondMenu}
                active={value === 2}
            />

            <SubscriptionPercentageItem
                title={t('subscribe_page.freedom')}
                priceDetail={`${thirdMenu.price}% h.t. par transaction`}
                onClick={() => {
                    handleClick(3)
                }}
                subscription={thirdMenu}
                active={value === 3}
            />

            <SubscriptionPercentageItem
                title={t('subscribe_page.private_individuals')}
                prePriceDetail="5 transactions gratuites par mois"
                priceDetail={`${forthMenu.price}% TTC par transaction ensuite`}
                onClick={() => {
                    handleClick(4)
                }}
                subscription={forthMenu}
                active={value === 4}
            />

            {
                isCreation && <PromotionField/>
            }
        </>
    )
}

export default SubscriptionSelectField
