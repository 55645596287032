import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import { TitileContainer } from './styled'
import { ResizeObserver } from 'resize-observer';

const PageTitle = ({
    toolbarRef,
    title,
    color = '--ion-color-text-primary',
    fontSize = '14px',
    ...rest
}) => {
    const observeRef = useRef()
    const titleRef = useRef()

    useEffect(() => {
        if (isEmpty(title) || isEmpty(toolbarRef.current) || isEmpty(titleRef.current)) {
            return
        }

        if (titleRef.current && !observeRef.current) {
            observeRef.current = true

            const resizeObserver = new ResizeObserver((event) => {
                // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
                const blockSize = event[0].contentRect.height
                const titleHeight = blockSize
                if (titleHeight) {
                    const defaultHeight = getComputedStyle(document.body).getPropertyValue('--ion-tool-bar-min-height')
                    const parsedDefaultHeight = parseFloat(defaultHeight)

                    if (titleHeight > parsedDefaultHeight) {
                        toolbarRef.current.style.setProperty('--min-height', `${titleHeight}px`);
                    }
                }
            });

            resizeObserver.observe(titleRef.current);
        }

    }, [title, toolbarRef.current, titleRef.current]);

    return (
        <TitileContainer
            color={color}
            fontSize={fontSize}
            {...rest}>
            <div className='page-title' ref={titleRef}>
                {title}
            </div>
        </TitileContainer>
    )
}

PageTitle.defaultProps = {
    color: '--ion-color-text-primary',
    fontSize: '14px',
}

export default PageTitle
