import { createSlice } from '@reduxjs/toolkit'
import { DEVICE_SLICE_NAME } from 'constants/sliceNameConstants'

const initialState = {
    uuid: null,
    token: null
}

const deviceSlice = createSlice({
    name: DEVICE_SLICE_NAME,
    initialState,
    reducers: {
        setDeviceUUID(
            state,
            { payload }
        ) {
            state.uuid = payload
        },
        setDeviceToken(
            state,
            { payload }
        ) {
            state.token = payload
        }
    },
})

export const {
    setDeviceUUID,
    setDeviceToken
} = deviceSlice.actions

export default deviceSlice

// UUID of Device or Browser.
export const selectCurrentDeviceUUID = (state) => state[DEVICE_SLICE_NAME].uuid
// Token to use for notification.
export const selectCurrentDeviceToken = (state) => state[DEVICE_SLICE_NAME].token
