import { createEntityAdapter } from "@reduxjs/toolkit"
import { TRANSACTIONS_BASE_PATH } from "constants/routeConstants"
import dayjs from 'dayjs'
import { formatDateSendToAPIHelper } from "helper/dateHelper"
import { toDescValue } from "helper/sortHelper"
import { baseSplitApi } from "store/services/_base"
import {
    baseInitialState,
    baseInitialSubState
} from "./_base"

export const transactionEntityAdapter = createEntityAdapter()

const oneMonthAgo = formatDateSendToAPIHelper(dayjs().subtract(30, 'day').startOf('month'))
const oneMonthLatter = formatDateSendToAPIHelper(dayjs().add(30, 'day').endOf('month'))

export const initialSubState = transactionEntityAdapter.getInitialState({
    ...baseInitialSubState,
    q: {
        execution_date_gteq: oneMonthAgo,
        execution_date_lteq: oneMonthLatter,
        s: toDescValue('execution_date')
    }
})

baseInitialState[TRANSACTIONS_BASE_PATH] = initialSubState

export const transactionsAddMatchers = (builder) => {
    builder.addMatcher(
        baseSplitApi.endpoints.getTransactions.matchFulfilled,
        (state, { payload }) => {
            const { data, pagy } = payload
            const currentPageState = state[TRANSACTIONS_BASE_PATH]
            if (currentPageState.shouldRefetch) {
                currentPageState.shouldRefetch = false
                transactionEntityAdapter.setAll(currentPageState, data || [])
            } else {
                transactionEntityAdapter.addMany(currentPageState, data || [])
            }
            currentPageState.hasNext = Boolean(pagy.next)
        }
    )
    builder.addMatcher(
        baseSplitApi.endpoints.createTransaction.matchFulfilled,
        (state) => {
            const currentPageState = state[TRANSACTIONS_BASE_PATH]
            currentPageState.shouldRefetch = true
            currentPageState.page = 1
        }
    )
}

export const transactionsSelectors = transactionEntityAdapter.getSelectors()
