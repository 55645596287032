import { ContainerWithLabels, NSDetailLabelTwo, NSTitleLabelDesktop, NSTitleLabelMobile } from 'components/BaseLabels';
import styled from "styled-components"
import BaseField from 'components/fields/BaseField';
import PasswordField from 'components/inputs/PasswordField';
import { useTranslation } from 'react-i18next'

export const DetailLabelContainer = styled.div`
margin-top: min(calc(6.72vh + 2.24vw), 80px);
`

export const InvisibleContainer = styled.div`
position: absolute;
`

export const InvisibleInputField = styled.input`
border: 0;
padding: 0;
height: 0;
`


const Index = () => {
    const { t: indivT } = useTranslation('individual')
    const { t: userT } = useTranslation('user')
    return (
        <>
            <ContainerWithLabels>
                <NSTitleLabelDesktop>
                    {userT('setup_sign_up_page.create_your_account')}
                </NSTitleLabelDesktop>
                
                <NSTitleLabelMobile>
                    {userT('setup_sign_up_page.create_your_account')}
                </NSTitleLabelMobile>

                <DetailLabelContainer>
                    <NSDetailLabelTwo>
                        {userT('setup_sign_up_page.fill_information')}
                    </NSDetailLabelTwo>
                </DetailLabelContainer>
            </ContainerWithLabels>

            <BaseField
                name='user.individual_attributes.first_name'
                placeholder={indivT('first_name')}
            />

            <BaseField
                name='user.individual_attributes.last_name'
                placeholder={indivT('last_name')}
            />

            <InvisibleContainer>
                <InvisibleInputField
                    tabIndex="-1"
                ></InvisibleInputField>
            </InvisibleContainer>

            <PasswordField
                name='user.password'
                placeholder={userT('set_password')}
            />
        </>
    )
}

export default Index
