import { useTranslation } from 'react-i18next'
import { FieldArray } from 'formik'
import { IbanField } from 'components/fields/bankAccounts';
import { Fragment } from 'react';
import { ContainerWithLabels, NSDetailLabel, NSTitleLabelDesktop, NSTitleLabelMobile } from 'components/BaseLabels';
import {
    BankAppendix, BankAppendixContain, BankAppendixContainMobile, BankAppendixLabel, BankAppendixLabelMobile, SecurityIconContainer, SecurityIconContainerMobile
} from './styled'
import {
    BankNameField,
    BankCityField,
} from 'components/fields/bankAccounts';
import { DetailLabelContainer } from '../UserInfoFields';
import SecurityIcon from 'assets/images/security_icon.png'
import { IonImg } from '@ionic/react';

const Index = ({ values }) => {
    const { t: userT } = useTranslation('user')
    const { t: bankAt } = useTranslation('bank_account')

    return (
        <>
            <ContainerWithLabels>
                <NSTitleLabelDesktop>
                    {userT('setup_sign_up_page.enter_iban')}
                </NSTitleLabelDesktop>

                <NSTitleLabelMobile>
                    {userT('setup_sign_up_page.enter_iban')}
                </NSTitleLabelMobile>

                <DetailLabelContainer>
                    <NSDetailLabel>
                        {userT('setup_sign_up_page.enter_iban_detail')}
                    </NSDetailLabel>
                </DetailLabelContainer>
            </ContainerWithLabels>

            <FieldArray name='user.bank_accounts_attributes'>
                {
                    ({ name }) => {
                        return values.user.bank_accounts_attributes.map((_, index) => {
                            return (
                                <Fragment key={index}>

                                    <IbanField
                                        autoHideKeyboard
                                        placeholder={bankAt('iban')}
                                        name={`${name}.${index}.iban`}
                                    />

                                    <div className='ion-margin-top'>
                                        <BankNameField
                                            name={`${name}.${index}.bank_name`}
                                        />
                                    </div>

                                    <div className='ion-margin-top'>
                                        <BankCityField
                                            name={`${name}.${index}.bank_city`}
                                        />
                                    </div>
                                </Fragment>
                            )
                        })
                    }
                }
            </FieldArray>

            <BankAppendix>
                <BankAppendixContain>
                    <SecurityIconContainer>
                        <IonImg src={SecurityIcon} style={{ height:"60px" }}></IonImg>
                    </SecurityIconContainer>

                    <BankAppendixLabel>
                        {userT('setup_sign_up_page.bank_appendix', { link: 'www.regafi.fr' })}
                    </BankAppendixLabel>
                </BankAppendixContain>

                <BankAppendixContainMobile>
                    <SecurityIconContainerMobile>
                        <IonImg src={SecurityIcon} style={{ height:"60px" }}></IonImg>
                    </SecurityIconContainerMobile>

                    <BankAppendixLabelMobile>
                        {userT('setup_sign_up_page.bank_appendix', { link: 'www.regafi.fr' })}
                    </BankAppendixLabelMobile>
                </BankAppendixContainMobile>
            </BankAppendix>
        </>
    )
}

export default Index
