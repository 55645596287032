import PropTypes from 'prop-types'
import { Container } from './styled'

const Spinner = ({
    size = 35,
    ...rest
}) => {
    return (
        <Container
            size={size}
            {...rest}
        />
    )
}

Spinner.propTypes = {
    size: PropTypes.number,
}

export default Spinner
