import { BANK_ACCOUNTS_BASE_PATH } from 'constants/routeConstants'
import basePathHelper from './_base'

const bankAccountsPathHelper = (id) => {
    return {
        ...basePathHelper(BANK_ACCOUNTS_BASE_PATH, id),
        updatePrimaryBankAccountsPath: `/${BANK_ACCOUNTS_BASE_PATH}/${id}/update_primary`
    }
}

export default bankAccountsPathHelper
