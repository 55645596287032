import {
    AppLogoContainer,
    AppLogoContainerMobile,
    BodyContainer,
    Container,
    HeaderContainer,
} from './styled'
import PropTypes from 'prop-types'
import AppLogo from 'components/AppLogo';
import { useHistory } from 'react-router';
import debugsPathHelper from 'helper/pathHelper/debugs';
import { useState } from 'react';

const PageContentWithAppLogo = ({
    body,
    logoSize,
    ...rest
}) => {

    /**
     * DEBUG MODE!
     * Dont forget to remove code here when deploy to production
     */
    const history = useHistory()
    const { indexDebugsPath } = debugsPathHelper()
    const [debugCount, setDebugCount] = useState(0)
    const handleEnterDebug = () => {
        if (debugCount < 4) {
            setDebugCount(debugCount + 1)
        } else {
            history.push(indexDebugsPath)
        }
    }


    return (
        <Container {...rest}>
            <HeaderContainer
                {...rest}
                onClick={handleEnterDebug}
            >
                <AppLogoContainer>
                    <AppLogo
                        size={logoSize}
                    />
                </AppLogoContainer>

                <AppLogoContainerMobile>
                    <AppLogo
                        size="60"
                    />
                </AppLogoContainerMobile>
            </HeaderContainer>

            <BodyContainer {...rest}>
                {body}
            </BodyContainer>
        </Container>
    )
};

PageContentWithAppLogo.propTypes = {
    pageName: PropTypes.string,
}

export default PageContentWithAppLogo;
