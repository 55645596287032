import { Form, useField, useFormikContext } from 'formik'
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import BaseField from 'components/fields/BaseField';
import { useTranslation } from 'react-i18next';
import { decorateEmailString } from 'helper/stringHelper';
import { useEffect, useRef } from 'react';
import userSettingsPathHelper from 'helper/pathHelper/userSettings';
import { useGetUserSettingQuery } from 'store/services/userSettings';
import IntlPhoneInput from 'components/inputs/IntlPhoneField';
import { UserClientPhoneInput } from 'components/userClients/PhoneInput/styled';

const Index = ({ submitButtonRef }) => {
    const { t } = useTranslation('user_client')
    const { setFieldValue } = useFormikContext();
    const intlRef = useRef()

    // Initial phone_iso2 incase of create user client
    const { indexUserSettingsPath } = userSettingsPathHelper()
    const { data } = useGetUserSettingQuery({ url: indexUserSettingsPath })

    const countryFieldName = 'user_client.phone_iso2'
    const [, meta,] = useField(countryFieldName)
    const { value } = meta

    useEffect(() => {
        // return if it's show's form, not new's form
        if (value) return

        if (data && intlRef.current) {
            const individual = data.data.individual
            const phone_iso2 = individual.phone_iso2
            intlRef.current.setCountry(phone_iso2);
        }
    }, [value, data, intlRef.current])

    return (
        <Form className='first-form-control first-form-control--second-input-margin'>

            <BaseField
                placeholder={t('first_name')}
                name='user_client.first_name'
            />

            <BaseField
                placeholder={t('last_name')}
                name='user_client.last_name'
            />

            <BaseField
                placeholder={t('email')}
                name='user_client.email'
                onChange={event => setFieldValue('user_client.email', decorateEmailString(event.target.value))}
            />

            <IntlPhoneInput
                Component={UserClientPhoneInput}
                name='user_client.phone'
                countryName={countryFieldName}
                placeholder={t('phone')}
                intlRef={intlRef}
                variant="standard"
            />

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
