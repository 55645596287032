import { Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import BaseField from 'components/fields/BaseField';
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import PasswordField from 'components/inputs/PasswordField';
import { decorateEmailString } from 'helper/stringHelper';
import { EmailContainer, PasswordContainer } from './styled';

const Index = ({ submitButtonRef }) => {

    const { t } = useTranslation('user')
    const { setFieldValue } = useFormikContext();

    return (
        <Form className='first-form-control'>
            <EmailContainer>
                <BaseField
                    name='user.email'
                    placeholder={t('login_email')}
                    onChange={event => setFieldValue('user.email', decorateEmailString(event.target.value))}
                />
            </EmailContainer>

            <PasswordContainer>
                <PasswordField
                    name='user.password'
                    placeholder={t('password')}
                />
            </PasswordContainer>

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
