import Spinner from "components/Spinner"
import clientPaymentPathHelper from "helper/pathHelper/clientPayment"
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper"
import useRedirectToInitPayment from "hooks/useRedirectToInitPayment"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo"
import PageLayout from "layout/PageLayout"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useGetShowToClientTransactionQuery } from "store/services/clientPayments"
import { Browser } from '@capacitor/browser';
import { ContainerWithLabels, DetailContainerThree, NSDetailLabel, NSTitleLabel } from "components/BaseLabels"
import PageContentSmaller from "layout/PageContentSmaller"
import usePerPlatformTranslation from "hooks/usePerPlatformTranslation";

const ClientWaitingForPaymentLinkPage = () => {
    useRedirectToInitPayment()
    const [refetchInterval, setRefetchInterval] = useState(0);
    const { t } = usePerPlatformTranslation('client_payment');

    const uuid = getURLSearchParamsHelper('uuid')
    const {
        showTransactionToClientPath
    } = clientPaymentPathHelper(uuid)

    const {
        data,
        isFetching,
    } = useGetShowToClientTransactionQuery(
        {
            url: showTransactionToClientPath
        },
        {
            pollingInterval: refetchInterval
        })

    // @note For some senarios payment_link could be present even before this page loaded
    // so we will use polling to check for the link.
    useEffect(() => {
        const openCapacitorSite = async () => {
            await Browser.open({ url: data.data.payment_link });
        };

        data?.data?.payment_link && openCapacitorSite()

        setRefetchInterval(data?.data?.payment_link ? 0 : 3000)

        return () => setRefetchInterval(0)
    }, [data?.data?.payment_link])

    let RenderContent
    if (data?.data?.payment_link) {
        RenderContent = <>
            <ContainerWithLabels>
                <NSTitleLabel>
                    {t('waiting_for_payment_link_page.opening_link')}
                </NSTitleLabel>
            </ContainerWithLabels>

            <DetailContainerThree>
                <NSDetailLabel>
                    {t('waiting_for_payment_link_page.you_could_safely_close')}
                </NSDetailLabel>

                <NSDetailLabel>
                    {t('waiting_for_payment_link_page.open_link_manually')}
                </NSDetailLabel>

                <Link
                    to={{ pathname: data.data.payment_link }}
                    target='_blank'
                >
                    {data.data.payment_link}
                    {/* https://www.google.com */}
                </Link>
            </DetailContainerThree>

        </>
    } else {
        RenderContent = <>
            <ContainerWithLabels>
                <NSTitleLabel>
                    {t('waiting_for_payment_link_page.first_getting_link_message')}
                </NSTitleLabel>
            </ContainerWithLabels>

            <DetailContainerThree>
                <NSTitleLabel className="ion-margin-bottom">
                    {t('waiting_for_payment_link_page.second_getting_link_message')}
                </NSTitleLabel>

                <Spinner />
            </DetailContainerThree>
        </>
    }

    return (
        <PageLayout>
            <PageContentSmaller loading={isFetching}>
                <PageContentWithAppLogo
                    pageName='clientPayment'
                    body={
                        <div className="text-margin">
                            {RenderContent}
                        </div>
                    }
                />
            </PageContentSmaller>
        </PageLayout>
    )
}

export default ClientWaitingForPaymentLinkPage
