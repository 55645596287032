import { IonFooter, IonToolbar } from '@ionic/react';
import styled from "styled-components";

const styledCPaddingBottom = ({ paddingBottom }) => paddingBottom ?? "70px"

const styledCBackgroundColor = ({ backgroundColor }) => backgroundColor ?? 'var(--ion-background-color)'

const fadingContainerSize = '30px'

const styledCFading = ({ fading }) => {
    return fading ? `&:before {
    content: '';
    position: absolute;
    top: -${fadingContainerSize};
    left: 0;
    width: 100%;
    text-align: center; 
    margin: 0;
    padding: ${fadingContainerSize} 0;
    background-image: linear-gradient(180deg, #FFFFFF00, #FFFFFF, #FFFFFF);
}` : ''
}

export const SafeAreaFading = styled.div`
height: ${fadingContainerSize};
`

export const Wrapper = styled(IonFooter)`
${styledCFading}
`

export const Container = styled(IonToolbar)`
--padding-top: 10px;
--padding-bottom: ${styledCPaddingBottom};
text-align: center;
--background: none;
background-color: ${styledCBackgroundColor} !important;
`
