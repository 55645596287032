import { useEffect, useRef } from "react"

const useAfterFirstMountedEffect = (fn, deps) => {
    const isMountedRef = useRef()

    useEffect(() => {
        if (!isMountedRef.current) {
            isMountedRef.current = true
            return
        }
        fn()
    }, deps)
}

export default useAfterFirstMountedEffect
