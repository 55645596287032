import {
    IonThumbnail
} from '@ionic/react';
import styled from "styled-components"

const styledGetMarginInline = ({ marginInline }) => marginInline ?? '0 10px'

const IconWrapper = styled(IonThumbnail)`
display: flex;
align-items: center;
height: 100%;
margin-inline: ${styledGetMarginInline};

& > svg {
    display: block;
    margin: 0 auto;
    text-align: center;
}
`
export default IconWrapper
