import { HOME_TAB_NAME } from "constants/appConstants"

// Please visit ./_base.js for more information.
const appPathHelper = () => {
    return {
        // @Used for: internal
        rootPath: `/${HOME_TAB_NAME}`,
        // @Used for: internal
        pageNotFoundPath: '/page_not_found',
    }
}

export default appPathHelper
