import { Form, useFormikContext, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import { CheckboxFieldContainer, CssIconButton, CycleDateAmountContainer } from './styled';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import UserClientField2 from 'components/fields/transactions/UserClientField/UserClientField2';
import MainFieldAndSubButtonContainer from 'components/MainFieldAndSubButtonContainer';
import CycleDateAmountField from 'components/fields/transactions/CycleDateAmountField/CycleDateAmountField';
import CheckboxField from 'components/transactions/Checkbox';
import UserClientPath from './UserClientPath';
import HiddenSubmitButton from 'components/HiddenSubmitButton';

const Index = ({ submitButtonRef }) => {
    const { t } = useTranslation(['transaction', 'application', 'user_client'])
    const { values, setFieldValue } = useFormikContext();

    const submit_new_client_form = getIn(values, 'submit_new_client_form')

    return (
        <>
            <Form>
                <div className='new-transaction-form-control new-transaction-form-control--first-input-margin'>
                    {
                        !submit_new_client_form && (
                            <MainFieldAndSubButtonContainer
                                MainField={UserClientField2}
                                SubButton={() => (
                                    <CssIconButton
                                        onClick={() => setFieldValue('submit_new_client_form', true)}
                                    >
                                        <PersonAddAltIcon style={{ color: 'var(--ion-color-primary-contrast)', fontSize: '35px' }} />
                                    </CssIconButton>
                                )}
                            />
                        )
                    }

                    {
                        submit_new_client_form && (  
                            <UserClientPath />
                        )
                    }

                    <CheckboxFieldContainer>
                        <CheckboxField
                            name='transaction_form.clear_bank'
                            label={t('new_page.force_the_choice_of_bank')}
                        />
                    </CheckboxFieldContainer>
                </div>

                <CycleDateAmountContainer>
                    <div className='new-tx-second-form-control first-form-control--second-input-margin'>
                        <CycleDateAmountField />
                    </div>
                </CycleDateAmountContainer>

                <HiddenSubmitButton
                    ref={submitButtonRef}
                />
            </Form>
        </>
    )
}

export default Index
