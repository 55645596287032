import styled from "styled-components";

export const WrapperAsterisk = styled.div`
&:after {
    position: absolute;
    content: " *";
    color: red;
    right: -19px;
    font-size: 29px;
}
`
