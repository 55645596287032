import clientPaymentPathHelper from "helper/pathHelper/clientPayment";
import transactionsPathHelper from "helper/pathHelper/transactions";
import usersPathHelper from "helper/pathHelper/users";
import useApplication from "hooks/useApplication";
import { useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";

const SideMenuVisibilityOnPage = () => {
  const { setIsSideMenuVisible } = useApplication();

  const {
    preUserRegistrationsPath,
    newUserRegistrationsPath,
    emailUserRegistrationsPath,
    afterUserRegistrationsPath,
    setupUserRegistrationsPath,
    newUserSessionsPath,
    afterSetupUserRegistrationsPath,
    forgotUserPasswordPath,
    sendForgotUserPasswordPath,
    resetUserPasswordPath,
  } = usersPathHelper();

  const { newTransactionsPath } = transactionsPathHelper();

  const {
    showClientAcceptPaymentPath,
    showClientSelectBankPath,
    showExplainClientSelectedBankPath,
    showClientWaitingForLinkPath,
    showClientPaymentSuccessPath,
    showClientPaymentErrorPath,
  } = clientPaymentPathHelper();

  const blacklistPaths = [
    // User paths start
    preUserRegistrationsPath,
    newUserRegistrationsPath,
    emailUserRegistrationsPath,
    afterUserRegistrationsPath,
    setupUserRegistrationsPath,
    newUserSessionsPath,
    afterSetupUserRegistrationsPath,
    forgotUserPasswordPath,
    sendForgotUserPasswordPath,
    resetUserPasswordPath,
    // User paths start
    // Transaction paths start
    newTransactionsPath,
    "/transactions/:id/duplicate",
    // Transaction paths end
    // Client payment paths start
    showClientAcceptPaymentPath,
    showClientSelectBankPath,
    showExplainClientSelectedBankPath,
    showClientWaitingForLinkPath,
    showClientPaymentSuccessPath,
    showClientPaymentErrorPath,
    // Client payment paths end
  ];

  const { pathname } = useLocation();

  useEffect(() => {
    const isInBlacklist = blacklistPaths.some((blacklistPath) => {
      return matchPath(pathname, {
        path: blacklistPath,
        exact: true,
        strict: false, // Allow '/' at the end of the path. eg. '/transactions/12/'
      });
    });
    // Side menu should be visible only if current path is not in the backlist path.
    setIsSideMenuVisible(!isInBlacklist);
  }, [pathname]);

  return null;
};

export default SideMenuVisibilityOnPage;
