import { Form } from 'formik';
import styled from "styled-components";

export const Container = styled(Form)`
display: flex;
flex-direction: column;
flex-grow: 1;
justify-content: center;
`
export const SubscriptionContainer = styled.div`
margin-top: min(calc(1.12vh + 1.12vw), 20px);
`
