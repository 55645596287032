import { withFormik } from 'formik'
import Form from './UserSignUpPhoneForm'
import { terms_and_conditions_accepted } from './validation'
import * as Yup from 'yup'
import { BaseFormikConfig } from 'forms/_baseForm'
import { phone } from './validation'

const UserFormValidationSchema = Yup
    .object()
    .shape({
       user: Yup.object().shape({
          phone,
          terms_and_conditions_accepted,
       })
    })

const UserFormikConfig = {
   ...BaseFormikConfig,
   mapPropsToValues: () => ({
      user: {
         phone: '',
         terms_and_conditions_accepted: false
      }
   }),
   validationSchema: UserFormValidationSchema,
}


export default withFormik(UserFormikConfig)(Form)
