import { isValidIBAN } from 'ibantools'
import * as Yup from 'yup'

export function validateIban(iban) {
    return isValidIBAN(iban) && iban.startsWith("FR")
}

export const iban = Yup
    .string()
    .required("L’IBAN doit être rempli.")
    .test('valid_iban', "L’IBAN est invalide.", validateIban)

export const bank_name = Yup.string()

export const bank_account_name = Yup.string()

export const bank_city = Yup.string()

export const bank_country = Yup.string()
