import { SEARCH_TRANSACTIONS_TOGGLE_NAME } from "constants/modalNameContants"
import { useModal } from "hooks/useModal"
import { TRANSACTIONS_BASE_PATH } from 'constants/routeConstants';
import { useTranslation } from 'react-i18next';
import { formatDateSendToAPIHelper } from 'helper/dateHelper';
import DatePicker from 'components/inputs/DateTimePicker';
import useCurrentIndexPageConfig from 'hooks/useCurrentIndexPageConfig';
import { formatDate } from 'helper/dateHelper';

const SearchModal = () => {
    const { t } = useTranslation('application')
    const {
        Modal,
    } = useModal(SEARCH_TRANSACTIONS_TOGGLE_NAME)
    const [pagesConfig, { setSearch }] = useCurrentIndexPageConfig(TRANSACTIONS_BASE_PATH)

    const { q: {
        execution_date_gteq,
        execution_date_lteq,
    }
    } = pagesConfig

    const handleDateChange = (key, value) => {
        setSearch(key, formatDateSendToAPIHelper(value))
    }

    return (
        <Modal modalName={SEARCH_TRANSACTIONS_TOGGLE_NAME}>
            <DatePicker
                label={t('start_at')}
                value={formatDate(execution_date_gteq)}
                onChange={(acceptedValue) => {
                    handleDateChange('execution_date_gteq', acceptedValue)
                }}
            />

            <DatePicker
                label={t('end_at')}
                value={formatDate(execution_date_lteq)}
                onChange={(acceptedValue) => {
                    handleDateChange('execution_date_lteq', acceptedValue)
                }}
            />
        </Modal>
    )
}

export default SearchModal
