import ContentList from 'components/ContentList';
import PageContent from 'layout/PageContent';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import MainPageFooter from 'layout/MainPageFooter';
import SignOutButton from 'components/SignOutButton';
import { useTranslation } from 'react-i18next';
import SettingMenuItems from './SettingMenuItems';

const SettingsPage = () => {
    const { t } = useTranslation('setting');

    return (
        <PageLayout>
            <PageHeader
                title={t("title")}
                isSideTitle
                action={<SignOutButton />}
            />

            <PageContent>
                <ContentList containerProps={{ isSecondMarginInline: true }}>
                    <SettingMenuItems />
                </ContentList>
            </PageContent>

            <MainPageFooter>
            </MainPageFooter>
        </PageLayout>
    );
};

export default SettingsPage;
