import styled from "styled-components";
import { IonItem } from '@ionic/react';

const styledMarginBottom = ({ marginBottom }) => marginBottom ?? '15px'

export const Container = styled(IonItem)`
--padding-start: 10px;
--color: var(--ion-color-text-primary);
--background: var(--ion-color-text-secondary);

margin-bottom: ${styledMarginBottom};

${({ withBorder }) => withBorder ? '--border-radius: 6px;' : ''}
`
