import { ReactComponent as Logo } from 'components/icons/logo.svg';

const AppLogo = ({ size = '72px' }) => {
    return <Logo
        width='100%'
        height={size}
    />
}

export default AppLogo
