import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from './SubscriptionForm'
import { BaseFormikConfig, id } from 'forms/_baseForm'

const FormValidationSchema = Yup
    .object()
    .shape({
        user_subscription: Yup.object().shape({
            plan_id: id,
        })
    })

const FormikConfig = {
    ...BaseFormikConfig,
    mapPropsToValues: ({ data }) => ({
        user_subscription: {
            plan_id: data?.plan_id ?? ''
        }
    }),
    validationSchema: FormValidationSchema,
}

export default withFormik(FormikConfig)(Form)
