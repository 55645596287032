import PrimaryButton from 'components/PrimaryButton';
import PrimaryLink from 'components/PrimaryLink';
import RequestErrorMessages from 'components/RequestErrorMessages';
import { UserClientForm } from 'forms/userClientsForm';
import { findAnyErrorState, isAnyStateIsLoading } from 'helper/multipleStatesChecker';
import onErrorRedirect from 'helper/onErrorRedirect';
import userClientsPathHelper from 'helper/pathHelper/userClients';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import PageContent from 'layout/PageContent';
import PageFooter from 'layout/PageFooter';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import {
  useGetUserClientQuery,
  useUpdateUserClientMutation,
  useDestroyUserClientMutation,
} from 'store/services/userClients';

const UserClientShowPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const {
    showUserClientsPath,
    indexUserClientsPath,
    deleteUserClientsPath,
    updateUserClientsPath,
  } = userClientsPathHelper(id)

  const queryState = useGetUserClientQuery({ url: showUserClientsPath })
  const {
    data,
    isSuccess,
    isFetching,
  } = queryState

  const [updateUserClient, updateState] = useUpdateUserClientMutation()
  const [destroyUserClient, destroyState] = useDestroyUserClientMutation()

  const states = [queryState, destroyState, updateState]

  const isLoading = isAnyStateIsLoading(states)
  const error = findAnyErrorState(states)

  const [submitButtonRef, handleClick] = useSubmitButtonRef()
  const { t } = useTranslation(['user_client', 'application'])

  const handleSubmit = async (data) => {
    try {
      await updateUserClient({
        url: updateUserClientsPath,
        body: data
      }).unwrap()
    } catch (error) {
      console.error('UserClientShowPage#handleSubmit', error);
    }
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await destroyUserClient({ url: deleteUserClientsPath }).unwrap()
      history.replace(indexUserClientsPath)
    } catch (error) {
      console.error('UserClientShowPage#handleDelete', error);
    }
  }

  let RenderContent
  if (isSuccess) {
    RenderContent = <UserClientForm
      data={data.data}
      submitButtonRef={submitButtonRef}
      onSubmit={handleSubmit}
    />

  }
  if (onErrorRedirect(error)) return onErrorRedirect(error)

  return (
    <PageLayout>
      <PageHeader
        title={t('show_page.title')}
        withBackButton
        backTo={indexUserClientsPath}
      />

      <PageContent loading={isFetching}>
        <RequestErrorMessages error={error} />

        {RenderContent}
      </PageContent>

      <PageFooter>
        <div className='ion-margin-bottom'>
          <PrimaryLink
            to='#'
            disabled={isLoading}
            onClick={handleDelete}
          >
            {t('show_page.delete')}
          </PrimaryLink>
        </div>

        <PrimaryButton
          onClick={handleClick}
          disabled={isLoading}
        >
          {t('save')}
        </PrimaryButton>
      </PageFooter>
    </PageLayout>
  );
};

export default UserClientShowPage;
