import { withFormik } from 'formik'
import Form from './UserSettingsForm'
import * as Yup from 'yup'
import { BaseFormikConfig, id } from 'forms/_baseForm'
import {
    email,
} from '../usersForm/validation';
import {
    first_name,
    last_name,
    phone,
    type_of_profession,
    birth_date
} from '../individualsForm/validation';
import {
    address,
    postal_code,
    city,
} from '../contactDataForm/validation';

const FormValidationSchema = Yup
    .object()
    .shape({
        user_setting: Yup.object().shape({
            email,
            individual_attributes: Yup.object().shape({
                id,
                first_name,
                last_name,
                phone,
                type_of_profession,
                birth_date,
            }),
            contact_data_attributes: Yup
                .array()
                .of(Yup.object().shape({
                    id,
                    address,
                    postal_code,
                    city
                }))
        })
    })

const mapPropsToValues = ({
    data: {
        individual,
        primary_contact_datum,
        email,
    }
}) => ({
    user_setting: {
        email: email ?? '',
        individual_attributes: {
            id: individual?.id ?? null,
            first_name: individual?.first_name ?? '',
            last_name: individual?.last_name ?? '',
            phone: individual?.phone ?? '',
            phone_iso2: individual?.phone_iso2 ?? '',
            type_of_profession: individual?.type_of_profession ?? '',
            birth_date: individual?.birth_date ?? '',
        },
        contact_data_attributes: [{
            id: primary_contact_datum?.id ?? null,
            address: primary_contact_datum?.address ?? '',
            postal_code: primary_contact_datum?.postal_code ?? '',
            city: primary_contact_datum?.city ?? ''
        }],
    }
})

const FormikConfig = {
    ...BaseFormikConfig,
    enableReinitialize: true,
    mapPropsToValues,
    validationSchema: FormValidationSchema,
}

export default withFormik(FormikConfig)(Form)
