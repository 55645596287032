import PrimaryBackLink from 'components/PrimaryBackLink';
import PrimaryButton from 'components/PrimaryButton';
import PrimaryLink from 'components/PrimaryLink';
import RequestErrorMessages from 'components/RequestErrorMessages';
import { UserSettingsForm } from 'forms/userSettingsForm';
import onErrorRedirect from 'helper/onErrorRedirect';
import userSettingsPathHelper from 'helper/pathHelper/userSettings';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import PageContent from 'layout/PageContent';
import PageFooter from 'layout/PageFooter';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import {
    useGetUserSettingQuery,
    useUpdateUserSettingMutation,
    useUpdateRequestInfoUserSettingMutation
} from 'store/services/userSettings';
import useCustomIonAlert from 'hooks/useCustomIonAlert';
import styled from 'styled-components';
import { findAnyErrorState, isAnyStateIsLoading } from 'helper/multipleStatesChecker';
import { useHistory } from 'react-router-dom'
import appPathHelper from 'helper/pathHelper/app'

const GetInfoContainer = styled.div`
margin-top: 35px;
`

const UserSettingsPage = () => {
    const history = useHistory()
    const { rootPath } = appPathHelper()
    const [present] = useCustomIonAlert()
    const { t } = useTranslation(['user_setting', 'application'])
    const {
        indexUserSettingsPath,
        updateUserSettingsPath,
        updateRequestInfoPath,
    } = userSettingsPathHelper()
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const [updateUserSetting, updateState] = useUpdateUserSettingMutation()
    const [updateRequestInfo,] = useUpdateRequestInfoUserSettingMutation()
    const getState = useGetUserSettingQuery({ url: indexUserSettingsPath })
    const {
        data,
        isSuccess,
        isFetching
    } = getState
    const states = [getState, updateState]

    const isLoading = isAnyStateIsLoading(states)
    const error = findAnyErrorState(states)

    const handleSubmit = async (data) => {

        try {
            await updateUserSetting({
                url: updateUserSettingsPath,
                body: data
            }).unwrap()
            history.replace(rootPath)
        } catch (error) {
            console.error('UserSettingsPage#handleSubmit', error)
        }
    }

    const handleSendInfo = async (e) => {
        e.preventDefault()

        try {
            await updateRequestInfo({ url: updateRequestInfoPath })
            present({
                message: t('index_page.get_info_data_success'),
                buttons: [
                    {
                        role: 'cancel',
                        text: t('close')
                    }
                ],
            })
        } catch (error) {
            console.error('UserSettingsPage#handleSendInfo', error);
        }
    }

    let RenderContent
    if (isSuccess) {
        RenderContent = <>
            <UserSettingsForm
                data={data.data}
                submitButtonRef={submitButtonRef}
                onSubmit={handleSubmit}
            />

            <GetInfoContainer>
                <PrimaryLink
                    to='#'
                    onClick={handleSendInfo}
                >
                    <div>
                        {t('index_page.get_info_data_first')}
                    </div>
                    <div>
                        {t('index_page.get_info_data_second')}
                    </div>
                </PrimaryLink>
            </GetInfoContainer>
        </>

    }

    if (onErrorRedirect(error)) return onErrorRedirect(error)


    return (
        <PageLayout>
            <PageHeader
                withBackButton
                title={t('index_page.title')}
            />

            <PageContent loading={isFetching}>
                <RequestErrorMessages error={error} />

                {RenderContent}
            </PageContent>

            <PageFooter>
                <div className='ion-margin-bottom'>
                    <PrimaryBackLink>
                        {t('cancel')}
                    </PrimaryBackLink>
                </div>

                <PrimaryButton
                    onClick={handleClick}
                    disabled={isLoading}
                >
                    {t('index_page.save')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
};

export default UserSettingsPage;
