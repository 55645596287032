import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';

/*
This functional component is based on: https://capacitorjs.com/docs/guides/deep-links

*/
const AppUrlListener = () => {
    const history = useHistory();

    useEffect(() => {
        App.addListener('appUrlOpen', (event) => {
            // Example url: https://dev.cashlee.eu/users/setup?invitation_token=randometoken123
            // slug = /users/setup?invitation_token=randometoken123
            const slug = event.url.split(process.env.REACT_APP_BASE).pop();
            if (slug) {
                history.push(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
};

export default AppUrlListener;
