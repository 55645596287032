
import { IonButton } from '@ionic/react';
import styled from 'styled-components';

export const Container = styled(IonButton)`

align-self: center;
--border-radius: var(--ion-border-radius-primary);

--background: ${({ isInvert }) => isInvert ? 'var(--ion-color-text-primary);' : 'var(--ion-color-primary-greeny-blue);'}
--background-activated: ${({ isInvert }) => isInvert ? 'var(--ion-color-text-primary-hover);' : 'var(--ion-color-secondary-greeny-blue);'}
--background-hover: ${({ isInvert }) => isInvert ? 'var(--ion-color-text-primary-hover);' : 'var(--ion-color-secondary-greeny-blue);'}

--background-hover-opacity: 0.5;

min-width: 14.5em;
max-width: ${({ maxWidth }) => maxWidth ? maxWidth : '15.6em;'}
min-height: 3em;

${({ height }) => height ? `height: ${height};` : ''}

${({ toUpperText }) => toUpperText ? 'text-transform: uppercase;' : ''}

`
