import PropTypes from 'prop-types'
import DefualtTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const CssTextField = styled(DefualtTextField)({
    "& .MuiInputBase-root": {
        "& input": {
            textAlign: 'center',
            color: 'var(--ion-color-text-primary)'
        }
    },
    '& .MuiOutlinedInput-root': {
        marginTop: '10px',
        background: 'var(--ion-background-color)',
        borderRadius: '10px',
        height: '30px',
        width: '150px',
        textAlign: 'center',

        '& fieldset': {
            borderColor: 'var(--ion-color-border-promotion)',
            borderWidth: '2px'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--ion-color-border-promotion)',
            borderWidth: '2px'
        }
    },
});

const PromoCodeTextField = ({
    value = '',
    multiline = false,
    ...rest
}) => {
    return (
        <CssTextField
            autoComplete="off"
            value={value}
            multiline={multiline}
            {...rest}
        />
    )
}

PromoCodeTextField.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    multiline: PropTypes.bool
}

export default PromoCodeTextField
