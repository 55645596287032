import {
    ContainerWithLabels,
    DetailContainerTwo,
    NSDetailLabelTwo,
    NSTitleLabelDesktop,
    NSTitleLabelMobile
} from "components/BaseLabels/index.js"
import PrimaryLink from "components/PrimaryLink"
import RequestErrorMessages from "components/RequestErrorMessages"
import usersPathHelper from "helper/pathHelper/users"
import useCustomIonAlert from "hooks/useCustomIonAlert/index.js"
import useLoadingAndSuccessToast from "hooks/useLoadingAndSuccessToast.js"
import useRedirectIfAlreadyAuth from "hooks/useRedirectIfAlreadyAuth"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import { useLocation } from 'react-router-dom'
import { useSignUpUserMutation } from "store/services/users"
import {
    MobileEmailContainer,
    ResendEmailContainer
} from './styled.js'
import PageContentSmaller from 'layout/PageContentSmaller/index.js';
import {
    IonCol,
    IonGrid,
    IonImg,
    IonRow,
    isPlatform
} from "@ionic/react"
import emailSentImage from "assets/images/mail_sent.png"

const AfterSignUpPage = () => {
    useRedirectIfAlreadyAuth();

    const location = useLocation()
    const { createUserRegistrationsPath } = usersPathHelper()
    const [reCreateUserRegistration, { isLoading, error }] = useSignUpUserMutation();
    const { t: userT } = useTranslation('user')
    const { t: appT } = useTranslation('application')
    const [showLoading, , dismiss] = useLoadingAndSuccessToast()
    const [present] = useCustomIonAlert()

    const handleResendEmail = async (e) => {
        e.preventDefault()

        const { state } = location
        if (!state) {
            return
        }
        showLoading(userT('after_sign_up_page.resending_email'))
        try {
            await reCreateUserRegistration({
                url: createUserRegistrationsPath,
                body: state
            }).unwrap()
            dismiss()
            present({
                message: userT('after_sign_up_page.resend_email_success'),
                buttons: [
                    {
                        role: 'cancel',
                        text: appT('close')
                    }
                ],
            })
        } catch (error) {
            console.error('AfterSignUpPage#handleResendEmail', error)
        }
    }

    let RenderImageContent
    if (!isPlatform('mobile')) {
        RenderImageContent = <IonCol
            sizeSm='6'
        >
            <MobileEmailContainer>
                <IonImg src={emailSentImage} style={{ height: '720px' }} />
            </MobileEmailContainer>
        </IonCol>
    }

    return (
        <PageLayout>
            <PageContentSmaller
                contentProps={{
                    sizeLg: '9'
                }}
            >
                <IonGrid>
                    <IonRow>
                        <IonCol
                            sizeSm={!isPlatform('mobile') ? "6" : "12"}
                        >
                            <PageContentWithAppLogo
                                body={
                                    <>
                                        <RequestErrorMessages error={error} />

                                        <div className="text-margin">
                                            <ContainerWithLabels>
                                                <NSTitleLabelDesktop>
                                                    {userT('after_sign_up_page.email_send')}
                                                </NSTitleLabelDesktop>

                                                <NSTitleLabelMobile>
                                                    {userT('after_sign_up_page.email_send')}
                                                </NSTitleLabelMobile>
                                            </ContainerWithLabels>

                                            <DetailContainerTwo>
                                                <NSDetailLabelTwo>
                                                    {userT('after_sign_up_page.click_link_to_confirm')}
                                                </NSDetailLabelTwo>
                                            </DetailContainerTwo>
                                        </div>

                                        <ResendEmailContainer>
                                            <PrimaryLink
                                                color="var(--ion-color-text-base)"
                                                fontSize="18px"
                                                to="#"
                                                onClick={handleResendEmail}
                                                disabled={isLoading}
                                            >
                                                {userT('after_sign_up_page.resend_email')}
                                            </PrimaryLink>
                                        </ResendEmailContainer>

                                        <DetailContainerTwo>
                                            <NSDetailLabelTwo>
                                                {userT('after_sign_up_page.remember_to_check_spam')}
                                            </NSDetailLabelTwo>

                                            <NSDetailLabelTwo className="ion-margin-top">
                                                {userT('after_sign_up_page.you_can_safely_close_this_page')}
                                            </NSDetailLabelTwo>
                                        </DetailContainerTwo>
                                    </>
                                }
                            />
                        </IonCol>
                        {RenderImageContent}
                    </IonRow>
                </IonGrid>
            </PageContentSmaller>
        </PageLayout>
    )
}

export default AfterSignUpPage
