import appPathHelper from 'helper/pathHelper/app';
import settingsPathHelper from 'helper/pathHelper/settings';
import { isEmpty } from 'lodash'

export const styledGetPrimaryResourceColor = ({ primary }) => primary ? 'var(--ion-color-primary-greeny-blue)' : 'var(--ion-color-text-primary)'

// We considered screen width that more than 992px is a desktop device.
export const styledHideIfDesktop = () => "@media (min-width: 992px) {display: none;}"
// Code above mean show only on mobile

// We considered screen width that less than 992px is a mobile device.
export const styledHideIfMobile = () => "@media (max-width: 992px) {display: none;}"
// Code above mean show only on desktop

export const isAnyOfMainPage = (pathname) => {
    const { rootPath } = appPathHelper()
    const { indexSettingsPath } = settingsPathHelper()

    const mainPagePaths = [
        rootPath,
        indexSettingsPath,
    ]

    return mainPagePaths.includes(pathname)
}

// Enhanced version of `isEmpty` which could check all types
export const isBlank = (value) => {
    const isBoolean = typeof value === 'boolean'
    const isNumber = typeof value === 'number'
    if (isBoolean || isNumber) return !value

    return isEmpty(value)
}

export const isPresent = (value) => {
    return !isBlank(value)
}
