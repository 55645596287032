import { DetailLabel } from "components/BaseLabels";
import { styledHideIfDesktop, styledHideIfMobile } from "helper/applicationHelper";
import styled from "styled-components";

export const BankAppendix = styled(DetailLabel)`
text-align: left;
font-size: 11px;
`

export const BankAppendixContain = styled.div`
color: #32AC91;
display: flex;
position: relative;
${styledHideIfMobile}
`

export const BankAppendixContainMobile = styled.div`
color: #32AC91;
display: flex;
position: relative;
${styledHideIfDesktop}
`

export const SecurityIconContainer = styled.div`
margin-right: 8px;
position: absolute;
left: -56px;
top: -16px;
`

export const SecurityIconContainerMobile = styled.div`
width: 15%;
`

export const BankAppendixLabel = styled.div`
font-size: 12px;
`

export const BankAppendixLabelMobile = styled(BankAppendixLabel)`
font-size: 12px;
width: 85%;
`