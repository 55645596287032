import styled from "styled-components";

export const ForgotPasswordContainer = styled.div`
font-size: 11px;
padding-top: 8px;
`
export const RegistrationContainer= styled.div`
margin-top: min(calc(3.36vh + 3.36vw), 60px);
font-size: 14px;
`

export const SignInContainer = styled.div`
margin-top: min(calc(2.24vh + 2.24vw), 40px);
`

export const DetailContainer = styled.div`
margin-top: 40px;
`

export const ContaninerWithIcon = styled.div`
display: inline-flex;
`

export const InscriptionSide= styled.div`
font-size: 16px;
margin: auto;
`
