import { USER_CLIENTS_BASE_PATH } from 'constants/routeConstants'
import basePathHelper from './_base'

// Please visit ./_base.js for more information.

const userClientsPathHelper = (id) => {
    return basePathHelper(USER_CLIENTS_BASE_PATH, id)
}

export default userClientsPathHelper
