import { Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import BaseField from 'components/fields/BaseField';
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import { decorateEmailString } from 'helper/stringHelper';

const Index = ({ submitButtonRef }) => {

    const { t } = useTranslation('user')
    const { setFieldValue } = useFormikContext();

    return (
        <Form className='first-form-control first-form-control--first-input-margin'>
            <BaseField
                name='user.email'
                placeholder={t('email')}
                onChange={event => setFieldValue('user.email', decorateEmailString(event.target.value))}
            />

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default Index
