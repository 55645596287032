import { createSlice } from '@reduxjs/toolkit'
import { APP_SLICE_NAME } from 'constants/sliceNameConstants'

const initialState = {
    theme: 'light',
    locale: 'fr',
    isReady: false,
    isSideMenuVisible: false,
    prevLocation: {},
}

const applicationSlice = createSlice({
    name: APP_SLICE_NAME,
    initialState,
    reducers: {
        setIsReady(state, { payload }) {
            state.isReady = payload
        },
        setIsSideMenuVisible(state, { payload }) {
            state.isSideMenuVisible = payload.isSideMenuVisible
        },
        setPrevLocation(state, { payload }) {
            state.prevLocation = payload
        }
    },
})

export const {
    setIsReady,
    setIsSideMenuVisible,
    setPrevLocation,
} = applicationSlice.actions

export const selectApplicationState = (state) => state[APP_SLICE_NAME]

export default applicationSlice
