import PageFooter from 'layout/PageFooter'

const TransactionPageFooter = ({
    children,
    backgroundColor = 'var(--ion-background-color)'
}) => {
    return (
        <PageFooter
            containerProps={{
                paddingBottom: "40px",
                backgroundColor: backgroundColor
            }}
            fading={false}
        >
            {children}
        </PageFooter>
    )
}

export default TransactionPageFooter
