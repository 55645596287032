import { IMask } from 'react-imask';

export const phoneIMaskOpions = {
    mask: '{+33} A BC DE FG HI',
    blocks: {
        A: {
            mask: '0',
            placeholderChar: '6',
        },
        B: {
            mask: '0',
            placeholderChar: '0'
        },
        C: {
            mask: '0',
            placeholderChar: '1'
        },
        D: {
            mask: '0',
            placeholderChar: '0'
        },
        E: {
            mask: '0',
            placeholderChar: '2'
        },
        F: {
            mask: '0',
            placeholderChar: '0'
        },
        G: {
            mask: '0',
            placeholderChar: '3'
        },
        H: {
            mask: '0',
            placeholderChar: '0'
        },
        I: {
            mask: '0',
            placeholderChar: '4'
        },
    }
}

export const phoneActiveIMaskOpions = {
    mask: '{+33} A BC DE FG HI',
    blocks: {
        A: {
            mask: '0',
            placeholderChar: '_'
        },
        B: {
            mask: '0',
            placeholderChar: '_'
        },
        C: {
            mask: '0',
            placeholderChar: '_'
        },
        D: {
            mask: '0',
            placeholderChar: '_'
        },
        E: {
            mask: '0',
            placeholderChar: '_'
        },
        F: {
            mask: '0',
            placeholderChar: '_'
        },
        G: {
            mask: '0',
            placeholderChar: '_'
        },
        H: {
            mask: '0',
            placeholderChar: '_'
        },
        I: {
            mask: '0',
            placeholderChar: '_'
        },
    }
}

export const useIMaskPhonePipe = () => {
    return useIMaskPipe(phoneIMaskOpions)
}

// https://imask.js.org/guide.html#masked-date
export const dateIMaskOptions = {
    mask: Date,  // enable date mask

    // other options are optional
    pattern: 'd{/}`m{/}`Y',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
    // you can provide your own blocks definitions, default blocks for date mask are:
    blocks: {
        d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
            placeholderChar: 'd', // defaults to '_'
        },
        m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
            placeholderChar: 'm', // defaults to '_'
        },
        Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999,
            placeholderChar: 'y', // defaults to '_'
        }
    },
    // define date -> str convertion
    format: function (date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        if (day < 10) day = "0" + day;
        if (month < 10) month = "0" + month;

        return [day, month, year].join('/');
    },
    // define str -> date convertion
    parse: function (str) {
        var dayMonthYear = str.split('/');
        return new Date(dayMonthYear[2], dayMonthYear[1] - 1, dayMonthYear[0]);
    },

    // optional interval options
    min: new Date(1970, 0, 1),  // defaults to `1900-01-01`
    max: new Date(),  // defaults to `9999-01-01`

    autofix: true,  // defaults to `false`, see details

    // also Pattern options can be set
    lazy: false,

    // and other common options
    overwrite: true  // defaults to `false`
}

export const useIMaskDatePipe = () => {
    return useIMaskPipe(dateIMaskOptions)
}

export const useIMaskPipe = (options) => {
    // For more information: https://imask.js.org/guide.html#pipe
    const pipe = IMask.createPipe(options)

    const maskValue = (value) => {
        return pipe(value)
    }

    return maskValue
}
