import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    setDeviceUUID,
    setDeviceToken,
} from 'store/slices/device'
import {
    selectCurrentDeviceUUID,
    selectCurrentDeviceToken,
} from 'store/slices/device'

const useDevice = () => {
    const dispatch = useDispatch()

    const deviceUUID = useSelector(selectCurrentDeviceUUID)

    const deviceToken = useSelector(selectCurrentDeviceToken)

    const handleSetDeviceUUID = useCallback((uuid) => {
        dispatch(setDeviceUUID(uuid))
    }, [dispatch])

    const handleSetDeviceToken = useCallback((token) => {
        dispatch(setDeviceToken(token))
    }, [dispatch])

    return useMemo(() => ({
        deviceUUID,
        deviceToken,
        setDeviceUUID: handleSetDeviceUUID,
        setDeviceToken: handleSetDeviceToken,
    }),
        [
            deviceUUID,
            deviceToken,
            handleSetDeviceUUID,
            handleSetDeviceToken,
        ]
    )
}

export default useDevice
