import { UserSetupForm } from "forms/usersForm";
import usersPathHelper from "helper/pathHelper/users";
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper";
import useRedirectIfAlreadyAuth from "hooks/useRedirectIfAlreadyAuth";
import PageLayout from "layout/PageLayout"
import { useHistory } from "react-router-dom";
import { useSetupUserMutation } from 'store/services/users'
import produce from "immer"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo";
import PrimaryButton from "components/PrimaryButton";
import { useTranslation } from "react-i18next";
import useSubmitButtonRef from "hooks/useSubmitButtonRef";
import RequestErrorMessages from "components/RequestErrorMessages";
import PrimaryLink from "components/PrimaryLink";
import CustomSteppers from "components/CustomSteppers";
import { useState } from "react";
import { maxUserSetupSteps } from "forms/usersForm/UserSetupFormController";
import useCredentials from "hooks/useCredentials";
import PageContentSmaller from "layout/PageContentSmaller";
import { BackButtonContainer, PrimaryButtonContainer } from "./styled";

const SetupSignUpPage = () => {
    useRedirectIfAlreadyAuth();

    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const history = useHistory();
    const {
        updateSetupUserRegistrationsPath,
        afterSetupUserRegistrationsPath
    } = usersPathHelper()
    const [setupUserRegistration, { isLoading, error }] = useSetupUserMutation()
    const { t: appT } = useTranslation('application')
    const [currentStep, setCurrentStep] = useState(1)
    const { setCredentialsAll } = useCredentials()

    const handleSubmit = async (data) => {
        if (currentStep < maxUserSetupSteps) {
            setCurrentStep(currentStep + 1)
        } else {
            const url_invitation_token = getURLSearchParamsHelper('invitation_token')
            const body = produce(data, draftState => {
                draftState.user.invitation_token = url_invitation_token
                draftState.invitation_token = url_invitation_token
            })
            try {
                const response = await setupUserRegistration({
                    url: updateSetupUserRegistrationsPath,
                    body
                }).unwrap()
                await setCredentialsAll(response.data)
                history.push(afterSetupUserRegistrationsPath)
            } catch (error) {
                console.error('SignUpPage#handleSubmit', error)
            }
        }
    }

    const hadleBackClick = (e) => {
        e.preventDefault();
        setCurrentStep(currentStep - 1)
    }

    const handleSubmitLabel = (step) => {
        switch (step) {
            case 2:
                return appT('record')
            case 3:
                return appT('finalize')
            default:
                return appT('submit')
        }
    }

    return (
        <PageLayout>
            <PageContentSmaller withAreaFading={false}>
                <PageContentWithAppLogo
                    pageName='setup'
                    body={
                        <>
                            <RequestErrorMessages error={error} />

                            <CustomSteppers
                                currentStep={currentStep}
                                maxSteps={maxUserSetupSteps}
                            />

                            <UserSetupForm
                                currentStep={currentStep}
                                submitButtonRef={submitButtonRef}
                                onSubmit={handleSubmit}
                            />
                            <div>
                                <BackButtonContainer
                                    currentStep={currentStep}
                                >
                                    <PrimaryLink
                                        to="#"
                                        onClick={hadleBackClick}
                                        disabled={isLoading}
                                    >
                                        {appT('back')}
                                    </PrimaryLink>
                                </BackButtonContainer>

                                <PrimaryButtonContainer
                                    currentStep={currentStep}
                                >
                                    <PrimaryButton
                                        onClick={handleClick}
                                        disabled={isLoading}
                                    >
                                        {handleSubmitLabel(currentStep)}
                                    </PrimaryButton>
                                </PrimaryButtonContainer>
                            </div>
                        </>
                    } />
            </PageContentSmaller>
        </PageLayout>
    )
}

export default SetupSignUpPage
