import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from './UserClientForm'
import { BaseFormikConfig, id } from 'forms/_baseForm'
import {
    last_name,
    first_name,
    phone,
    email,
} from './validation';

const FormValidationSchema = Yup
    .object()
    .shape({
        user_client: Yup.object().shape({
            id,
            last_name,
            first_name,
            phone,
            email,
        })
    })

const FormikConfig = {
    ...BaseFormikConfig,
    enableReinitialize: true,
    mapPropsToValues: ({ data }) => ({
        user_client: {
            id: data?.id ?? null,
            last_name: data?.last_name ?? '',
            first_name: data?.first_name ?? '',
            phone: data?.phone ?? '',
            phone_iso2: data?.phone_iso2 ?? '',
            email: data?.email ?? '',
        }
    }),
    validationSchema: FormValidationSchema,
}

export default withFormik(FormikConfig)(Form)
