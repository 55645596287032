import { ContainerWithLabels, NSTitleLabel } from "components/BaseLabels"
import PrimaryButton from "components/PrimaryButton"
import PrimaryLink from "components/PrimaryLink"
import clientPaymentPathHelper from "helper/pathHelper/clientPayment"
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper"
import useRedirectToInitPayment from "hooks/useRedirectToInitPayment"
import PageContentSmaller from "layout/PageContentSmaller"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo"
import PageFooter from "layout/PageFooter"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { useUpdateClientExplainBankMutation } from "store/services/clientPayments"
import { DetailContainer, DetailLabel } from './styled'

const ClientExplainBankPage = () => {
    useRedirectToInitPayment()
    const { t } = useTranslation(['application', 'client_payment']);
    const uuid = getURLSearchParamsHelper('uuid')
    const history = useHistory()
    const { state = {} } = useLocation()
    const {
        showClientWaitingForLinkPath,
        updateExplainClientSelectedBankPath,
    } = clientPaymentPathHelper(uuid)
    const { data = {} } = state
    // === DEBUG ===
    // const data = {
    //     bridgeapi_bank: {
    //         name: "Credit Agricole Ile de France",
    //         bank_help_link_to_click: "https://cashlee.eu/credit_agricole_identifiant/",
    //         bank_web_address: "https://psd2.portal.credit-agricole.fr"
    //     },
    //     type: 'ClientPayment'
    // }
    // === DEBUG ===
    const { bridgeapi_bank } = data

    const [trigger, { isLoading }] = useUpdateClientExplainBankMutation()

    const handleClick = async () => {
        await trigger({ url: updateExplainClientSelectedBankPath })
        history.replace(showClientWaitingForLinkPath)
    }

    return (
        <PageLayout>
            <PageContentSmaller>
                <PageContentWithAppLogo
                    body={
                        <>
                            <ContainerWithLabels className="text-margin">
                                <NSTitleLabel color='var(--ion-color-primary-greeny-blue)'>
                                    {t('explain_bank_page.title')}
                                </NSTitleLabel>
                            </ContainerWithLabels>

                            <DetailContainer className="ion-text-start ion-margin ion-padding">
                                <div>
                                    <DetailLabel>
                                        {t("explain_bank_page.you_will_be_redirect_to_bank_web")}
                                    </DetailLabel>
                                    <PrimaryLink
                                        to="#"
                                        onClick={(e) => e.preventDefault()}
                                        color='var(--ion-color-primary-greeny-blue)'>
                                        {bridgeapi_bank?.name}
                                    </PrimaryLink>
                                    {" "}
                                    <DetailLabel>
                                        {t("explain_bank_page.redirect_to_bank_web_address")}
                                    </DetailLabel>

                                    <PrimaryLink
                                        color='var(--ion-color-primary-greeny-blue)'
                                        to="#"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {bridgeapi_bank?.bank_web_address}
                                    </PrimaryLink>
                                </div>

                                <div className="ion-margin-top">
                                    <DetailLabel>
                                        {t("explain_bank_page.you_will_need")}
                                    </DetailLabel>
                                    {" "}
                                    <PrimaryLink
                                        to="#"
                                        onClick={(e) => e.preventDefault()}
                                        color='var(--ion-color-primary-greeny-blue)'>
                                        {t("explain_bank_page.bank_credentials")}
                                    </PrimaryLink>
                                    {" "}
                                    <DetailLabel>
                                        {t("explain_bank_page.to_authenticate")}
                                    </DetailLabel>
                                </div>

                                <div className="ion-margin-top">
                                    <DetailLabel>
                                        {t("explain_bank_page.click_if_you_need_help")}
                                    </DetailLabel>

                                    <PrimaryLink
                                        to={{ pathname: bridgeapi_bank?.bank_help_link_to_click }}
                                        target='_blank'
                                        color='var(--ion-color-primary-greeny-blue)'
                                    >
                                        {t("here")}
                                    </PrimaryLink>
                                </div>
                            </DetailContainer>
                        </>
                    }
                />
            </PageContentSmaller>
            <PageFooter>

                <PrimaryButton
                    toUpperText={false}
                    className="ion-text-wrap"
                    height="4.8em"
                    onClick={handleClick}
                    disabled={isLoading}
                >
                    {t('explain_bank_page.submit')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
}

export default ClientExplainBankPage
