import { NSDetailLabel, NSTitleLabel } from "components/BaseLabels"
import PrimaryButton from "components/PrimaryButton"
import usersPathHelper from "helper/pathHelper/users"
import useRedirectIfAlreadyAuth from "hooks/useRedirectIfAlreadyAuth"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo"
import PageFooter from "layout/PageFooter"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import PageContentSmaller from 'layout/PageContentSmaller/index.js';

// @deprecated from https://dynames.plan.io/issues/1736
// This page is replaced by https://cashlee.eu/pre_sign_up
const PreSignUpPage = () => {
    useRedirectIfAlreadyAuth();

    const { newUserRegistrationsPath } = usersPathHelper()
    const { t: userT } = useTranslation('user')
    const { t: appT } = useTranslation('application')

    return (
        <PageLayout>
            <PageContentSmaller>
                <PageContentWithAppLogo
                    body={
                        <div className='text-margin'>
                            <NSTitleLabel>
                                {userT('pre_sign_up_page.ready_to_join')}
                            </NSTitleLabel>
                            <NSDetailLabel>
                                {userT('pre_sign_up_page.ask_for_information')}
                            </NSDetailLabel>
                        </div>
                    }
                />
            </PageContentSmaller>

            <PageFooter>
                <PrimaryButton
                    routerLink={newUserRegistrationsPath}
                >
                    {appT('continue')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
}

export default PreSignUpPage
