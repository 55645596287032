import {
  IonPage,
  useIonModal,
} from '@ionic/react';
import clsx from 'clsx';
import './style.scss';

const ModalContent = ({ children }) => {
  return (
    <IonPage>
      <div className="wrapper">
        {children}
      </div>
    </IonPage>
  )
}

// https://ionicframework.com/docs/api/modal#interfaces
const useAlertModalLike = ({
  children,
  almostFullWidthEnabled,
  wrapperMarginDisabled,
  cssClass,
}) => {
  const [present, dismiss] = useIonModal(ModalContent, { children })

  const customPresent = (presentProps) => {
    present({
      ...presentProps,
      cssClass: clsx(
        'alert-modal-like',
        presentProps?.cssClass,
        cssClass,
        almostFullWidthEnabled && 'almost-full-width',
        wrapperMarginDisabled && 'no-wrapper-margin',
      ),
    })
  }
  return [customPresent, dismiss]
}

export default useAlertModalLike
