import styled from "styled-components";

const styledGetByCurrentPage = ({ currentStep }) => {
    if (currentStep === 1) {
        return `visibility: hidden;`
    }
    return `margin-top: min(calc(1.68vh + 1.68vw), 30px);`
};

const styledGetByPrimaryButton = ({ currentStep }) => {
    if (currentStep === 1) {
        return `margin-top: min(calc(3.36vh + 3.36vw), 60px);`
    }
    return `margin-top: min(calc(1.12vh + 1.12vw), 20px);`
};


export const PrimaryButtonContainer = styled.div`
${styledGetByPrimaryButton}
`

export const BackButtonContainer = styled.div`
${styledGetByCurrentPage}
`