import { Wrapper } from './styled.js';

const ContentTextCenter = ({
    children,
    containerProps,
}) => {
    return (
        <Wrapper {...containerProps}>
            {children}
        </Wrapper>
    )
};

export default ContentTextCenter;
