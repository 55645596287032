import { Container } from "./styled";

/*
Example.
=======
To open external link, the props should look like this.

<PrimaryLink to={{ pathname: 'https://your_link.com' }} target='_blank'>
    Your text
</PrimaryLink>
=======

*/

const PrimaryLink = ({
    children,
    ...rest
}) => {

    return (
        <Container
            {...rest}
        >
            {children}
        </Container>
    );
};

export default PrimaryLink;
