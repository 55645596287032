import TransactionAmount from 'components/transactions/AmountLabel'
import TransactionPaymentLink from 'components/transactions/PaymentLinkLabel'
import TransactionPaymentLinkQr from 'components/transactions/PaymentLinkQr'
import transactionsPathHelper from 'helper/pathHelper/transactions'
import PageContent from 'layout/PageContent'
import PageHeader from 'layout/PageHeader'
import { useTranslation } from 'react-i18next'
import PrimaryButton from 'components/PrimaryButton'
import clientPaymentPathHelper from 'helper/pathHelper/clientPayment'
import TransactionSendLinkForm from 'forms/transactionsForm/TransactionSendLinkFormController'
import useSubmitButtonRef from 'hooks/useSubmitButtonRef'
import { useDeleteTransactionMutation, useSendPaymentLinkMutation } from 'store/services/transactions'
import Spinner from 'components/Spinner'
import { SpinnerContainer, TextButton } from './styled'
import Countdown from 'react-countdown';
import dayjs from 'dayjs'
import { useHistory } from 'react-router'
import useCustomIonAlert from 'hooks/useCustomIonAlert';
import TransactionPageFooter from 'layout/TransactionPageFooter'
import { passedSendMailTimeSchedule } from 'helper/dateHelper'
import { findAnyErrorState, isAnyStateIsLoading } from 'helper/multipleStatesChecker';
import RequestErrorMessages from 'components/RequestErrorMessages'

function CountdownExpireTime({
    expires_time,
    refetch,
}) {

    const renderer = ({ hours, minutes, seconds }) => {
        const textHours = hours < 10 ? `0${hours}` : `${hours}`
        const textMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
        const textSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`

        // Render a countdown
        return <span>{textHours}:{textMinutes}:{textSeconds}</span>;
    };
    let RenderContent = null
    if (expires_time) {
        const date = dayjs(expires_time).toDate()
        RenderContent = <Countdown
            date={date}
            renderer={renderer}
            /*
            Since we rely on the server to check if transactions are expired
            and it has a delay of 1 minute 
            but we could get the latest status for the transaction from the show endpoint. 
            so we will refetch upon Countdown completed. 
            */
            onComplete={refetch}
        />
    }

    return RenderContent
}

const Pending = ({
    data,
    refetch,
}) => {
    const { amount, status, uuid, id, expires_time, payment_link_type, execution_date } = data.data
    const { showClientAcceptPaymentUri } = clientPaymentPathHelper(uuid)

    const { t } = useTranslation(['transaction', 'application'])

    const [submitButtonRef, handleClick] = useSubmitButtonRef()

    const [sendPaymentLink, sendPaymentLinkState] = useSendPaymentLinkMutation()
    const isLoading = isAnyStateIsLoading([sendPaymentLinkState])
    const error = findAnyErrorState([sendPaymentLinkState])

    const { sendPaymentLinkTransactionsPath, deleteTransactionsPath } = transactionsPathHelper(id)
    const history = useHistory();

    const handleSubmit = async (data) => {
        try {
            await sendPaymentLink({
                url: sendPaymentLinkTransactionsPath,
                body: data
            }).unwrap()

            history.goBack()
        } catch (error) {
            console.error('TransactionShowPage#handleSubmit', error)
        }
    }

    const [deleteTransaction, deleteState] = useDeleteTransactionMutation()
    const [alertPresent] = useCustomIonAlert()
    const handleDelete = async () => {
        try {
            await deleteTransaction({
                url: deleteTransactionsPath, id
            }).unwrap()

            history.replace(`/`)
        } catch (error) {
            console.error('TransactionShowPage#handleDelete', error)
        }
    }
    const paymentLinkSent = payment_link_type && passedSendMailTimeSchedule(execution_date)

    return (
        <>
            <PageHeader
                withBackButton
            />

            <PageContent
                onRefresh={refetch}
            >
                <TransactionAmount status={status} amount={amount} bold />

                <TransactionPaymentLink status={status} link={showClientAcceptPaymentUri} />

                <TransactionPaymentLinkQr status={status} link={showClientAcceptPaymentUri} />

                {
                    expires_time ? (
                        <>
                            <SpinnerContainer>
                                <Spinner />
                            </SpinnerContainer>
                            <SpinnerContainer>
                                <CountdownExpireTime
                                    expires_time={expires_time}
                                    refetch={refetch}
                                />
                            </SpinnerContainer>
                        </>
                    ) : (
                        <>
                            <RequestErrorMessages error={error} />

                            <TransactionSendLinkForm
                                data={data.data}
                                submitButtonRef={submitButtonRef}
                                onSubmit={handleSubmit}
                            />

                        </>
                    )
                }

            </PageContent>

            {
                !expires_time && (
                    <TransactionPageFooter>
                        <div className='ion-margin-bottom'>
                            <TextButton
                                onClick={() => {
                                    alertPresent({
                                        header: t('show_page.cancel_payment'),
                                        message: t('show_page.cancel_payment_confirmation'),
                                        buttons: [t('cancel'), { text: t('confirm'), handler: handleDelete }],
                                    })
                                }}
                            >
                                {t('show_page.cancel_payment')}
                            </TextButton>
                        </div>

                        <PrimaryButton
                            onClick={handleClick}
                            disabled={isLoading || deleteState.isLoading || paymentLinkSent}
                        >
                            {t('send')}
                        </PrimaryButton>
                    </TransactionPageFooter>
                )
            }
        </>
    )
}

export default Pending
