import PropTypes from 'prop-types'
import { Wrapper, Container } from './styled.js';

const ContentList = ({
    children,
    containerProps,
}) => {
    return (
        <Wrapper lines='none' >
            <Container {...containerProps}>
                {children}
            </Container>
        </Wrapper>
    )
};

ContentList.propTypes = {
    containerProps: PropTypes.shape({
        isFirstMarginInline: PropTypes.bool,
        isSecondMarginInline: PropTypes.bool,
    })
}

export default ContentList;
