import { IonCol } from '@ionic/react';
import { Wrapper } from 'components/ContentList/styled'
import {
    Title as CompTitle
} from '../styled'
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";
import {
    Container,
    CardContainer,
    CardUpperContainer,
    CardDownerContainer,
    Title,
    Detail,
    Amount
} from './styled'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./styles.scss";

// import required modules
import { FreeMode, Pagination } from "swiper";
import transactionsPathHelper from 'helper/pathHelper/transactions';
import { formatNumberToCurrency } from 'helper/numberHelper';
import { useHistory } from 'react-router';
import { formatDate } from 'helper/dateHelper';

const RenderTransactionCard = ({ transaction }) => {
    const {
        id,
        amount,
        full_name,
        execution_date,
    } = transaction
    const { push } = useHistory()
    const { afterCreateTransactionsPath } = transactionsPathHelper(id)
    return <CardContainer onClick={() => push(afterCreateTransactionsPath)}>
        <CardUpperContainer>
            <IonCol>
                <Title>
                    {full_name || '\u00A0'}
                </Title>

                <Detail>
                    {formatDate(execution_date)}
                </Detail>
            </IonCol>
        </CardUpperContainer>
        <CardDownerContainer class="ion-align-items-end">
            <IonCol>
                <QRCode value="hey" size={60} />
            </IonCol>
            <IonCol>
                <Amount>
                    {formatNumberToCurrency(amount)}
                </Amount>
            </IonCol>
        </CardDownerContainer>
    </CardContainer>
}

const LatestPendingTransactions = ({ transactions }) => {
    const { t } = useTranslation('home')
    let RenderContent = null
    if (transactions.length) {
        RenderContent = <Wrapper>
            <CompTitle isSecondMarginInline>
                {t('pending_transactions')}
            </CompTitle>
            <Container isSecondMarginInline>
                <Swiper
                    slidesPerView='auto'
                    spaceBetween={20}
                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Pagination]}
                >
                    {
                        transactions.map((transaction) => <SwiperSlide key={transaction.id}>
                            <RenderTransactionCard transaction={transaction} />
                        </SwiperSlide>
                        )
                    }
                </Swiper>
            </Container>
        </Wrapper>
    }

    return RenderContent
}

export default LatestPendingTransactions
