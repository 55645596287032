import { baseSplitApi, baseGetResourcesProvidesTags } from "./_base";

export const userInvitationsSlice = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUserInvitations: builder.query({
      query: (config) => config,
      providesTags: baseGetResourcesProvidesTags,
    }),
    setUserInvitation: builder.mutation({
      query: (config) => ({
        ...config,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetUserInvitationsQuery, useSetUserInvitationMutation } =
  userInvitationsSlice;
