import { IonCol, IonGrid, IonRow } from "@ionic/react"
import CheckCircle from "@mui/icons-material/CheckCircle"
import PrimaryButton from "components/PrimaryButton"
import PrimaryLink from "components/PrimaryLink/index.js"
import RequestErrorMessages from "components/RequestErrorMessages/index.js"
import { formatDate } from "helper/dateHelper/index.js"
import { formatNumberToCurrency } from "helper/numberHelper"
import transactionsPathHelper from "helper/pathHelper/transactions"
import { isSuccess } from "helper/transactionsStatusHelper/index.js"
import PageContent from "layout/PageContent"
import PageFooter from "layout/PageFooter"
import PageHeader from "layout/PageHeader"
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom';

import {
    Container,
    Amount,
    StatusContainer
} from './styled.js'
import { useFetchPDF } from "hooks/useFetch.js"
import { TitleLabel } from "components/BaseLabels/index.js"

const Success = ({ data }) => {
    const {
        id,
        status,
        label,
        amount,
        full_name,
        execution_date,
    } = data.data

    const {
        showPdfTransactionsUri,
        duplicateTransactionsPath
    } = transactionsPathHelper(id)
    const { t } = useTranslation('transaction')
    const [trigger, { isLoading, error }] = useFetchPDF()

    const handleGetPdfClick = (e) => {
        e.preventDefault()
        trigger(showPdfTransactionsUri, {
            fileOptions: {
                filename: label
            },
            toastOptions: {
                loadingText: t('show_page.obtaining_proof')
            }
        })
    }

    const history = useHistory();

    return (
        <>
            <PageHeader
                withBackButton
                title={isSuccess(status) ? label : ''}
            />

            <PageContent>
                <Container>
                    <RequestErrorMessages error={error} />

                    <IonGrid className="first-form-control">
                        <IonRow>
                            <IonCol size='7'>
                                <IonRow>
                                    <TitleLabel className="ion-margin-top">
                                        {formatDate(execution_date)}
                                    </TitleLabel>
                                </IonRow>

                                <IonRow>
                                    <TitleLabel className="ion-margin-top">
                                        {full_name}
                                    </TitleLabel>
                                </IonRow>

                                <IonRow>
                                    <TitleLabel className="ion-margin-top ion-text-start">
                                        {label}
                                    </TitleLabel>
                                </IonRow>
                            </IonCol>

                            <IonCol>
                                <IonRow className="ion-justify-content-end">
                                    <Amount>
                                        {formatNumberToCurrency(amount)}
                                    </Amount>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <StatusContainer>
                        <div>
                            <CheckCircle style={{ fontSize: 40, color: 'var(--ion-color-primary-greeny-blue)' }} />
                        </div>

                        <div className="ion-margin-top">
                            {t('status_text.success')}
                        </div>
                    </StatusContainer>
                </Container>
            </PageContent>

            <PageFooter>
                <div className="ion-margin-bottom">
                    <PrimaryLink
                        disabled={isLoading}
                        to="#"
                        onClick={handleGetPdfClick}
                    >
                        {t('show_page.obtain_proof')}
                    </PrimaryLink>
                </div>

                <PrimaryButton
                    onClick={() => history.replace(duplicateTransactionsPath)}
                >
                    {t('show_page.duplicate')}
                </PrimaryButton>
            </PageFooter>
        </>
    )
}

export default Success
