import PageLayout from 'layout/PageLayout';
import transactionsPathHelper from 'helper/pathHelper/transactions';
import PageContentWithAppLogo from 'layout/PageContentWithAppLogo';
import PageFooter from 'layout/PageFooter';
import PrimaryButton from 'components/PrimaryButton';
import { useTranslation } from 'react-i18next';
import PrimaryLink from 'components/PrimaryLink';
import {
    TextContainer,
    CompleteMyInfoContainer,
} from './styled.js'
import userSettingsPathHelper from 'helper/pathHelper/userSettings';
import appPathHelper from 'helper/pathHelper/app';
import { NSDetailLabel, NSTitleLabel } from 'components/BaseLabels/index.js';
import CheckCircle from '@mui/icons-material/CheckCircle';
import PageContentSmaller from 'layout/PageContentSmaller/index.js';

const AfterSetupSignupPage = () => {
    const { newTransactionsPath } = transactionsPathHelper()
    const { t: userT } = useTranslation('user')
    const { t: appT } = useTranslation('application')
    const { indexUserSettingsPath } = userSettingsPathHelper()
    const { rootPath } = appPathHelper()

    return (
        <PageLayout>
            <PageContentSmaller>
                <PageContentWithAppLogo
                    pageName='afterSetup'
                    body={
                        <>
                            <div>
                                <CheckCircle style={{ fontSize: '48px', color: 'var(--ion-color-primary-greeny-blue)' }} />
                            </div>

                            <TextContainer className='text-margin'>
                                <NSTitleLabel>
                                    {userT('after_setup_sign_up_page.welcome')}
                                </NSTitleLabel>
                                <NSDetailLabel>
                                    {userT('after_setup_sign_up_page.finalize_info_or_create')}
                                </NSDetailLabel>
                            </TextContainer>

                            <CompleteMyInfoContainer className='text-margin'>
                                <PrimaryLink
                                    to={indexUserSettingsPath}>
                                    {userT('after_setup_sign_up_page.complete_my_info')}
                                </PrimaryLink>
                            </CompleteMyInfoContainer>
                        </>
                    }
                />
            </PageContentSmaller>

            <PageFooter>
                <div className='ion-margin-bottom'>
                    <PrimaryLink
                        to={rootPath}>
                        {appT('close')}
                    </PrimaryLink>
                </div>

                <PrimaryButton routerLink={newTransactionsPath}>
                    {appT('create')}
                </PrimaryButton>
            </PageFooter>

        </PageLayout>
    );
};

export default AfterSetupSignupPage;
