import ContentList from 'components/ContentList';
import ContentListItem from 'components/ContentListItem';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import PrimaryButton from 'components/PrimaryButton';
import RequestErrorMessages from 'components/RequestErrorMessages';
import onErrorRedirect from 'helper/onErrorRedirect';
import bankAccountsPathHelper from 'helper/pathHelper/bankAccounts';
import PageContent from 'layout/PageContent';
import PageFooter from 'layout/PageFooter';
import PageHeader from 'layout/PageHeader';
import PageLayout from 'layout/PageLayout';
import { useTranslation } from 'react-i18next';
import { useGetBankAccountsQuery } from 'store/services/bankAccounts';
import { LogoWrapper } from './styled.js'
import { styledGetPrimaryResourceColor } from 'helper/applicationHelper.js';
import { friendlyFormatIBAN } from 'ibantools';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import AccountBalance from '@mui/icons-material/AccountBalance'
import { DetailLabel, TitleLabel } from 'components/BaseLabels/index.js';
import { GridContainer } from 'layout/PageContent/styled.js';
import PrimaryBackLink from 'components/PrimaryBackLink/index.js';

const BankAccountItem = ({ bankAccount }) => {
    const {
        iban,
        id,
        primary,
        logo_url,
        bank_name,
        bank_account_name
    } = bankAccount
    const { showBankAccountsPath } = bankAccountsPathHelper(id)

    return <ContentListItem
        withBorder
        routerLink={showBankAccountsPath}
    >
        <GridContainer>
            <IonRow className='ion-justify-content-center ion-align-items-center'>
                <IonCol size='11'>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonGrid>
                                    <IonRow className='ion-align-items-center'>
                                        <IonCol size='2'>
                                            {
                                                logo_url
                                                    ?
                                                    <LogoWrapper>
                                                        <img
                                                            src={logo_url}
                                                            alt=""
                                                        />
                                                    </LogoWrapper>

                                                    :
                                                    <AccountBalance style={{ fontSize: '26px', color: 'var(--ion-color-text-primary)' }} />
                                            }

                                        </IonCol>
                                        <IonCol>
                                            <TitleLabel>
                                                {bank_name || '\u00A0'}
                                            </TitleLabel>

                                            <DetailLabel className='ion-sm-margin-top'>
                                                {bank_account_name || '\u00A0'}
                                            </DetailLabel>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol>
                                <DetailLabel className='ion-sm-margin-top'>
                                    {friendlyFormatIBAN(iban)}
                                </DetailLabel>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>

                <IonCol size='1'>
                    <FavoriteBorder style={{ fontSize: '16px', color: styledGetPrimaryResourceColor({ primary }) }} />
                </IonCol>
            </IonRow>
        </GridContainer>
    </ContentListItem>
}

const BankAccountsIndexPage = () => {
    const {
        indexBankAccountsPath,
        newBankAccountsPath,
    } = bankAccountsPathHelper()
    const {
        data,
        isSuccess,
        error,
        isFetching
    } = useGetBankAccountsQuery({ url: indexBankAccountsPath })
    const { t } = useTranslation(['bank_account', 'application'])

    let RenderContent
    let RenderCreateButton
    if (isSuccess) {
        RenderContent = <ContentList>
            {
                data.data.map(bankAccount => <BankAccountItem
                    bankAccount={bankAccount}
                    key={bankAccount.id} />
                )
            }
        </ContentList>
        if (data.extra.can_create_more) {
            RenderCreateButton = <div>
                <PrimaryButton routerLink={newBankAccountsPath}>
                    {t('index_page.new_bank_account')}
                </PrimaryButton>
            </div>
        }
    } else {
        if (onErrorRedirect(error)) return onErrorRedirect(error)
    }

    return (
        <PageLayout>

            <PageHeader
                title={t('index_page.title')}
                withBackButton
            />

            <PageContent loading={isFetching}>
                <RequestErrorMessages error={error} />
                {RenderContent}
            </PageContent>

            <PageFooter>
                <div className='ion-margin-bottom'>
                    <PrimaryBackLink>
                        {t('cancel')}
                    </PrimaryBackLink>
                </div>

                {RenderCreateButton}
            </PageFooter>
        </PageLayout >
    );
};

export default BankAccountsIndexPage;
