import { useIonAlert } from '@ionic/react'
import clsx from 'clsx'
import './style.scss'

/*
For more information
https://ionicframework.com/docs/api/alert
*/
const useCustomIonAlert = () => {
    const [present, dismiss] = useIonAlert()

    /*
    @params[:options] is the same as https://ionicframework.com/docs/api/alert#alertoptions
    */
    const customPresent = (options) => {
        present({
            mode: "ios",
            ...options,
            cssClass: clsx('custom-ion-alert', options.cssClass),
        })
    }

    return [customPresent, dismiss]
}

export default useCustomIonAlert
