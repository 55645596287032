import { Container } from "./styled"

const TransactionPaymentLink = ({ status, link }) => {
    return (
        <Container status={status}>
            {link}
        </Container>
    )
}

export default TransactionPaymentLink
