import PageContent from 'layout/PageContent';
import PageLayout from 'layout/PageLayout';
import { useHistory } from 'react-router-dom'

const PageNotFound = () => {
    const history = useHistory();

    const handleReturnToHomePage = () => {
        history.replace('/')
    }

    return (
        <PageLayout>
            <PageContent>
                <div>
                    Sorry, The page you are looking for is not found.
                    <button onClick={handleReturnToHomePage}>
                        Return to home page.
                    </button>
                </div>
            </PageContent>
        </PageLayout>
    )
}

export default PageNotFound
