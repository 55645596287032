import { useHistory } from 'react-router-dom';
import PageLayout from "layout/PageLayout";
import usersPathHelper from 'helper/pathHelper/users';
import { useUpdateUserPasswordMutation } from 'store/services/users';
import useRedirectIfAlreadyAuth from 'hooks/useRedirectIfAlreadyAuth';
import produce from "immer"
import { getURLSearchParamsHelper } from 'helper/URLSearchParamsHelper';
import { UserResetPasswordForm } from 'forms/usersForm';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import PageContentWithAppLogo from 'layout/PageContentWithAppLogo';
import PageFooter from 'layout/PageFooter';
import PrimaryButton from 'components/PrimaryButton';
import { useTranslation } from 'react-i18next';
import RequestErrorMessages from 'components/RequestErrorMessages';
import { NSDetailLabel, NSTitleLabel } from 'components/BaseLabels';
import { FormWrapper } from './styled'
import PageContentSmaller from 'layout/PageContentSmaller/index.js';

const ResetPasswordPage = () => {
    useRedirectIfAlreadyAuth()
    const {
        updateUserPasswordPath,
        newUserSessionsPath
    } = usersPathHelper()
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const history = useHistory();
    const [updateUserPassword, { isLoading, error }] = useUpdateUserPasswordMutation()
    const { t: userT } = useTranslation('user')
    const { t: appT } = useTranslation('application')

    const handleSubmit = async (data) => {
        const reset_password_token = getURLSearchParamsHelper('reset_password_token')
        const body = produce(data, draftState => {
            draftState.user.reset_password_token = reset_password_token
            draftState.reset_password_token = reset_password_token
        })
        try {
            await updateUserPassword({
                url: updateUserPasswordPath,
                body
            }).unwrap()
            history.replace(newUserSessionsPath)
        } catch (error) {
            console.error('ForgotPasswordPage#handleSubmit', error)
        }
    }
    return (
        <PageLayout>
            <PageContentSmaller>
                <PageContentWithAppLogo body={
                    <>
                        <RequestErrorMessages error={error} />

                        <div className='text-margin'>
                            <NSTitleLabel>
                                {userT('reset_password_page.new_password')}
                            </NSTitleLabel>

                            <NSDetailLabel>
                                {userT('reset_password_page.enter_new_password')}
                            </NSDetailLabel>
                        </div>

                        <FormWrapper>
                            <UserResetPasswordForm
                                submitButtonRef={submitButtonRef}
                                onSubmit={handleSubmit}
                            />
                        </FormWrapper>
                    </>
                }
                />
            </PageContentSmaller>
            <PageFooter>
                <PrimaryButton
                    onClick={handleClick}
                    disabled={isLoading}
                >
                    {appT('submit')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
};

export default ResetPasswordPage;
