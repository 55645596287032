import { useFormikContext, getIn } from 'formik';
// import './style.scss'
import DateTimePicker from 'components/inputs/DateTimePicker'
import dayjs from 'dayjs'
import { reverseFormatDate } from 'helper/dateHelper';


const DatePicker = ({
    name,
    ...rest
}) => {

    const {
        values,
        setFieldValue,
        touched,
        errors,
    } = useFormikContext();
    const formatValue = getIn(values, name).format('DD/MM/YYYY')

    const handleChange = (value) => {
        const reverseDate = reverseFormatDate(value)
        setFieldValue(name, dayjs(reverseDate))
    }

    return (
        <DateTimePicker
            {...rest}
            name={name}
            value={formatValue}
            error={getIn(touched, name) && Boolean(getIn(errors, name))}
            helperText={getIn(touched, name) && getIn(errors, name)}
            onChange={handleChange}
            iconColor='var(--ion-color-text-secondary)'
            sx={{
                // Input text that the user is entering.
                '& input': {
                    color: 'var(--ion-color-text-secondary)',
                    '::placeholder': {
                        color: 'var(--ion-color-text-primary)',
                        opacity: 1 /* Firefox */
                    },
                    ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
                        color: 'var(--ion-color-text-primary)'
                    },
                    '::-ms-input-placeholder': { /* Microsoft Edge */
                        color: 'var(--ion-color-text-primary)'
                    },
                    "&.Mui-disabled": {
                        textFillColor: 'var(--ion-color-text-secondary)'
                    }
                },

                // Outline
                '& .MuiInput-underline': {
                    '&:after': {
                        borderBottomColor: 'var(--ion-color-text-secondary)'
                    },
                    '&:before': {
                        borderBottomColor: 'var(--ion-color-text-secondary)',
                    },
                    '&:hover::before': {
                        borderBottom: '1px solid var(--ion-color-text-secondary) !important'
                    }
                },

            }}
        />
    )
}

export default DatePicker
