import { USER_DEVICES_BASE_PATH } from 'constants/routeConstants'
import basePathHelper from './_base'

const userDevicesPathHelper = () => {
    return {
        ...basePathHelper(USER_DEVICES_BASE_PATH),
    }
}

export default userDevicesPathHelper
