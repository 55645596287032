import {
  USERS_BASE_PATH,
  USER_INVITATIONS_BASE_PATH,
} from "constants/routeConstants";
import basePathHelper from "./_base";

// Please visit ./_base.js for more information.

const userInvitationsPathHelper = (id) => {
  return {
    ...basePathHelper(USER_INVITATIONS_BASE_PATH, id),
  };
};

export const userApiInvitationsPathHelper = () => {
  return {
    ...basePathHelper(USERS_BASE_PATH),
    // @Used for: internal
    createInvitationPath: `/${USERS_BASE_PATH}/invitation`,
  };
};

export default userInvitationsPathHelper;
