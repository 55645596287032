import PropTypes from 'prop-types'
import { IonPage } from '@ionic/react'
import clsx from 'clsx'

import './style.scss'

const PageLayout = ({
    children,
    withSafeAreaTop,
    withSafeAreaBottom,
    className
}) => {
    return (
        <IonPage
            className={clsx(
                withSafeAreaTop && 'with-safe-area-top',
                withSafeAreaBottom && 'with-safe-area-bottom',
                className
            )}
        >
            {children}
        </IonPage>
    )
}

PageLayout.propTypes = {
    children: PropTypes.node,
    withSafeAreaTop: PropTypes.bool,
    withSafeAreaBottom: PropTypes.bool,
    className: PropTypes.string
}

export default PageLayout
