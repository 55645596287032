import {
    baseSplitApi,
    baseGetResourcesProvidesTags,
} from './_base'

export const bridgeapiBanksSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getBridgeapiBanks: builder.query({
            query: config => config,
            providesTags: baseGetResourcesProvidesTags
        }),
    }),
})

export const {
    useGetBridgeapiBanksQuery,
} = bridgeapiBanksSlice
