import { NSDetailLabel } from "components/BaseLabels"
import CheckCircle from "@mui/icons-material/CheckCircle"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import PageContentSmaller from "layout/PageContentSmaller"

const ClientPaymentSuccessPage = () => {
    const { t } = useTranslation('client_payment')
    return (
        <PageLayout>
            <PageContentSmaller>
                <PageContentWithAppLogo
                    body={
                        <>
                            <div>
                                <CheckCircle style={{ fontSize: '32px', color: 'var(--ion-color-primary-greeny-blue)' }} />
                            </div>

                            <div className="text-margin">
                                <NSDetailLabel>
                                    {t('success_page.message')}
                                </NSDetailLabel>
                            </div>

                        </>
                    }
                />
            </PageContentSmaller>
        </PageLayout>
    )
}

export default ClientPaymentSuccessPage
