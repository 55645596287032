import styled from "styled-components";

export const Wrapper = styled.div`
margin-top: 20px;
margin-bottom: 20px;
`
export const Value = styled.div`
color: var(--ion-color-secondary-greeny-blue);
font-size: 14px
`
