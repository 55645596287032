import styled from "styled-components";
import { styled as mStyled } from '@mui/material/styles';
import { FormControl } from "@mui/material";

export const WrapperAsterisk = styled.div`
&:after {
    position: absolute;
    content: " *";
    color: red;
    right: -19px;
    font-size: 29px;
}
`

export const CenterPhoneInput = styled.input`
width: 100%;
text-align: center;
font-size: 20px;der-color: var(--ion-color-primary-greeny-blue);
background: var(--ion-background-color);
height: 30px;
border-top : 0;
border-left : 0;
border-right : 0;
border-width: ${({ error }) => error ? '2px;' : 'thin;'}
border-color: ${({ error }) => error ? 'var(--ion-color-text-phone-error);' : 'rgba(0,0,0,0.87);'}
outline: 0;
&:focus-visible {
    border-width: 2px;
    border-color: ${({ error }) => error ? 'var(--ion-color-text-phone-error);' : 'var(--ion-color-primary-greeny-blue);'}
}
padding-bottom: 7px;
`

export const CssFormControl = mStyled(FormControl)({
    marginTop: '8px',

    "& .iti__flag-container": {
        paddingBottom: '7px'
    },

    "& .MuiFormHelperText-root": {
        textAlign: 'center'
    }
})
