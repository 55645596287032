import { IonLabel } from '@ionic/react'

import { Button, Title } from './styled.js'

const QueryButton = ({
    title,
    Icon,
    renderIcon,
    ...rest
}) => {
    return (
        <div>
            <Button {...rest}>
                <IonLabel>
                    {renderIcon && renderIcon()}

                    <Title>
                        {title}
                    </Title>
                </IonLabel>
            </Button>
        </div>
    )
}

export default QueryButton
