import { Form, useField } from 'formik'
import HiddenSubmitButton from 'components/HiddenSubmitButton';
import { useTranslation } from 'react-i18next';
import SubscriptionSelectField, { tempSubscriptionSelectField } from 'components/subscriptions/SubscriptionSelectField';
import { Bold, Detail, Title } from "./styled"
import transactionsPathHelper from 'helper/pathHelper/transactions';
import { useInThisMonthQuery } from 'store/services/transactions';
import { formatNumberToCurrency } from 'helper/numberHelper';
import dayjs from 'dayjs';

const calculatedTotalPrice = (
    numberOfTransaction = 0,
    monthlySubscriptionPrice,
    flatRateTransaction,
    additionalTransactionPrice,
    skipMonthlyPrice,
    expireSkipMonthlyPriceDate
) => {
    // Calculate over price
    let overTransaction = numberOfTransaction - flatRateTransaction
    if (overTransaction < 0) {
        overTransaction = 0
    } else {
        overTransaction = (overTransaction * additionalTransactionPrice)
    }

    // Calculate monthly price
    const expireDate = dayjs(expireSkipMonthlyPriceDate).startOf('day')
    const currentDate = dayjs().startOf('day')
    const inRangeSkipMonthlyPrice = expireDate.diff(currentDate) > 0
    let monthlyPrice
    if (inRangeSkipMonthlyPrice && skipMonthlyPrice) {
        monthlyPrice = 0
    } else {
        monthlyPrice = monthlySubscriptionPrice
    }

    return monthlyPrice + overTransaction
}

const Index = ({ submitButtonRef }) => {
    const { t } = useTranslation('subscription')
    const userSubPlanName = "user_subscription.plan_id"
    const [, meta,] = useField(userSubPlanName)
    const selected = meta.value
    const menuList = tempSubscriptionSelectField()
    const selectedItem = menuList[selected - 1]

    const { inThisMonthTransactionsPath } = transactionsPathHelper()
    const { data } = useInThisMonthQuery({ url: inThisMonthTransactionsPath })
    const numberOfTransaction = data?.data?.transactions?.length
    const subscribed_at = data?.data?.subscribed_at
    const transactions = data?.data?.transactions
    const isPercentageItemSelected = (selected === 3) || (selected === 4)

    let totalPrice
    switch (selected) {
        case 3:
            totalPrice = calculatedTotalPricePercentage(transactions, selectedItem.price)
            break;
        case 4:
            totalPrice = calculateTotalPricePrivateIndividuals(transactions, selectedItem.price)
            break;
        default:
            totalPrice = calculatedTotalPrice(
                numberOfTransaction,
                selectedItem.price,
                selectedItem.flat_rate_transaction,
                selectedItem.additional_transaction_price,
                selectedItem.skip_monthly_price,
                subscribed_at
            )
    }

    return (
        <Form>
            <SubscriptionSelectField
                subscribed_at={subscribed_at}
                name={userSubPlanName}
            />

            <Title>
                {t('subscribe_page.current_month_detail')}
            </Title>

            <Detail>
                {t('subscribe_page.number_of_receipts')} : <Bold>{numberOfTransaction}</Bold>
            </Detail>

            {
                isPercentageItemSelected && (
                    <Detail>
                        {t('subscribe_page.total_amount_collect')} : <Bold>{formatNumberToCurrency(getTotalTransactionAmount(transactions))}</Bold>
                    </Detail>
                )
            }

            <Detail>
                {t('subscribe_page.estimated_cost')} : {formatNumberToCurrency(totalPrice)}
            </Detail>

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

function getTotalTransactionAmount(transactions = []) {
    return transactions.reduce((price, currentTransaction) => {
        return price + Number(currentTransaction.amount)
    }, 0)
}

function calculatedTotalPricePercentage(
    transactions = [],
    monthlySubscriptionPricePercentage
) {
    if (transactions) {
        const sumPrice = getTotalTransactionAmount(transactions)
        const percentagePrice = (sumPrice * monthlySubscriptionPricePercentage) / 100 // Make it percentage

        return percentagePrice
    }

    return 0
}

function calculateTotalPricePrivateIndividuals(
    transactions = [],
    monthlySubscriptionPricePercentage
) {
    const targetTransactions = transactions.slice(5)

    if (targetTransactions.length > 0) {
        const sumPrice = getTotalTransactionAmount(targetTransactions)
        const percentagePrice = (sumPrice * monthlySubscriptionPricePercentage) / 100 // Make it percentage

        return percentagePrice
    }

    return 0
}

export default Index
