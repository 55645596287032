import PropTypes from 'prop-types'
import DefualtTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const CssTextField = styled(DefualtTextField)(({ value, appendasterisk }) => {

    const asterisk = appendasterisk ? (
        {
            '&:after': {
                content: "'*'",
                color: 'red',
                marginRight: '-20px',
                marginTop: '8px',
                fontSize: '29px'
            }
        }
    ) : {}

    return (
        {
            // helperText
            '& label': {
                fontSize: '14px',
            },
            // Input text that the user is entering.
            '& input': {
                'color': value ? 'var(--ion-color-text-primary)' : 'var(--ion-color-text-phone-placeholder)',
                fontSize: '14px',
                '::placeholder': {
                    color: 'var(--ion-color-text-primary)',
                    opacity: 1 /* Firefox */
                },
                ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
                    color: 'var(--ion-color-text-primary)'
                },
                '::-ms-input-placeholder': { /* Microsoft Edge */
                    color: 'var(--ion-color-text-primary)'
                },
            },
            // Errors text.
            '& p': {
                fontSize: '14px',
            },

            '& .MuiOutlinedInput-root': {
                background: 'var(--ion-background-color)',
                borderRadius: '15px',
                height: '30px',

                ...asterisk,

                '& fieldset': {
                    borderColor: 'var(--ion-color-primary-greeny-blue)',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--ion-color-primary-greeny-blue)',
                }
            },
        }
    )
});

const TextField = ({
    value = '',
    multiline = false,
    ...rest
}) => {
    return (
        <CssTextField
            autoComplete="off"
            fullWidth
            value={value}
            multiline={multiline}
            {...rest}
        />
    )
}

TextField.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    multiline: PropTypes.bool
}

export default TextField
