import { IonModal } from "@ionic/react";
import styled from "styled-components";

export const ModalContainer = styled(IonModal)`
--width: auto;
--height: auto;

--border-radius: 0;
box-shadow: 0 3px 6px #00000029;

&::part(content) {
    padding: 16px;

    min-width: 250px;
    max-width: 300px;

    min-height: 250px;
}
`
