import { Wrapper } from 'components/ContentList/styled'
import { Container, Title } from './styled'
import { useTranslation } from 'react-i18next';
import { TransactionItem } from 'pages/transactions/TransactionsIndexPage';

const LatestOtherTransactions = ({ transactions }) => {
    const { t } = useTranslation(['home', 'application'])
    let RenderContent = null
    if (transactions.length) {
        RenderContent = <Wrapper>
            <Title isSecondMarginInline>
                {t('latest_transactions')}
            </Title>
            <Container isSecondMarginInline>

                {
                    transactions.map((transaction) => <TransactionItem
                        marginBottom='5px'
                        key={transaction.id}
                        transaction={transaction}
                    />
                    )
                }
            </Container>
        </Wrapper>
    }

    return RenderContent
}

export default LatestOtherTransactions
