import styled from "styled-components"

export const Detail = styled.span`
color: var(--ion-color-text-primary);
font-size: 14px;
`

export const Title = styled(Detail)`
font-weight: var(--ion-font-weight-bold);
`

export const AcceptLabelContainer = styled.div`
padding-top: 10px;
`