import { useHistory } from 'react-router-dom';
import PageLayout from "layout/PageLayout";
import usersPathHelper from 'helper/pathHelper/users';
import { useCreateUserPasswordMutation } from 'store/services/users';
import useRedirectIfAlreadyAuth from 'hooks/useRedirectIfAlreadyAuth';
import { UserForgotPasswordForm } from 'forms/usersForm';
import useSubmitButtonRef from 'hooks/useSubmitButtonRef';
import PageContentWithAppLogo from 'layout/PageContentWithAppLogo';
import PageFooter from 'layout/PageFooter';
import PrimaryButton from 'components/PrimaryButton';
import { useTranslation } from 'react-i18next';
import {
    FormWrapper,
    CancelContainer,
} from './styled.js'
import PrimaryBackLink from 'components/PrimaryBackLink';
import RequestErrorMessages from 'components/RequestErrorMessages';
import { NSDetailLabel, NSTitleLabel } from 'components/BaseLabels/index.js';
import PageContentSmaller from 'layout/PageContentSmaller/index.js';

const ForgotPasswordPage = () => {
    useRedirectIfAlreadyAuth();

    const {
        createUserPasswordPath,
        sendForgotUserPasswordPath
    } = usersPathHelper()
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const history = useHistory();
    const [createUserPassword, { isLoading, error }] = useCreateUserPasswordMutation()
    const { t: userT } = useTranslation('user')
    const { t: appT } = useTranslation('application')

    const handleSubmit = async (data) => {
        try {
            await createUserPassword({
                url: createUserPasswordPath,
                body: data
            }).unwrap()
            history.replace(sendForgotUserPasswordPath)
        } catch (error) {
            console.error('ForgotPasswordPage#handleSubmit', error)
        }
    }

    return (
        <PageLayout>
            <PageContentSmaller>
                <PageContentWithAppLogo
                    body={
                        <>
                            <RequestErrorMessages error={error} />

                            <div className='text-margin'>
                                <NSTitleLabel>
                                    {userT('forgot_password_page.forgot_your_password')}
                                </NSTitleLabel>

                                <NSDetailLabel>
                                    {userT('forgot_password_page.enter_email_to_send')}
                                </NSDetailLabel>
                            </div>

                            <FormWrapper>
                                <UserForgotPasswordForm
                                    submitButtonRef={submitButtonRef}
                                    onSubmit={handleSubmit}
                                />
                            </FormWrapper>

                            <CancelContainer>
                                <PrimaryBackLink>
                                    {appT('cancel')}
                                </PrimaryBackLink>
                            </CancelContainer>

                        </>
                    }
                />
            </PageContentSmaller>

            <PageFooter>
                <PrimaryButton
                    onClick={handleClick}
                    disabled={isLoading}
                >
                    {appT('send')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
}

export default ForgotPasswordPage;
