import { useEffect, useMemo } from 'react'
import useAfterFirstMountedEffect from './useAfterFirstMountedEffect'
import useCurrentIndexPageConfig from './useCurrentIndexPageConfig';
import usePrevLocation from 'hooks/usePrevLocation.js'

function serializeQuery(params, prefix) {
    const query = Object.keys(params).map((key) => {
        const value = params[key];

        if (params.constructor === Array)
            key = `${prefix}[]`;
        else if (params.constructor === Object)
            key = (prefix ? `${prefix}[${key}]` : key);

        if (typeof value === 'object')
            return serializeQuery(value, key);
        else
            return `${key}=${encodeURIComponent(value)}`;
    });

    return [].concat.apply([], query).join('&');
}

const useIndexPagesConfig = ({
    stateKey,
    api,
    url,
}) => {
    const [pagesConfig, helpers] = useCurrentIndexPageConfig(stateKey)
    const [trigger, result] = api()

    const queryOptions = useMemo(() => {
        return {
            url,
            params: serializeQuery({
                page: pagesConfig.page,
                q: pagesConfig.q
            })
        }
    }, [
        url,
        pagesConfig.page,
        pagesConfig.q,
        pagesConfig.timestamp, // Upon resetPage dispatch, timestamp will be changed. Include it to deps to trigger change.
    ])

    // First mounted
    useEffect(() => {
        if (pagesConfig.shouldRefetch) {
            trigger(queryOptions)
        }
    }, [])

    // The user change sort or search option
    useAfterFirstMountedEffect(() => {
        trigger(queryOptions)
    }, [queryOptions])

    const [prevLocation,] = usePrevLocation()

    useAfterFirstMountedEffect(() => {
        const associatedPathReg = new RegExp(url)
        const isFromAssociatedPath = prevLocation.pathname.match(associatedPathReg)
        // If not already triggered fetch in the first mount 
        // and not come from any associated path then refetch.
        // Example of associated path.
        // if current path is '/transactions'
        // then associated paths are '/transactions/1', '/transactions/1/duplicate' etc.
        if (!pagesConfig.shouldRefetch && !isFromAssociatedPath) {
            helpers.resetPage()
        }
    }, [prevLocation.pathname, url, helpers.resetPage])

    return useMemo(() => {
        return [
            pagesConfig,
            helpers,
            result,
        ]
    }, [
        pagesConfig,
        helpers,
        result,
    ])
}

export default useIndexPagesConfig
