import { Container } from './styled.js'
import PropTypes from 'prop-types'


const PrimaryButton = ({
    children,
    toUpperText = true,
    ...rest
}) => {
    return (
        <Container toUpperText={toUpperText} {...rest}>
            {children}
        </Container>
    )
}

PrimaryButton.propTypes = {
    toUpperText: PropTypes.bool,
    isInvert: PropTypes.bool
}

export default PrimaryButton;
