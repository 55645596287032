import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import BaseField from 'components/fields/BaseField';
import { useState } from "react";

const PasswordField = ({ ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <BaseField
            {...props}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: <InputAdornment position="end" >
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                    >
                        {showPassword ? <Visibility style={{ color: 'var(--ion-color-primary-greeny-blue)' }} /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment >

            }}

        />
    )
}

export default PasswordField
