import { CircularProgress } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { CssTextField, CssAutocomplete } from "./styled"
import { useLazyGetUserClientsQuery } from 'store/services/userClients';
import userClientsPathHelper from 'helper/pathHelper/userClients';
import { useTranslation } from 'react-i18next';
import { useFormikContext, getIn } from 'formik';
import { decorateEmailString } from "helper/stringHelper";
import SearchIcon from '@mui/icons-material/Search';

const UserClientField2 = () => {
    const { t } = useTranslation(['transaction', 'application'])

    // State and Data
    const [open, setOpen] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({ "id": null, "full_name": '' })

    // Formik
    const { setFieldValue, values, ...form } = useFormikContext();
    const form_id = 'transaction_form.user_client_id'
    const form_name = 'transaction_form.full_name'
    const clientId = getIn(values, form_id)
    const clientName = getIn(values, form_name)

    // API
    const {
        indexUserClientsPath
    } = userClientsPathHelper()
    const [trigger, result] = useLazyGetUserClientsQuery()
    const { isSuccess, isFetching } = result

    // Ref : Use this with 'blur()' to hide virtual keyboard after selected
    const autoCompleteTextFieldRef = useRef()

    // Render
    const renderInput = (params) => {
        return (
            <CssTextField
                {...params}
                placeholder={t('new_page.full_name')}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                            {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </>
                    ),
                }}
                name={form_name}
                autoComplete="off"
                inputRef={autoCompleteTextFieldRef}
                error={getIn(form.touched, form_id) && Boolean(getIn(form.errors, form_id))}
                helperText={getIn(form.touched, form_id) && getIn(form.errors, form_id)}
            />
        )
    }

    useEffect(() => {
        if (open && !apiCalled) {
            setApiCalled(true)
            trigger({ url: indexUserClientsPath })
        } else {
            if (!isFetching && isSuccess) {
                const data = result.data.data ? result.data.data.map(datum => datum) : []
                setOptions(data);
            }
        }
    }, [open, isFetching]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
            setApiCalled(false)
        }
    }, [open]);

    useEffect(() => {
        if (clientId && clientName) {
            handleSetSelectedOption(clientId, clientName)
        }
    }, [
        clientId,
        clientName,
    ])

    const handleSetSelectedOption = (rawId, RawFullName) => {
        const id = rawId ?? null
        const full_name = RawFullName ?? ''
        setSelectedOption({ id, full_name })
    }

    return (
        <CssAutocomplete
            value={selectedOption}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.full_name}
            options={options}
            loadingText={t('loading')}
            loading={isFetching}
            onChange={(e, value) => {
                handleSetSelectedOption(value?.id, value?.full_name)
                setFieldValue(form_id, value?.id || '')
                setFieldValue(form_name, value?.full_name || '')
                autoCompleteTextFieldRef.current.blur()
            }}
            noOptionsText={t('new_page.no_data')}
            renderInput={renderInput}
            popupIcon={<SearchIcon />}
            freeSolo // To remove warning
            forcePopupIcon={true} // if use freeSolo prop, it will hide icon, so we must use this prop
        />
    )
}

export default UserClientField2
