import { Checkbox, FormControlLabel } from "@mui/material"
import { getIn, useFormikContext } from "formik"

const CheckboxField = ({ name, label, ...rest }) => {

    const {
        setFieldValue,
        setFieldTouched,
        values,
    } = useFormikContext()

    const value = getIn(values, name)

    const handleChange = (event) => {
        setFieldTouched(name)
        setFieldValue(name, event.target.checked)
    };
    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={name}
                    size="small"
                    sx={{
                        color: "#ffffff",
                        '&.Mui-checked': {
                            color: "#ffffff",
                        },
                    }}
                    checked={value}
                    onChange={handleChange}
                />
            }
            label={label}
            sx={{
                '.MuiTypography-root': {
                    color: "#ffffff",
                    fontSize: 10
                }
            }}
        />
    );
}

export default CheckboxField
