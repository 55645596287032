import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from './NotificationPreferencesForm'
import { BaseFormikConfig } from 'forms/_baseForm'
import {
    email_notification,
    app_notification,
} from '../userPreferencesForm/validation';

const FormValidationSchema = Yup
    .object()
    .shape({
        user_preference: Yup.object().shape({
            email_notification,
            app_notification,
        })
    })

const FormikConfig = {
    ...BaseFormikConfig,
    mapPropsToValues: ({ data }) => ({
        user_preference: {
            email_notification: data.email_notification,
            app_notification: data.app_notification,
        }
    }),
    validationSchema: FormValidationSchema,
}

export default withFormik(FormikConfig)(Form)
