import styled from "styled-components";

export const Title = styled.div`
margin-top: 59px;
font-size: 14px;
font-weight: bold;
color: var(--ion-color-text-primary);
text-align: start;
padding-bottom: 4px;
border-bottom: 2px solid var(--ion-color-text-primary);
`

export const Detail = styled.div`
font-size: 14px;
color: var(--ion-color-text-primary);
${({ has_margin_top = true }) => has_margin_top && 'margin-top: 22.5px;'}
`

export const Bold = styled.span`
font-weight: bold;
`
