import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import clsx from 'clsx';
import { DetailLabel } from 'components/BaseLabels';

export const NSTitleLabelTwo = styled(DetailLabel)`
font-weight: var(--ion-font-weight-semi-bold);
font-size: 14px;
letter-spacing: 1px;
margin-top: -36px;
`
// Implement based on: https://mui.com/material-ui/react-stepper/#customized-horizontal-stepper

const roundIconSize = 19
const halfRoundIconSize = roundIconSize / 2

const RoundConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: halfRoundIconSize - 1,
    left: `calc(-50% + ${halfRoundIconSize}px)`,
    right: `calc(50% + ${halfRoundIconSize}px)`,
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'var(--ion-color-text-primary)',
    borderTopWidth: 3,
  },
}));

const RoundStepIconRoot = styled('div')(() => ({
  display: 'flex',
  width: roundIconSize,
  height: roundIconSize,
  alignItems: 'center',
  '& .RoundStepIcon-circle': {
    width: roundIconSize,
    height: roundIconSize,
    borderRadius: '50%',
    backgroundColor: '#C5C5C5',
    '&--active': {
      backgroundColor: 'var(--ion-color-primary-greeny-blue)',
    }
  },
}));

const StepperWrapper = styled('div')(() => ({
  marginTop: "min(calc(5.04vh + 1.68vw), 60px)"
}));

function RoundStepIcon(props) {
  const {
    className,
    icon,
    currentStep,
  } = props;

  return (
    <RoundStepIconRoot
      className={className}
    >
      <div className={clsx("RoundStepIcon-circle", icon === currentStep && "RoundStepIcon-circle--active")} />
    </RoundStepIconRoot>
  );
}

RoundStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const Index = ({
  currentStep,
  maxSteps,
}) => {
  let RenderContent = []
  for (let i = 0; i < maxSteps; i++) {
    RenderContent.push(
      <Step key={i}>
        <StepLabel
          StepIconComponent={RoundStepIcon}
          StepIconProps={{ currentStep }}
        />
      </Step>
    )
  }

  return (
    <StepperWrapper>
      <Stack sx={{ width: '100%' }} spacing={1}>
        <NSTitleLabelTwo>
          {currentStep}/{maxSteps}
        </NSTitleLabelTwo>

        <Stepper
          alternativeLabel
          activeStep={currentStep}
          connector={<RoundConnector />}
        >
          {RenderContent}
        </Stepper>
      </Stack>
    </StepperWrapper>
  );
}
export default Index
