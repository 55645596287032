import { useField } from 'formik'
import BaseField from 'components/fields/BaseField'
import { InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TextField from '../DatePicker/styled'
import SubAmountFieldMask from './SubAmountFieldMask'
import { preventArrowUpdateNumberOnKeyDown } from 'helper/inputHelper'

const SubAmountField = ({ name, disabled }) => {
    const [, sub_amount_meta, sub_amounts_helpers] = useField(name)
    const value = sub_amount_meta.value ? sub_amount_meta.value.toString() : ""

    return (
        <BaseField
            name={name}
            Component={TextField}
            InputProps={{
                inputComponent: SubAmountFieldMask,
                endAdornment: (
                    <InputAdornment
                        position="end"
                        sx={{
                            '.MuiTypography-root': {
                                fontWeight: 'bold',
                                color: 'white',
                            }
                        }}
                    >
                        €
                    </InputAdornment>
                ),
                style: {
                    alignSelf: 'center',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: 'white',
                }
            }}
            type="number"
            InputLabelProps={{
                shrink: true,
                color: 'var(--ion-color-text-secondary)',
                '&::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: 'white',
                },
            }}
            disabled={disabled}
            className='date-amount-container__amount'
            value={value}
            onKeyDown={preventArrowUpdateNumberOnKeyDown}
            onChange={(newValue, maskNewValue) => {
                const floatValue = newValue ? parseFloat(newValue) : ""
                sub_amounts_helpers.setValue(floatValue)
            }}
        />
    )
}

export default SubAmountField

