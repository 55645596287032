import { Device } from '@capacitor/device';
import { store } from "store";
import { baseSplitApi } from "store/services/_base";
import {
    setDeviceUUID,
} from 'store/slices/device';
import {
    setCredentials,
    resetCredentials,
} from 'store/slices/auth';
import { AUTH_SLICE_NAME } from 'constants/sliceNameConstants';
import {
    getLocalDataHelper,
    setLocalDataHelper,
    resetLocalDataHelper,
} from 'helper/localStorageHelper';
import usersPathHelper from 'helper/pathHelper/users';
import {
    setIsReady,
} from 'store/slices/application'

const loadAuthFromStorageAndRefresh = async () => {
    const { dispatch } = store
    const { refreshTokenUsersPath } = usersPathHelper()
    const { uuid } = await Device.getId();
    dispatch(setDeviceUUID(uuid))

    const auth = await getLocalDataHelper(AUTH_SLICE_NAME);
    if (auth) {
        dispatch(setCredentials(auth))
        try {
            // We use initiate function so we will have to 
            // handle the error logic ourself
            const response = await dispatch(baseSplitApi
                .endpoints
                .refreshTokenUser
                .initiate({ url: refreshTokenUsersPath })
            )
            if (response.error) {
                console.error('Error refreshTokenUser', response.error);
                throw new Error("refreshTokenUser")
            }
            const authResponse = response.data.data

            await setLocalDataHelper(AUTH_SLICE_NAME, authResponse)

            dispatch(setCredentials(authResponse))
        } catch (error) {
            await resetLocalDataHelper()
            dispatch(resetCredentials())
            console.error('InitializedLayout', error);
        }
    }
    dispatch(setIsReady(true))
}

export default loadAuthFromStorageAndRefresh
