import { IonGrid, IonItem, IonRow } from "@ionic/react";
import styled from "styled-components";

export const Wrapper = styled(IonItem)`
--padding-start: 9px !important;
--inner-padding-end: 9px !important;
`

export const ContentContainer = styled.div`
margin-left: 12px;
margin-right: 12px;
`

export const Container = styled(IonGrid)`
margin-top: 9px;
margin-bottom: 9px;
border-radius: 9px;
background:  ${({ active }) => active ? 'var(--ion-color-primary-greeny-blue);' : 'var(--ion-color-text-secondary);'};
`

export const Label = styled(IonRow)`
margin: 8px;
text-align: center;
justify-content: center !important;
${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
${({ active }) => active && 'color: var(--ion-color-text-secondary);'}
${({ bold }) => bold && 'font-weight: bold;'}
`
export const Title = styled.div`
margin-top: 59px;
font-size: 14px;
font-weight: bold;
color: var(--ion-color-text-primary);
text-align: start;
padding-bottom: 4px;
border-bottom: 2px solid var(--ion-color-text-primary);
`

export const Detail = styled.div`
font-size: 14px;
color: var(--ion-color-text-primary);
margin-top: 22.5px
`

export const Bold = styled.span`
font-weight: bold;
`
