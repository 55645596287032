import { Form } from 'formik'
import styled from "styled-components";

export const FormContainer = styled(Form)`
display: flex;
flex-direction: column;
flex: 1;
`

export const BodyContainer = styled.div`
flex-grow: 1;
`

export const FooterContainer = styled.div`
flex-shrink: 0;
margin-bottom: 34px;
`
