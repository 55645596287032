import PageFooter from 'layout/PageFooter'

const MainPageFooter = ({ children }) => {
    return (
        <PageFooter containerProps={{ paddingBottom: "30px" }}>
            {children}
        </PageFooter>
    )
}

export default MainPageFooter
