import { NSDetailLabel } from "components/BaseLabels"
import HighlightOff from "@mui/icons-material/HighlightOff"
import { getURLSearchParamsHelper } from "helper/URLSearchParamsHelper"
import PageContentWithAppLogo from "layout/PageContentWithAppLogo"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import PageContentSmaller from "layout/PageContentSmaller"

const ClientPaymentErrorPage = () => {
    const { t } = useTranslation('transaction')
    const reason = getURLSearchParamsHelper('reason')

    let RenderErrorText
    // For more information https://docs.bridgeapi.io/docs/initiate-your-first-payment#what-to-display-when-your-callback-urls-are-called
    switch (reason) {
        case 'expired':
            RenderErrorText = t('error_reasons.expired')
            break;
        case 'already_done':
            RenderErrorText = t('error_reasons.already_done')
            break;
        case 'already_failed':
            RenderErrorText = t('error_reasons.already_failed')
            break;
        default:
            RenderErrorText = t('error_reasons.unknown')
            break;
    }

    return (
        <PageLayout>
            <PageContentSmaller>
                <PageContentWithAppLogo
                    body={
                        <>
                            <div>
                                <HighlightOff style={{ fontSize: '32px' }} />
                            </div>

                            <div className="text-margin">
                                <NSDetailLabel>
                                    {RenderErrorText}
                                </NSDetailLabel>
                            </div>
                        </>
                    }
                />
            </PageContentSmaller>
        </PageLayout>
    )
}

export default ClientPaymentErrorPage
