import PropTypes from 'prop-types'
import { Field, getIn } from 'formik'
import TextField from 'components/inputs/TextField'

const BaseField = ({
  name,
  render,
  Component = TextField,
  ...rest
}) => {

  const renderInput = ({ field, form }) => {
    if (render) {
      return render({ field, form })
    }
    else {
      return (
        <Component
          {...field}
          onChange={form.handleChange}
          value={getIn(form.values, name)}
          error={getIn(form.touched, name) && Boolean(getIn(form.errors, name))}
          helperText={getIn(form.touched, name) && getIn(form.errors, name)}
          name={name}
          {...rest}
        />
      )
    }
  }

  return (
    <Field name={name}>
      {(renderProps) => renderInput(renderProps)}
    </Field>
  )
}

BaseField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  render: PropTypes.func,
  Component: PropTypes.elementType
}

export default BaseField
