import {
    baseSplitApi,
    baseGetResourcesProvidesTags,
    baseResourceRequestTags,
    baseCreateResourceInvalidatesTags,
    baseDeleteResourceInvalidatesTags
} from './_base'

export const userClientsSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        getUserClients: builder.query({
            query: config => config,
            providesTags: baseGetResourcesProvidesTags
        }),
        getUserClient: builder.query({
            query: config => config,
            providesTags: baseResourceRequestTags
        }),
        createUserClient: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
            invalidatesTags: baseCreateResourceInvalidatesTags
        }),
        updateUserClient: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
            invalidatesTags: baseResourceRequestTags
        }),
        destroyUserClient: builder.mutation({
            query: config => ({
                ...config,
                method: 'DELETE',
            }),
            invalidatesTags: baseDeleteResourceInvalidatesTags
        }),
    }),
})

export const {
    useGetUserClientsQuery,
    useLazyGetUserClientsQuery,
    useGetUserClientQuery,
    useCreateUserClientMutation,
    useUpdateUserClientMutation,
    useDestroyUserClientMutation,
} = userClientsSlice
