import { createSlice } from '@reduxjs/toolkit'
import {
    SEARCH_TRANSACTIONS_TOGGLE_NAME,
    SORT_TRANSACTIONS_TOGGLE_NAME,
    CREATE_USER_CLIENT_TOGGLE_NAME,
    SINGLE_PAYMENT_DIALOG,
    MULTIPLE_PAYMENT_DIALOG,
    PUSH_NOTIFICATION_TOGGLE_NAME,
} from 'constants/modalNameContants'
import { TOGGLE_SLICE_NAME } from 'constants/sliceNameConstants'

const initialState = {}

const toggleNames = [
    SEARCH_TRANSACTIONS_TOGGLE_NAME,
    SORT_TRANSACTIONS_TOGGLE_NAME,
    CREATE_USER_CLIENT_TOGGLE_NAME,
    SINGLE_PAYMENT_DIALOG,
    MULTIPLE_PAYMENT_DIALOG,
    PUSH_NOTIFICATION_TOGGLE_NAME,
]

toggleNames.forEach(name => {
    initialState[name] = {
        value: false
    }
});

const modalSlice = createSlice({
    name: TOGGLE_SLICE_NAME,
    initialState,
    reducers: {
        setTrue(state, { payload }) {
            const { stateName } = payload
            state[stateName].value = true
        },
        setFalse(state, { payload }) {
            const { stateName } = payload
            state[stateName].value = false
        },
        toggle(state, { payload }) {
            const { stateName } = payload
            state[stateName].value = !state[stateName].value
        }
    },
})

export const {
    setTrue,
    setFalse,
    toggle,
} = modalSlice.actions

export default modalSlice

export const selectCurrentModal = (stateName) => (state) => state[TOGGLE_SLICE_NAME][stateName]
