import { USER_INVITATIONS_TAG_TYPE } from 'constants/apiConstants'
import { baseSplitApi } from './_base'

const usersSlice = baseSplitApi.injectEndpoints({
    overrideExisting: false,
    endpoints: builder => ({
        signUpUser: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST'
            }),
            invalidatesTags: () => [USER_INVITATIONS_TAG_TYPE]
        }),
        signInUser: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
        }),
        setupUser: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
        }),
        refreshTokenUser: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
        }),
        signOutUser: builder.mutation({
            query: config => ({
                ...config,
                method: 'DELETE',
            }),
        }),
        createUserPassword: builder.mutation({
            query: config => ({
                ...config,
                method: 'POST',
            }),
        }),
        updateUserPassword: builder.mutation({
            query: config => ({
                ...config,
                method: 'PATCH',
            }),
        }),
    }),
})

export const {
    useSignUpUserMutation,
    useSetupUserMutation,
    useSignInUserMutation,
    useSignOutUserMutation,
    useRefreshTokenUserMutation,
    useUpdateUserPasswordMutation,
    useCreateUserPasswordMutation
} = usersSlice
