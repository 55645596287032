import RequestErrorMessages from "components/RequestErrorMessages"
import PageContent from "layout/PageContent"
import PageHeader from "layout/PageHeader"
import PageLayout from "layout/PageLayout"
import { useTranslation } from "react-i18next"
import { ContentContainer } from "./styled"
import { SubscriptionForm } from "forms/subscriptionForm"
import subscriptionsPathHelper from "helper/pathHelper/subscriptions"
import {
    useGetUserSubscriptionQuery,
    useUpdateEndUserSubscriptionMutation,
    useUpdateUserSubscriptionMutation,
} from "store/services/userSubscriptions"
import {
    isAnyStateIsLoading,
    findAnyErrorState,
} from "helper/multipleStatesChecker"
import useSubmitButtonRef from "hooks/useSubmitButtonRef"
import PageFooter from "layout/PageFooter"
import PrimaryButton from "components/PrimaryButton"
import appPathHelper from "helper/pathHelper/app"
import { useHistory } from "react-router"
import PrimaryLink from "components/PrimaryLink"
import useCustomIonAlert from 'hooks/useCustomIonAlert'
import dayjs from 'dayjs'
import { Detail } from "forms/subscriptionForm/styled"
import userPromotionCodesPathHelper from 'helper/pathHelper/userPromotionCodes';
import { useGetLatestUserPromotionCodeQuery } from 'store/services/userPromotionCodes';

const SubscriptionShowPage = () => {
    const history = useHistory()
    const [submitButtonRef, handleClick] = useSubmitButtonRef()
    const { t } = useTranslation(['application', 'subscription', 'user_promotion_code'])
    const {
        showSubscriptionsPath,
        updateSubscriptionsPath,
        updateEndSubscriptionsPath,
    } = subscriptionsPathHelper()
    const { rootPath } = appPathHelper()
    const getState = useGetUserSubscriptionQuery({ url: showSubscriptionsPath })
    const {
        data,
        isFetching,
        isSuccess
    } = getState

    const [updateSubStateTrigger, updateSubState] = useUpdateUserSubscriptionMutation()

    const [updateEndStateTrigger, updateEndState] = useUpdateEndUserSubscriptionMutation()

    const states = [getState, updateSubState, updateEndState]

    const isLoading = isAnyStateIsLoading(states)

    const error = findAnyErrorState(states)

    const handleSubmit = async (data) => {
        try {
            await updateSubStateTrigger({
                url: updateSubscriptionsPath,
                body: data
            }).unwrap()
        } catch (error) {
            console.error('SubscriptionShowPage#handleSubmit', error)
        }
    }

    const handleUpdateEndSub = async () => {
        try {
            await updateEndStateTrigger({
                url: updateEndSubscriptionsPath,
            }).unwrap()
            history.replace(rootPath)
        } catch (error) {
            console.error('SubscriptionShowPage#handleUpdateEndSub', error)
        }
    }

    const [alertPresent] = useCustomIonAlert()
    let RenderEndSubLink
    if (isSuccess && !data.data.end_subscription_date) {
        const nextMonthName = dayjs().add(1, 'M').format('MMMM')
        RenderEndSubLink = <PrimaryLink
            to="#"
            onClick={(e) => {
                e.preventDefault()
                alertPresent({
                    header: t('subscribe_page.alert_title_cancel_subscription'),
                    message: t('subscribe_page.alert_description_cancel_subscription', { nextMonth: nextMonthName }),
                    buttons: [t('cancel'), { text: t('confirm'), handler: handleUpdateEndSub }],
                })
            }}
        >
            {t('subscribe_page.update_end_subscription_date')}
        </PrimaryLink>
    }

    const { showLatestUserPromotionCodePath } = userPromotionCodesPathHelper()
    const { data: userPromotionData } = useGetLatestUserPromotionCodeQuery({ url: showLatestUserPromotionCodePath })

    return (
        <PageLayout>
            <PageHeader
                title={t('subscribe_page.title')}
                withBackButton
            />

            <PageContent loading={isFetching}>
                <ContentContainer>
                    <RequestErrorMessages error={error} />

                    <SubscriptionForm
                        data={data?.data}
                        submitButtonRef={submitButtonRef}
                        onSubmit={handleSubmit}
                    />

                    {
                        data?.data ? <Detail>
                            {t('subscribe_page.transaction_limitation.message', { count: data.data.current_limitation })}
                        </Detail> : null
                    }

                    {
                        (userPromotionData?.data?.is_active) && (
                            <>
                                <Detail>
                                    {t('current_promotion_code')} : {userPromotionData.data.promotion_code.label}
                                </Detail>
                            </>
                        )
                    }

                </ContentContainer>
            </PageContent>
            <PageFooter>
                <div className="ion-margin-bottom">
                    {RenderEndSubLink}
                </div>

                <PrimaryButton
                    onClick={handleClick}
                    disabled={isLoading}
                >
                    {t('save')}
                </PrimaryButton>
            </PageFooter>
        </PageLayout>
    )
}

export default SubscriptionShowPage
